import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

interface ReusableMenuProps {
  menuButton: React.ReactNode
  menuItems: Array<React.ReactNode>
  isHiddenMobile?: boolean
  menuWrapperClass?: string
  onOpen?: () => void
  onClose?: () => void
}

const ReusableMenu = ({
  menuButton,
  menuItems,
  menuWrapperClass,
  isHiddenMobile = false,
  onOpen = () => {},
  onClose = () => {},
}: ReusableMenuProps) => {
  const menuWrapperClassNames = () => {
    const defaultClasses = `
      absolute 
      flex 
      flex-col
      lg:right-0
      -top-6
      lg:top-10
      mb-2
      lg:mb-0
      lg:mt-2 
      z-20 
      rounded-md 
      shadow-lg 
      bg-white 
      ring-1 
      ring-black 
      ring-opacity-5 
      focus:outline-none
    `
    return menuWrapperClass || defaultClasses
  }

  return (
    <Menu as="div" className="relative flex">
      {({ open }) => {
        React.useEffect(() => {
          if (open) {
            onOpen()
            return
          }
          onClose()
        }, [open])

        return (
          <>
            <Menu.Button className={isHiddenMobile ? 'hidden md:block' : ''}>
              {menuButton}
            </Menu.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items static className={menuWrapperClassNames()}>
                {menuItems.map((menuItem, index) => (
                  <Menu.Item key={`MenuItem-${index + 1}`}>
                    {menuItem}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )
      }}
    </Menu>
  )
}

ReusableMenu.defaultProps = {
  isHiddenMobile: false,
}

export default ReusableMenu
