import tw from 'tailwind-styled-components'

export const Container = tw.div`
  relative
  rounded-md
  shadow-md
  bg-white
  mt-0
  lg:max-w-200
  border
  border-gray-50
`

export const Inner = tw.div`
  p-4
  pb-2
  bg-offWhite
  rounded-b-md
  space-y-3
`

export const ImageContainer = tw.div`
  h-160
  w-full
  flex
  items-center
  justify-center
`

export const Image = tw.img`
  h-full
  w-full
  rounded-t-md
  object-contain
`

export const BadgesContainer = tw.div`
  absolute
  top-5
  inset-x-0
  flex
  items-center
  justify-between
`

export const Icon = tw.img`
  lg:w-20
  w-16
  object-cover
`

export const FavIcon = tw.img`
  w-8
  object-cover
`

export const RoundedBadgeContainer = tw.div`
  lg:w-28
  w-24
`

export const ZigZagContainer = tw.div`
  flex
  items-center
  justify-center
  text-white
  text-xs
  lg:text-base
  font-semibold
  ml-auto
  py-1
  w-16
  pl-2
  lg:pl-0
  lg:w-20
  bg-discountLabel
`

export const Flex = tw.div`
  flex
  items-baseline
  space-x-2
`

export const FavIconContainer = tw.div`
  w-full
  flex
  items-center
  justify-end
  cursor-pointer
`
