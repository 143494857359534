import tw from 'tailwind-styled-components'

const H2Primary = tw.h2`
  text-primaryDark
  text-xl
  lg:text-2xl
  font-bold
`

export default H2Primary
