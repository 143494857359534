import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Outer = tw.div`
  py-6
  bg-white
  md:bg-secondaryGray
`

export const Container = tw(DefaultContainer)`
  flex
`
