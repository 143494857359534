import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import * as React from 'react'
import { IGridMenu, NavLanguage } from '../../@types/nav'
import { GridMenuItem } from './GridMenuItem'
import { MenubarItem } from './MenubarItem'

interface MegaMenuProps {
  menu: IGridMenu
  language: NavLanguage
}

export const MegaMenu = ({ menu, language }: MegaMenuProps) => {
  return (
    <Popover className="relative flex items-center">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center space-x-1">
            <MenubarItem
              language={language}
              menu={{
                ...menu,
                name_en: menu.cate_name_en,
                name_th: menu.cate_name_th,
                url: '',
              }}
            />
            <ChevronDownIcon className="w-4 h-4" />
          </Popover.Button>

          <Popover.Overlay
            className={`${
              open ? 'fixed inset-0 opacity-30' : 'opacity-0'
            } bg-black`}
          />

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute top-4 z-20 mt-3 max-h-3.5 w-screen max-w-4xl">
              <div className="px-4 overflow-y-auto bg-white rounded max-h-96">
                <div className="grid grid-cols-4 gap-4 py-2 gap-y-6">
                  {menu.sub_cate_lv1.map((cat) => (
                    <GridMenuItem
                      menu={cat}
                      key={cat?.[language]}
                      language={language}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
