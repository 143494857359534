const bodyClass = document.body.className

export const removeBodyScroll = () => {
  document.body.className = `${bodyClass} overflow-y-hidden`
}

export const addBodyScroll = () => {
  document.body.className = bodyClass.replace(' overflow-y-hidden', '')
}

export const toggleBodyScroll = () => {
  if (bodyClass.includes('overflow-y-hidden')) {
    removeBodyScroll()
    return
  }
  addBodyScroll()
}
