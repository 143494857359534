export const TOP_FILTERS = [
  {
    id: 1,
    value: 'สินค้าแนะนำ',
    stateKey: 'recommended',
  },
  {
    id: 2,
    value: 'สินค้าขายดี',
    stateKey: 'best_seller',
  },
  {
    id: 3,
    value: 'สินค้ามาใหม่',
    stateKey: 'new_item',
  },
]

export const PAGINATION_VALUES = [
  {
    id: 1,
    value: 21,
    stateKey: 'product_items',
  },
  {
    id: 2,
    value: 51,
    stateKey: 'product_items',
  },
  {
    id: 3,
    value: 99,
    stateKey: 'product_items',
  },
]
