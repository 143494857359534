import * as React from 'react'

const minWidths = [
  { size: 'xs', maxWidth: 640, minWidth: 360 },
  { size: 'sm', maxWidth: 768, minWidth: 640 },
  { size: 'md', maxWidth: 1024, minWidth: 768 },
  { size: 'lg', maxWidth: 1280, minWidth: 1024 },
  { size: 'xl', maxWidth: 1536, minWidth: 1280 },
  { size: '2xl', maxWidth: 2440, minWidth: 1536 },
]

const useScreenSize = () => {
  const [width, setWidth] = React.useState<number>(() => window.innerWidth)

  const initialWidth =
    minWidths.filter(({ minWidth, maxWidth }) => {
      return window.innerWidth >= minWidth && window.innerWidth < maxWidth
    })[0]?.size || '2xl'

  const [stringSize, setStringSize] = React.useState<string>(initialWidth)

  const minSize = React.useCallback(
    (screenSize: string) => {
      const [sizeObject] = minWidths.filter((obj) => obj.size === screenSize)
      return width >= sizeObject.minWidth
    },
    [width]
  )

  React.useEffect(() => {
    const handleWindowResize = () => {
      const filteredWidths = minWidths.filter(({ minWidth, maxWidth }) => {
        return window.innerWidth >= minWidth && window.innerWidth < maxWidth
      })

      setWidth(window.innerWidth)
      setStringSize(filteredWidths[0]?.size || '2xl')
    }
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    width,
    size: stringSize,
    minSize,
  }
}

export default useScreenSize
