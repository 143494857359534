import React from 'react'
import { Popover } from '@headlessui/react'
import { ChevronDownSolid } from '@graywolfai/react-heroicons'

import { ISubCategoryLvl2 } from '../../@types/nav'
import {
  Container,
  MenuButtonContainer,
  GridContainer,
  Grid,
  List,
  ListHeading,
  ListItem,
  DownIconContainer,
} from './GridMenu.styles'
import { IconContainer, Icon, Text } from '../LinkWithIcon/LinkWithIcon.styles'

interface IPropTypes {
  isMobileWidth: boolean
  innerText: string
  iconSrc: string
  menuItems: Array<ISubCategoryLvl2>
  currentLanguage: 'name_th' | 'name_en'
}

const GridMenu: React.FC<IPropTypes> = ({
  innerText,
  iconSrc,
  menuItems,
  isMobileWidth,
  currentLanguage,
}) => {
  /*
   * map menu items that are to be viewed inside the grid menu
   * each item in the grid has listHeading and subitems
   */
  const mapMenuItems = menuItems.map((subMenuItem) => (
    <List key={subMenuItem.name_en}>
      <ListHeading href={subMenuItem.link}>
        {subMenuItem[currentLanguage]}
      </ListHeading>

      {subMenuItem.sub_cate_lv2.map((levelTwo) => (
        <ListItem key={levelTwo.name_en} href={levelTwo.link}>
          {levelTwo[currentLanguage]}
        </ListItem>
      ))}
    </List>
  ))

  return (
    <Container onClick={(event) => event.stopPropagation()}>
      <Popover className="w-full">
        {({ open }) => (
          <>
            <Popover.Button className="w-full">
              <MenuButtonContainer>
                <IconContainer>
                  <Icon src={iconSrc} />
                </IconContainer>
                <Text $textColor="white">{innerText}</Text>
                <DownIconContainer>
                  <ChevronDownSolid
                    className={`w-6 h-6 font-semibold ${
                      isMobileWidth ? 'text-primaryDark' : 'text-white'
                    }`}
                  />
                </DownIconContainer>
              </MenuButtonContainer>
            </Popover.Button>
            <Popover.Overlay
              className={`${
                open ? 'opacity-30 fixed inset-0 w-screen' : 'opacity-0'
              } bg-black`}
            />
            <Popover.Panel>
              <GridContainer className="overflow-y-scroll">
                <Grid>{mapMenuItems}</Grid>
              </GridContainer>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </Container>
  )
}

export default GridMenu
