import { INavLinkWithIcon } from '../@types/nav'

export const menus: INavLinkWithIcon[] = [
  {
    name_th: 'เพลา',
    name_en: 'Axles&Accessories',
    url: '/t/categories/axles-accessories',
    icon_url: '/images/axle.svg',
  },
  {
    name_th: 'กะทะล้อ',
    name_en: 'Wheel',
    url: '/t/categories/wheel',
    icon_url: '/images/wheel.svg',
  },
  {
    name_th: 'ดัมพ์',
    name_en: 'Hoist and Accessories',
    url: '/t/categories/hoist-and-accessories',
    icon_url: '/images/dump.svg',
  },
  {
    name_th: 'ผ้าเบรค',
    name_en: 'Brake Linings',
    url: '/t/categories/brake-linings',
    icon_url: '/images/brake.svg',
  },
  {
    name_th: 'ถังน้ำมัน',
    name_en: 'Oil Tanks',
    url: '/t/categories/oil-tanks',
    icon_url: '/images/fuel.svg',
  },
  {
    name_th: 'น็อตล้อ',
    name_en: 'Studs & Nuts',
    url: '/t/categories/studs-nuts',
    icon_url: '/images/stud.svg',
  },
  {
    name_th: 'แผ่นคลัช',
    name_en: 'Clutch disc',
    url: '/t/categories/clutch-disc',
    icon_url: '/images/clutch.svg',
  },
  {
    name_th: 'ระบบเบรคและหม้อลม',
    name_en: 'Brake system and Brake chambers',
    url: '/t/categories/brake-system-and-brake-chambers',
    icon_url: '/images/air_brake.svg',
  },
]

export const subMenus: INavLinkWithIcon[] = [
  {
    name_th: 'จัดส่งทั่วประเทศ',
    name_en: 'Express Delivery Nationwide',
    url: '/p/express-delivery-nationwide',
    icon_url: '/images/delivery.svg',
  },
  {
    name_th: 'บริการ Click & Collect',
    name_en: 'Click & Collect',
    url: '/p/click-collection',
    icon_url: '/images/shop.svg',
  },
  {
    name_th: 'เปลี่ยนและคืนสินค้าได้ง่าย',
    name_en: 'Easy & Secure Payment',
    url: '/p/easy-refund-return',
    icon_url: '/images/refund.svg',
  },
  {
    name_th: 'ชำระเงินง่าย ปลอดภัย',
    name_en: 'Easy Refund & Return',
    url: '/p/trust-transaction',
    icon_url: '/images/secure_payment.svg',
  },
  {
    name_th: 'รับประกันสินค้าแท้ 100%',
    name_en: '100% Authentic & Warranty',
    url: '/p/100-authentic-warranty',
    icon_url: '/images/authentic.svg',
  },
]
