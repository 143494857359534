import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper'
import { SRLWrapper } from 'simple-react-lightbox'

// swiper css elements
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/zoom/zoom.scss'

// inner image zoom css
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import '../shopByBrand/ShopByBrand.css'

import {
  Container,
  SwiperContainer,
  PrimaryImageContainer,
  ThumbsSwiperContainer,
  SecondaryImageContainer,
  SmallImage,
  IconContainer,
  Image,
  Icon,
} from './ImageGallery.styles'

// windowSize hook
import { useWindowSize } from '../../hooks/useWindowSize'
import { MOBILE_WIDTH } from '../../constants/windowSize'

const SPACE_BETWEEN = 5
const SLIDES_PER_VIEW = 4

SwiperCore.use([Navigation, Thumbs, Pagination])

interface ImageGalleryProps {
  images: Array<string>
}

const ImageGallery = ({ images }: ImageGalleryProps) => {
  const windowSize = useWindowSize()
  const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH

  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <Container>
      <SwiperContainer>
        <IconContainer>
          <Icon className="hidden lg:block" src="/images/zoomIcon.png" />
        </IconContainer>

        <SRLWrapper>
          <div className="darkSwiperPagination">
            <Swiper
              id="main"
              thumbs={{ swiper: thumbsSwiper }}
              pagination={isMobileWidth ? { clickable: true } : false}
            >
              {images.map((image) => (
                <SwiperSlide key={image}>
                  <PrimaryImageContainer>
                    <Image
                      className="pointer-events-auto"
                      src={image}
                      loading="lazy"
                    />
                  </PrimaryImageContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </SRLWrapper>

        <ThumbsSwiperContainer className="carouselSwiper">
          <Swiper
            id="thumbs"
            navigation
            slidesPerView={SLIDES_PER_VIEW}
            spaceBetween={SPACE_BETWEEN}
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
            watchSlidesProgress
          >
            {images.map((image) => (
              <SwiperSlide key={`small-${image}`}>
                <SecondaryImageContainer>
                  <SmallImage src={image} />
                </SecondaryImageContainer>
              </SwiperSlide>
            ))}
          </Swiper>
        </ThumbsSwiperContainer>
      </SwiperContainer>
    </Container>
  )
}

export default ImageGallery
