const CART_PATH = '/orders/populate'
/*
 * @praram current product id
 * @praram quantity (of product)
 * @return status of request
 *
 * adds an item to the cart
 */
export async function addToCart(
  variantId: number,
  quantity: number
): Promise<boolean> {
  if (!quantity || !variantId) {
    return false
  }

  const data = new FormData()
  data.append('variant_id', String(variantId))
  data.append('quantity', String(quantity))
  data.append('is_ajax', 'true')

  return fetch(CART_PATH, {
    method: 'POST',
    body: data,
  })
    .then((res) => res.json())
    .then((resData: { status: boolean }) => resData.status)
    .catch(() => false)
}
