import {
  HeartOutline,
  LogoutOutline,
  UserOutline,
  XSolid,
} from '@graywolfai/react-heroicons'
import { Dialog, Transition } from '@headlessui/react'
import * as React from 'react'
import { toast } from 'react-toastify'
import { getCSRFToken } from '../../helpers/utils'
import { Image } from '../primaryNavbar/PrimaryNavbar.styles'

const FAVORITE_TEXT = 'รายการโปรดบัญชีของฉัน'
const ACCOUNT_TEXT = 'บัญชีของฉัน'

const LoggedInSlideOver = () => {
  const [open, setOpen] = React.useState(false)

  const logout = () => {
    fetch('/logout', {
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': getCSRFToken(),
      },
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => toast.error(error))
  }

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        role="none"
        className="w-6 ml-2 lg:hidden lg:w-10"
      >
        <Image alt="user avatar icon" src="/images/avatar_icon.png" />
      </div>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-x-0 bottom-0 z-30 overflow-hidden lg:hidden top-nav"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 transition-opacity bg-black bg-opacity-70" />
            </Transition.Child>
            <div className="fixed bottom-0 right-0 flex max-w-full pl-10 top-nav">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-8 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XSolid className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-col h-full overflow-y-scroll bg-white divide-y shadow-xl">
                    <a
                      className="flex px-4 py-4 space-x-2 text-primaryDark"
                      href="/account"
                    >
                      <UserOutline className="w-5 h-5" />
                      <p>{ACCOUNT_TEXT}</p>
                    </a>
                    <a
                      className="flex px-4 py-4 space-x-2 text-primaryDark"
                      href="/favorites"
                    >
                      <HeartOutline className="w-5 h-5" />
                      <p>{FAVORITE_TEXT}</p>
                    </a>
                    <button
                      type="button"
                      className="flex px-4 py-4 space-x-2 text-primaryDark"
                      onClick={logout}
                    >
                      <LogoutOutline className="w-5 h-5" />
                      <p>Logout</p>
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default LoggedInSlideOver
