import React, { useState } from 'react'

import { CheckoutPriceSection, CartItemsTable } from '..'
import {
  Container,
  FormAndCheckoutSectionContainer,
  DeliveryFormContainer,
  DeliveryHeaderContainer,
  FormContainer,
  CartItemsContainer,
} from '../checkoutAddress/CheckoutAddress.styles'
import { HrGray, H3PrimaryDark, H3SecondaryDark } from '../../GlobalStyles'
import {
  DeliveryHeading,
  DeliveryOptionsContainer,
} from './DeliveryPage.styles'

import { ICartItem } from '../../@types/cartItem'
import { IShipping } from '../../@types/shipping'

const DELIVERYPAGE_HEADER = 'การจัดส่ง'
const DELIVERY_OPTIONS_HEADING = 'ช่องทางการจัดส่ง'
const API_URL = '/checkout/update/delivery'

/*
 * @param deliveryPrice
 *
 * calls api with deliveryPrice added to the body as a form data
 */
function postDeliveryValue(deliveryId: number) {
  // get csrf token
  const csrfToken =
    document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') ?? ''
  const data = new FormData()
  data.append(
    'order[shipments_attributes][0][selected_shipping_rate_id]',
    String(deliveryId)
  )

  fetch(API_URL, {
    method: 'PATCH',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
    body: data,
  }).catch((error) => alert(error))
}

interface DeliveryPageProps {
  itemTotalPrice: string
  totalPrice: string
  cartItems: Array<ICartItem>
  shippingMethods: any
  valuePromo?: number
  orderNumber: string
  promocode?: string
}

const DeliveryPage = ({
  itemTotalPrice,
  totalPrice,
  cartItems,
  shippingMethods,
  valuePromo,
  orderNumber,
  promocode,
}: DeliveryPageProps) => {
  /*
   * handles state of currently selected delivery method, as these are constants
   * the current delivery id's are 1, 2, and 3
   */
  const [selected, setSelected] = useState<IShipping>(shippingMethods[0])
  return (
    <Container>
      <FormAndCheckoutSectionContainer>
        <FormContainer>
          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {DELIVERYPAGE_HEADER}
            </DeliveryHeaderContainer>

            <CartItemsContainer>
              <CartItemsTable
                formToken=""
                handleChangeTotalPrice={() => {}}
                cartItems={cartItems}
              />

              <HrGray />

              <DeliveryHeading>{DELIVERY_OPTIONS_HEADING}</DeliveryHeading>

              {shippingMethods.map((option) => (
                <DeliveryOptionsContainer
                  key={option.id}
                  onClick={() => setSelected(option)}
                  $isSelected={option.id === selected.id}
                >
                  <H3PrimaryDark>{option.name}</H3PrimaryDark>
                  {option.id !== 1 && (
                    <H3SecondaryDark>
                      {Number(option.amount) ? `฿ ${option.amount}` : `฿ 0`}
                    </H3SecondaryDark>
                  )}
                </DeliveryOptionsContainer>
              ))}
            </CartItemsContainer>
          </DeliveryFormContainer>
        </FormContainer>

        <CheckoutPriceSection
          itemTotalPrice={itemTotalPrice}
          totalPrice={totalPrice}
          deliveryPrice={Number(selected?.amount || 0)}
          handleSubmit={() => postDeliveryValue(selected.id)}
          orderNumber={orderNumber}
          valuePromo={valuePromo}
          promocode={promocode}
        />
      </FormAndCheckoutSectionContainer>
    </Container>
  )
}

export default DeliveryPage
