import React from 'react'
import { CheckSolid } from '@graywolfai/react-heroicons'

import { H1Primary } from '../../GlobalStyles'
import {
  Container,
  CheckoutStagesContainer,
  CheckoutStageContainer,
  CheckoutStageCount,
  CheckoutStage,
} from '../checkoutAddress/CheckoutAddress.styles'

import { CHECKOUT_STAGES } from '../../constants/checkoutStages'

const PAGE_HEADING = 'ชำระเงิน'

interface CheckoutStageProps {
  checkoutState: string
  orderId: number
  orderToken: number
}

const CheckoutStages = ({ checkoutState }: CheckoutStageProps) => {
  /*
   * is complete marks the stages before the active one as complete
   * it is set to false when the current active stage is encountered
   *
   * Note: It is not the same as isComplete = stage.stage !== checkoutState as isComplete needs to stay true
   * for all elements before the active stage and it should only be switched to false
   * once the an active element has been encountered
   */
  let isComplete = true
  const stages = CHECKOUT_STAGES.map((stage) => {
    if (stage.stage === checkoutState) {
      isComplete = false
    }

    return (
      <CheckoutStageContainer key={stage.id}>
        <CheckoutStageCount
          $isComplete={isComplete}
          $isActive={stage.stage === checkoutState}
        >
          {isComplete ? (
            <CheckSolid className="h-6 w-6 text-white" />
          ) : (
            `0${stage.id + 1}`
          )}
        </CheckoutStageCount>
        <CheckoutStage
          $isComplete={isComplete}
          $isActive={stage.stage === checkoutState}
        >
          {stage.name}
        </CheckoutStage>
      </CheckoutStageContainer>
    )
  })

  return (
    <Container>
      <H1Primary>{PAGE_HEADING}</H1Primary>
      <CheckoutStagesContainer>{stages}</CheckoutStagesContainer>
    </Container>
  )
}

export default CheckoutStages
