import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  headCarouselSwiper
  grid
  grid-cols-1
  lg:grid-cols-4
  gap-4
  pb-4
`

export const PrimaryCarousel = tw.div`
  carouselSwiper
  cursor-pointer
  lg:h-500
  h-240
  w-full
  col-span-3
  row-span-2
`

export const SecondaryCarousel = tw.div`
  carouselSwiper
  cursor-pointer
  h-300
  lg:h-240
  w-full
  col-span-3
  row-span-2
  lg:col-span-1
  lg:row-span-1
`

export const Link = tw.a`
  no-underline
`

export const Image = tw.img`
  h-full
  w-full
  lg:object-cover
  object-fit
`

export const SmallImage = tw(Image)``
