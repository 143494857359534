import tw from 'tailwind-styled-components'

export const Container = tw.div`
  mx-2
  lg:w-400
  bg-white
  rounded-md
  relative
  shadow-lg
`

export const Inner = tw.div`
  px-2
  py-2
  md:px-4
  md:px-6
  md:pt-8
  md:pb-6
  space-y-2
  md:space-y-4
  flex
  flex-col
`

export const Flex = tw.div`
  flex
  items-center
  justify-center
  space-x-4
  py-2
`

export const FacebookButton = tw.a`
  w-full
  py-2
  flex
  items-center
  justify-center
  space-x-3
  text-white
  bg-primary
  rounded-md
  hover:bg-primaryDark
`
export const MobileTopRow = tw.div`
  py-6
  text-bold
  text-lg
  text-white
  text-center
  md:hidden
`
export const CloseButton = tw.div`
  right-2
  top-2
`

export const LoginFormHeader = tw.div`
  hidden
  md:block
  text-normal
  md:text-center
  md:text-xl
  text-primaryDark
  md:pb-4
`

export const SocialLoginText = tw.div`
  text-primaryDark
  text-sm
`

export const Bottom = tw.div`
  w-full
  flex
  items-center
  justify-center
  mt-4
  md:mt-0
  py-4
  md:py-6
  bg-signupGray 
  text-primaryDark
  text-sm
  space-x-3
  rounded-b-md
`

export const Form = tw.form`
  flex
  flex-col
  space-y-4
  md:space-y-6
  items-center
  justify-center
`

export const SubmitButton = tw.button`
  py-2
  w-1/3
  rounded-md
  bg-primaryDark
  hover:primary
  text-white
  cursor-pointer
  w-full
  md:w-auto
  md:px-10
`

export const Input = tw.input<{ $hasError: boolean }>`
  w-full
  rounded-md
  border
  ${(props) => (props.$hasError ? 'bg-red-500' : '')}
  border-gray-300
  placeholder-gray-300
  focus:border-primary
  text-primaryDark
`

export const smMenuWrapperClassNames = `
  fixed
  left-0
  top-top-nav
  bottom-0
  min-w-full
  min-h-no-top-nav
  bg-overlay-60
  md:bg-transparent
  z-50
  overflow-y-scroll
  pb-8
  focus:outline-none
`

export const menuWrapperClassNames = `
  z-50
  absolute
  flex
  flex-col
  right-0
  mt-2
  rounded-md
  focus:outline-none
`
