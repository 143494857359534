export type SortingType =
  | 'recommended'
  | 'best_seller'
  | 'new_item'
  | 'price_asc'
  | 'price_desc'

export interface ISort {
  label: string
  value: SortingType
}

export const SORTING_OPTION: ISort[] = [
  { label: 'สินค้าแนะนำ', value: 'recommended' },
  { label: 'สินค้าขายดี', value: 'best_seller' },
  { label: 'สินค้ามาใหม่', value: 'new_item' },
  { label: 'ราคาต่ำ ไป สูง', value: 'price_asc' },
  { label: 'ราคาสูง ไป ต่ำ', value: 'price_desc' },
]
