import { XIcon } from '@heroicons/react/outline'
import queryString from 'query-string'
import * as React from 'react'
import { Checkbox, Dropdown, Slider } from '..'
import {
  DELIVERY_METHODS,
  ICheckbox,
  MAX_SLIDER_VALUE,
  MIN_SLIDER_VALUE,
  OFFERS_OPTIONS,
  PAYMENT_METHODS,
} from '../../constants/categoryFilters'
import { convertToObj, getIds, getRange, rangeConverter } from '../../helpers'
import { IBrand, ISpecification } from '../categoryMainBody'
import {
  CheckboxGroupContainer,
  CloseIconContainer,
  Container,
  FilterButton,
  FilterColumnBody,
  FilterItem,
  Header,
  Overlay,
} from './FilterColumn.styles'

interface FilterColumnProps {
  showColumn: boolean
  isMobileWidth: boolean
  specifications: ISpecification[]
  brands: IBrand[]
  hideColumn: (hide: boolean) => void
}

interface IFilterState {
  price?: number[]
  brand: number[]
  offers: string[]
  spec: number[]
  width?: number[]
  length?: number[]
  height?: number[]
  delivery_methods: string[]
  payment_methods: string[]
}

const getCheckboxValue = (key: any, queryData: any) => {
  const options: any = {
    offers: OFFERS_OPTIONS,
    delivery_methods: DELIVERY_METHODS,
    payment_methods: PAYMENT_METHODS,
  }

  return options[key]
    .filter((option: any) => queryData[option.value])
    .map((val: any) => val.value)
}

const FilterColumn = ({
  showColumn,
  specifications,
  brands,
  hideColumn,
  isMobileWidth,
}: FilterColumnProps) => {
  const [isOverlayHidden, setIsOverlayHidden] = React.useState(true)
  const [currentFilter, setCurrentFilter] = React.useState<IFilterState>({
    price: undefined,
    brand: [],
    offers: [],
    spec: [],
    width: undefined,
    length: undefined,
    height: undefined,
    delivery_methods: [],
    payment_methods: [],
  })

  if (showColumn && isOverlayHidden) {
    setIsOverlayHidden(false)
  }

  const hideFilterColumn = () => {
    hideColumn(false)
    setTimeout(() => setIsOverlayHidden(true), 300)
  }

  React.useEffect(() => {
    const search: any = queryString.parse(window.location.search, {
      arrayFormat: 'comma',
    })

    setCurrentFilter({
      price: rangeConverter('price', search),
      width: rangeConverter('width', search),
      length: rangeConverter('length', search),
      height: rangeConverter('height', search),
      brand: getIds(search.brand),
      spec: getIds(search.spec),
      offers: getCheckboxValue('offers', search),
      delivery_methods: getCheckboxValue('delivery_methods', search),
      payment_methods: getCheckboxValue('payment_methods', search),
    })
  }, [])

  const brandOptions: ICheckbox[] = React.useMemo(() => {
    return brands.reduce((prev: any, curr) => {
      if (
        !prev.find(
          (brand: any) =>
            brand.label.toLowerCase() === curr.brand_name.toLowerCase()
        )
      ) {
        return [
          ...prev,
          {
            label: curr.brand_name,
            value: curr.brand_id,
          },
        ]
      }

      return prev
    }, [])
  }, [brands.length])

  const specOptions: ICheckbox[] = React.useMemo(() => {
    return specifications.reduce((prev: any, curr) => {
      if (
        !prev.find(
          (specification: any) =>
            specification.label.toLowerCase() === curr.spec_name.toLowerCase()
        )
      ) {
        return [
          ...prev,
          {
            label: curr.spec_name,
            value: curr.spec_id,
          },
        ]
      }

      return prev
    }, [])
  }, [specifications.length])

  const handleSliderChange = (filterType: string) => (value: any) => {
    setCurrentFilter((prevState) => ({
      ...prevState,
      [filterType]: value,
    }))
  }

  const handleSelectBrand = (brandId: number) => () => {
    setCurrentFilter((prevState) => {
      const isSelected = prevState.brand.includes(brandId)
      return {
        ...prevState,
        brand: isSelected
          ? prevState.brand.filter((id) => id !== brandId)
          : [...prevState.brand, brandId],
      }
    })
  }

  const handleSelectSpec = (specId: number) => () => {
    setCurrentFilter((prevState) => {
      const isSelected = prevState.spec.includes(specId)
      return {
        ...prevState,
        spec: isSelected
          ? prevState.spec.filter((id) => id !== specId)
          : [...prevState.spec, specId],
      }
    })
  }

  const handleCheckboxSelect =
    (filterType: keyof IFilterState, value: any) => () => {
      if (!value) {
        return
      }

      setCurrentFilter((prevState) => {
        const currentVal: any[] = prevState[filterType] as any
        const isSelected = currentVal.includes(value as never)
        return {
          ...prevState,
          [filterType]: isSelected
            ? currentVal.filter((val: any) => val !== value)
            : [...currentVal, value],
        }
      })
    }

  const handleFilterSubmit = () => {
    const queryObj = {
      ...convertToObj(currentFilter.offers),
      ...convertToObj(currentFilter.delivery_methods),
      ...convertToObj(currentFilter.payment_methods),
      ...getRange('price', currentFilter.price),
      ...getRange('width', currentFilter.width),
      ...getRange('height', currentFilter.height),
      ...getRange('length', currentFilter.length),
      brand: currentFilter.brand || [],
      spec: currentFilter.spec || [],
    }

    const stringified = queryString.stringify(queryObj, {
      arrayFormat: 'comma',
    })
    window.location.search = stringified
  }

  return (
    <>
      {isMobileWidth && (
        <Overlay $isVisible={showColumn} $isHidden={isOverlayHidden} />
      )}
      <Container $isHidden={!showColumn}>
        <Header>
          ฟิลเตอร์
          <CloseIconContainer onClick={hideFilterColumn}>
            <XIcon className="w-10 h-10 text-white" />
          </CloseIconContainer>
        </Header>

        <FilterColumnBody>
          <FilterItem>
            <Dropdown headerText="ราคา (฿)">
              <Slider
                type="price"
                minValue={MIN_SLIDER_VALUE}
                maxValue={MAX_SLIDER_VALUE}
                values={
                  currentFilter.price || [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE]
                }
                step={100}
                onChange={handleSliderChange('price')}
              />
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="แบรนด์">
              <CheckboxGroupContainer>
                {brandOptions.map((option) => (
                  <Checkbox
                    text={option.label}
                    key={option.value}
                    onChange={handleSelectBrand(option.value)}
                    isChecked={!!currentFilter.brand.includes(option.value)}
                  />
                ))}
              </CheckboxGroupContainer>
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="ข้อเสนอ">
              <CheckboxGroupContainer>
                {OFFERS_OPTIONS.map((option) => (
                  <Checkbox
                    text={option.label}
                    key={option.value}
                    onChange={handleCheckboxSelect('offers', option.value)}
                    isChecked={!!currentFilter.offers.includes(option.value)}
                  />
                ))}
              </CheckboxGroupContainer>
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="สเปค">
              <CheckboxGroupContainer>
                {specOptions.map((option) => (
                  <Checkbox
                    text={option.label}
                    key={option.value}
                    onChange={handleSelectSpec(option.value)}
                    isChecked={!!currentFilter.spec.includes(option.value)}
                  />
                ))}
              </CheckboxGroupContainer>
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="กว้าง (CM)">
              <Slider
                type="width"
                minValue={MIN_SLIDER_VALUE}
                maxValue={MAX_SLIDER_VALUE}
                values={
                  currentFilter.width || [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE]
                }
                step={10}
                onChange={handleSliderChange('width')}
              />
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="ยาว (CM)">
              <Slider
                type="length"
                minValue={MIN_SLIDER_VALUE}
                maxValue={MAX_SLIDER_VALUE}
                values={
                  currentFilter.length || [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE]
                }
                step={10}
                onChange={handleSliderChange('length')}
              />
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="สูง (CM)">
              <Slider
                type="height"
                minValue={MIN_SLIDER_VALUE}
                maxValue={MAX_SLIDER_VALUE}
                values={
                  currentFilter.height || [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE]
                }
                step={10}
                onChange={handleSliderChange('height')}
              />
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="การจัดส่ง">
              <CheckboxGroupContainer>
                {DELIVERY_METHODS.map((option) => (
                  <Checkbox
                    text={option.label}
                    key={option.value}
                    onChange={handleCheckboxSelect(
                      'delivery_methods',
                      option.value
                    )}
                    isChecked={
                      !!currentFilter.delivery_methods.includes(option.value)
                    }
                  />
                ))}
              </CheckboxGroupContainer>
            </Dropdown>
          </FilterItem>

          <FilterItem>
            <Dropdown headerText="การชำระเงิน">
              <CheckboxGroupContainer>
                {PAYMENT_METHODS.map((option) => (
                  <Checkbox
                    text={option.label}
                    key={option.value}
                    onChange={handleCheckboxSelect(
                      'payment_methods',
                      option.value
                    )}
                    isChecked={
                      !!currentFilter.payment_methods.includes(option.value)
                    }
                  />
                ))}
              </CheckboxGroupContainer>
            </Dropdown>
          </FilterItem>

          <FilterButton onClick={handleFilterSubmit}>ค้นหา</FilterButton>
        </FilterColumnBody>
      </Container>
    </>
  )
}

export default FilterColumn
