import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-6
  lg:space-y-8
  mb-6
  lg:mb-8
`

export const Form = tw.form`
  flex
  flex-col
  space-y-4
`

export const PrimaryBorder = tw.div`
  border
  border-primary
  py-4
  rounded-md
  pb-4
`

export const ButtonsContainer = tw.div`
  flex
  space-x-4
  w-1/4
  px-8
`
