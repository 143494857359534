import React from 'react'

import { Container, Label, RequiredSpan } from '../Input/Input.styles'
import { SelectEl as StyledSelect, Option } from '../Select/Select.styles'

import { ILocationData } from '../../@types/formData'

export interface IRenderOptions {
  label: string
  value: string
}

interface SelectProps {
  labelText: string
  isLabelBold?: boolean
  isRequired?: boolean
  list: Array<ILocationData>
}

// eslint-disable-next-line react/display-name
const Select = React.forwardRef(
  (
    { labelText, isRequired = false, isLabelBold = false, list }: SelectProps,
    ref
  ) => {
    /*
     * make options virtualized as the list is quite long upto 30,000 values on some
     */
    const renderOptions = list.map((option) => (
      <Option key={option.id}>{option.name_th}</Option>
    ))

    return (
      <Container $isFlexCol>
        <Label $isLabelBold={isLabelBold}>
          {labelText} {isRequired && <RequiredSpan>*</RequiredSpan>}
        </Label>
        <StyledSelect ref={ref}>{renderOptions}</StyledSelect>
      </Container>
    )
  }
)

export default Select
