import React from 'react'

import {
  CheckoutSectionContainer,
  DeliveryFormContainer,
  DeliveryHeaderContainer,
  CouponContainer,
  PriceContainer,
  TotalPriceContainer,
  PriceText,
  PriceTextBold,
  PriceTaxContainer,
  TaxText,
  PriceBold,
  PrimaryButton,
  SecondaryButton,
} from '../checkoutAddress/CheckoutAddress.styles'
import { formatPrice } from '../FlashCard'

import { HrGray } from '../../GlobalStyles'
import { CouponSection } from './CouponSection'
import { getCSRFToken } from '../../helpers'

const CHECKOUT_HEADER_TEXT = 'สรุปรายการ'
const ITEM_TOTAL_TEXT = 'ราคา'
const ORDER_TOTAL_TEXT = 'ราคารวม'
const CHECKOUT_TEXT = 'ขั้นตอนถัดไป'
const TAX_TEXT = 'รวมค่าภาษีแล้ว (ถ้ามี)'
const DELIVERY_TEXT = 'ค่าส่ง'
const GO_BACK = 'ย้อนกลับ'

interface CheckoutPriceSectionProps {
  totalPrice: string
  itemTotalPrice: string
  deliveryPrice?: number
  hasCouponInput?: boolean
  isHeaderHidden?: boolean
  handleSubmit: (e?: any) => void
  isCheckoutButtonHidden?: boolean
  isBackButtonHidden?: boolean
  isButtonHidden?: boolean
  orderNumber: string
  valuePromo?: number
  promocode?: string
}

const CheckoutPriceSection = ({
  itemTotalPrice,
  totalPrice,
  deliveryPrice,
  handleSubmit,
  hasCouponInput = true,
  isCheckoutButtonHidden = false,
  isHeaderHidden = false,
  isBackButtonHidden = false,
  isButtonHidden = false,
  orderNumber,
  valuePromo,
  promocode,
}: CheckoutPriceSectionProps) => {
  const orderTotalPrice = () => {
    return deliveryPrice === null
      ? Number(totalPrice)
      : Number(totalPrice) + Number(deliveryPrice)
  }

  const handleBack = () => {
    const urlObj: any = {
      '/checkout/delivery': '/checkout/address',
      '/checkout/payment': '/checkout/delivery',
      '/checkout/update/payment': '/checkout/payment',
      '/checkout/confirm': '/checkout/update/payment',
    }

    const nextUrl = urlObj[window.location.pathname] || '/checkout/address'

    window.location.href = nextUrl
  }

  const handleCouponSubmit = (coupon: string) => (e: any) => {
    e.preventDefault()
    if (!coupon) {
      return
    }

    const csrfToken = getCSRFToken()

    const data = new FormData()
    data.append('coupon_code', coupon)

    fetch(`/orders/${orderNumber}/coupon_codes`, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
      body: data,
    }).then(() => window.location.reload())
  }

  return (
    <CheckoutSectionContainer className="relative">
      <DeliveryFormContainer>
        {
          /*
           * hide the header in cart
           */
          !isHeaderHidden && (
            <DeliveryHeaderContainer>
              {CHECKOUT_HEADER_TEXT}
            </DeliveryHeaderContainer>
          )
        }

        <CouponContainer>
          {hasCouponInput && (
            <CouponSection handleSubmit={handleCouponSubmit} />
          )}

          <PriceContainer>
            <PriceText>{ITEM_TOTAL_TEXT}</PriceText>
            <PriceText>฿ {formatPrice(parseFloat(itemTotalPrice))}</PriceText>
          </PriceContainer>

          {deliveryPrice !== null && (
            <PriceContainer>
              <PriceText>{DELIVERY_TEXT}</PriceText>
              <PriceText>฿ {formatPrice(deliveryPrice || 0)}</PriceText>
            </PriceContainer>
          )}

          {!!valuePromo && (
            <PriceContainer>
              <PriceText>ส่วนลด</PriceText>
              <PriceText>฿ {formatPrice(valuePromo)}</PriceText>
            </PriceContainer>
          )}

          {!!promocode && (
            <PriceContainer>
              <PriceText>โค้ดที่ใช้</PriceText>
              <PriceText>{promocode}</PriceText>
            </PriceContainer>
          )}

          <HrGray />

          <TotalPriceContainer>
            <PriceTextBold>{ORDER_TOTAL_TEXT}</PriceTextBold>
            <PriceTaxContainer>
              <PriceBold>฿ {formatPrice(orderTotalPrice())}</PriceBold>
              <TaxText>{TAX_TEXT}</TaxText>
            </PriceTaxContainer>
          </TotalPriceContainer>
        </CouponContainer>
      </DeliveryFormContainer>
      <PrimaryButton
        className={isCheckoutButtonHidden || isButtonHidden ? 'hidden' : ''}
        onClick={handleSubmit}
        type="submit"
      >
        {CHECKOUT_TEXT}
      </PrimaryButton>

      <SecondaryButton
        className={
          isCheckoutButtonHidden ||
          isBackButtonHidden ||
          window.location.pathname.includes(
            'address' || 'confirm' || 'complete'
          )
            ? 'hidden'
            : ''
        }
        onClick={handleBack}
        type="button"
      >
        {GO_BACK}
      </SecondaryButton>
    </CheckoutSectionContainer>
  )
}

CheckoutPriceSection.defaultProps = {
  deliveryPrice: null,
}

export default CheckoutPriceSection
