import tw from 'tailwind-styled-components'

import { DefaultContainer, PrimaryTextRegular } from '../../GlobalStyles'

export const Outer = tw.div`
  bg-secondaryGray
`

export const Container = tw(DefaultContainer)`
  flex
  flex-col
  lg:flex-row
  lg:px-6
  lg:py-5
  space-x-0
  lg:space-x-6
  bg-white
`

export const ImageGalleryContainer = tw.div`
  w-full
  lg:w-367
  space-y-6
`

export const IconsContainer = tw.div`
  hidden  
  lg:flex
  items-center
  self-bottom
`

export const SocialIcons = tw.div`
  w-full
  flex
  justify-between
  items-center
  border-r-2
  border-primaryDark
  pr-6
`

export const FavouriteIcon = tw.div`
  cursor-pointer
  flex
  space-x-3
  justify-center
  items-center
  px-6
`

export const IconContainer = tw.a`
  cursor-pointer
  w-8
`

export const SocialIcon = tw.img`
  object-contain
`

export const ProductDetailContainer = tw.div`
  w-full
  lg:w-5/12
  relative
  space-y-5
`

export const PaymentShippingDetailContainer = tw.div`
  hidden
  h-full
  w-full
  pt-5
  lg:block
  lg:w-3/12
  space-y-4
`

export const PaymentShippingDetailInner = tw.a`
  no-underline
  px-3
  py-4
  bg-lightBlue
  flex
  flex-col
  space-y-4
`

export const FlexBetweenText = tw.div`
  flex
  space-x-2
`
export const HeaderText = tw(PrimaryTextRegular)`
  font-bold
`

export const OutlineButtonsFlex = tw.div`
  lg:w-1/2
  space-x-3
  flex
`

export const BadgesContainer = tw.div` 
`

export const DescriptionContainer = tw.div`
  space-y-2
  mb-4
`

export const Ul = tw.ul`
  text-secondaryGrayDark
  list-disc
  pl-6
`

export const Li = tw.li`
`

export const PriceAndStockContainer = tw.div`
  flex
  justify-between
`

export const PriceContainer = tw.div`
  flex
  items-center
  space-x-2
`

export const StockIndicator = tw.ul`
  text-red-500
  font-semibold
  bg-red-200
  list-disc
  px-8
  py-2
  rounded-full
`

export const FlexBetween = tw.div`
  w-full
  flex
  justify-between
  flex-col
  space-y-2
  lg:items-center
  lg:flex-row
`

export const ButtonsContainer = tw.div`
  fixed
  bottom-0
  left-0
  bg-secondaryGray
  p-4
  lg:p-0
  lg:bg-transparent
  lg:static
  w-full
  flex
  justify-between
  items-center
  space-x-2
`

export const Icon = tw.img`
  w-6
  h-6
`
