import tw from 'tailwind-styled-components'

export const Container = tw.div`
`

export const SwiperContainer = tw.div`
  w-full
  flex
  flex-col
  space-y-2
  relative
  overflow-hidden
`

export const IconContainer = tw.div`
  z-10
  absolute
  top-10
  right-5
  w-8
`

export const Icon = tw.img`
  object-contain
`

export const PrimaryImageContainer = tw.div`
  object-cover
  w-full
  h-367
  flex
  flex-1
  justify-center
  items-center
`

export const ThumbsSwiperContainer = tw.div`
  hidden
  md:block
  w-full
`

export const SecondaryImageContainer = tw.div`
  w-100
  cursor-pointer
`

export const Image = tw.img`
  object-cover
`

export const SmallImage = tw.img`
  object-cover
  h-80p
  w-80p
`
