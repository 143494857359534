import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import {
  PhoneOutline,
  MailOutline,
  LocationMarkerOutline,
} from '@graywolfai/react-heroicons'

import { Input, SelectEl as Select } from '..'
import { Label } from '../Input/Input.styles'
import { CardButtonFill } from '../FlashCard/FlashCard.styles'
import { H1Primary, H3PrimaryDark, PSecondaryDark } from '../../GlobalStyles'
import {
  Container,
  LeftContainer,
  ContactDetailsContainer,
  Form,
  Flex,
  HalfWidthDiv,
  FlexCol,
  Textarea,
  GrayLabel,
  DetailContainer,
} from './ContactUsPage.styles'

const CONTACT_US_HEADING = 'ติดต่อเรา'
const CONTACT_DETAILS_HEADING = 'บริษัท บิทแมกซ์ อินเตอร์คอร์ป จำกัด '
const ADDRESS =
  '32/3 หมู่ที่ 5 ถนนพหลโยธิน ตำบลคลองหนึ่ง อำเภอคลองหลวง จังหวัดปทุมธานี 12120'
const PHONES = '(+66) 02-516-1546, (+66) 086-982-8288'
const EMAIL = 'info@bitmax.co.th'
const ADDRESS_ENGLISH =
  '32/3 Moo 5 Phaholyothin Road, Klong Nueng Klong Luang, Pathumthani, 12120, Thailand'
const SUBMIT_TEXT = 'ส่งข้อมูล'
const COMPANY_NAME_HEADER = 'Company Name'
const FIRST_NAME = 'First Name'
const LAST_NAME = 'Last Name'
const MOBILE = 'Mobile'
const EMAIL_HEADER = 'Email'
const ENQUIRY_TYPE = 'Tell us a bit about your enquiry'
const MESSAGE = 'Message'
const MESSAGE_LIMIT = 'จำกัด 500 ตัวอักษร'
const API_URL = '/contact-us'

interface FormValues {
  companyname: string
  firstname: string
  lastname: string
  mobile: number
  email: string
  enquiryType: string
  message: string
}

const ContactUsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()

  const onSubmit = (values: FormValues) => {
    const formData = new FormData()

    formData.append(`contact[name]`, values.companyname)
    formData.append(`contact[firstname]`, values.firstname)
    formData.append(`contact[lastname]`, values.lastname)
    formData.append(`contact[mobile]`, String(values.mobile))
    formData.append(`contact[email]`, values.email)
    formData.append(`contact[inquiry]`, values.enquiryType)
    formData.append(`contact[message]`, values.message)

    fetch(API_URL, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          toast.success(
            'Message sent successfully. We will contact you as soon as possible regarding your request.'
          )
          return
        }
        toast.error(
          'There an error sending your message.Please try again later.'
        )
      })
      .catch(() =>
        toast.error(
          'There was a network error while sending your message. Please try again later.'
        )
      )
  }

  return (
    <Container>
      <LeftContainer>
        <H1Primary>{CONTACT_US_HEADING}</H1Primary>
        <ContactDetailsContainer>
          <H3PrimaryDark>{CONTACT_DETAILS_HEADING}</H3PrimaryDark>
          <PSecondaryDark>{ADDRESS}</PSecondaryDark>
          <DetailContainer>
            <PhoneOutline className="w-6 h-6 text-primaryDark" />
            <PSecondaryDark>{PHONES}</PSecondaryDark>
          </DetailContainer>
          <DetailContainer>
            <MailOutline className="w-6 h-6 text-primaryDark" />
            <PSecondaryDark>{EMAIL}</PSecondaryDark>
          </DetailContainer>
          <DetailContainer>
            <LocationMarkerOutline className="w-6 h-6 text-primaryDark" />
            <PSecondaryDark>{ADDRESS_ENGLISH}</PSecondaryDark>
          </DetailContainer>
        </ContactDetailsContainer>
      </LeftContainer>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          isLabelBold
          type="text"
          flexCol
          labelText={COMPANY_NAME_HEADER}
          {...register('companyname')}
        />
        <Flex>
          <HalfWidthDiv>
            <Input
              type="text"
              isRequired
              isLabelBold
              flexCol
              labelText={FIRST_NAME}
              {...register('firstname', { required: true })}
              hasError={!!errors.firstname}
            />
          </HalfWidthDiv>
          <HalfWidthDiv>
            <Input
              type="text"
              isLabelBold
              isRequired
              flexCol
              labelText={LAST_NAME}
              {...register('lastname', { required: true })}
              hasError={!!errors.lastname}
            />
          </HalfWidthDiv>
        </Flex>
        <Input
          type="tel"
          isLabelBold
          isRequired
          flexCol
          labelText={MOBILE}
          {...register('mobile')}
        />
        <Input
          type="text"
          isLabelBold
          isRequired
          flexCol
          labelText={EMAIL_HEADER}
          {...register('email', { required: true })}
          hasError={!!errors.email}
        />
        <Select
          isLabelBold
          isRequired
          labelText={ENQUIRY_TYPE}
          list={[{ name_th: 'Test', id: 1, name_en: 'Test' }]}
          {...register('enquiryType', { required: true })}
        />

        <FlexCol>
          <Label $isLabelBold>{MESSAGE}</Label>
          <Textarea {...register('message', { required: true })} />
          <GrayLabel>{MESSAGE_LIMIT}</GrayLabel>
        </FlexCol>

        <CardButtonFill type="submit">{SUBMIT_TEXT}</CardButtonFill>
      </Form>
    </Container>
  )
}

export default ContactUsPage
