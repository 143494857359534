import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Input } from '..'
import { H3PrimaryDark } from '../../GlobalStyles'
import { FormikContainer } from '../checkoutAddress/CheckoutAddress.styles'
import { CardButtonFill, CardButtonOutline } from '../FlashCard/FlashCard.styles'
import { Container, Form, PrimaryBorder, ButtonsContainer } from './EditAccountPage.styles'

import { USER_INFO_FORM } from '../../constants/userAccountForm'
import { USER_ADDRESS_FORM } from '../../constants/userAddressForm'

const ADDRESSFORM_HEADER = 'ที่อยู่ในการจัดส่ง'
const USER_INFO_HEADER = 'บัญชีของฉัน'
const CONFIRM_TEXT = 'บันทึก'
const CANCEL_TEXT = 'ยกเลิก'

/*
 * @TODO: replace console.log with api path
 */

/*
 * Validation schema for user info
 */
const userInfoValidation = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phone: Yup.number().min(9, 'Please check your phone number').required('Required'),
  email: Yup.string().email('Invalied email').required('Required'),
  password: Yup.string()
    .min(8, 'Password should be at least 8 characters long')
    .required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match'),
})

/*
 * Validation for user address
 */
const userAddressValidation = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  houseNumber: Yup.number().required('Required'),
  building: Yup.string(),
  lane: Yup.string().required('Required'),
  road: Yup.string().required('Required'),
  province: Yup.string().required('Required'),
  amphor: Yup.string().required('Required'),
  zipCode: Yup.number().required('Required'),
  backupNumber: Yup.number(),
})

export interface IUserForm {
  [key: string]: string | number
}

interface EditAccountPageProps {
  user: any
}

const EditAccountPage = ({ user }: EditAccountPageProps) => {
  const initialUserValues: IUserForm = {
    firstName: '',
    lastName: '',
    phone: '',
    email: user.email,
    password: '',
    confirmPassword: '',
  }

  const intialAddressValues: IUserForm = {
    firstName: '',
    lastName: '',
    houseNumber: '',
    building: '',
    lane: '',
    road: '',
    province: '',
    amphor: '',
    tambon: '',
    zipCode: '',
    backupNumber: '',
  }

  return (
    <Container>
      <Formik
        initialValues={initialUserValues}
        validationSchema={userInfoValidation}
        onSubmit={(values) => console.log('Values: ', values)}
      >
        {({ errors, touched, values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <H3PrimaryDark>{USER_INFO_HEADER}</H3PrimaryDark>
            <PrimaryBorder>
              <FormikContainer className="w-2/3">
                {USER_INFO_FORM.map(({ id, label, isRequired, name, type }) => (
                  <Input
                    key={id}
                    flexCol
                    type={type}
                    name={name}
                    labelText={label}
                    isRequired={isRequired}
                    onChange={handleChange}
                    value={String(values[name])}
                    hasError={touched[name] && Boolean(errors[name])}
                    errorText={(touched[name] && errors[name]) || ''}
                  />
                ))}
              </FormikContainer>

              <ButtonsContainer>
                <CardButtonFill type="submit">{CONFIRM_TEXT}</CardButtonFill>
                <CardButtonOutline>{CANCEL_TEXT}</CardButtonOutline>
              </ButtonsContainer>
            </PrimaryBorder>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={intialAddressValues}
        validationSchema={userAddressValidation}
        onSubmit={console.log}
      >
        {({ values, handleChange, handleSubmit, touched, errors }) => (
          <Form onSubmit={handleSubmit}>
            <H3PrimaryDark>{ADDRESSFORM_HEADER}</H3PrimaryDark>
            <PrimaryBorder>
              <FormikContainer className="w-2/3">
                {USER_ADDRESS_FORM.map(({ id, label, type, name, isRequired }) => (
                  <Input
                    key={id}
                    flexCol
                    type={type}
                    name={name}
                    labelText={label}
                    isRequired={isRequired}
                    onChange={handleChange}
                    value={String(values[name])}
                    hasError={touched[name] && Boolean(errors[name])}
                    errorText={(touched[name] && errors[name]) || ''}
                  />
                ))}
              </FormikContainer>

              <ButtonsContainer>
                <CardButtonFill type="submit">{CONFIRM_TEXT}</CardButtonFill>
                <CardButtonOutline>{CANCEL_TEXT}</CardButtonOutline>
              </ButtonsContainer>
            </PrimaryBorder>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default EditAccountPage
