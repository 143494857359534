import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import parse, {
  domToReact,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { Element } from 'domhandler/lib/node'
import SimpleReactLightbox from 'simple-react-lightbox'

import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  ImageGalleryContainer,
  IconsContainer,
  SocialIcons,
  FavouriteIcon,
  IconContainer,
  SocialIcon,
  ProductDetailContainer,
  OutlineButtonsFlex,
  BadgesContainer,
  DescriptionContainer,
  Ul,
  Li,
  PriceAndStockContainer,
  PriceContainer,
  StockIndicator,
  FlexBetween,
  ButtonsContainer,
  PaymentShippingDetailContainer,
  PaymentShippingDetailInner,
  FlexBetweenText,
  HeaderText,
  Icon,
} from './ProductPage.styles'

import {
  RoundedBadgeContainer,
  OutlineButton,
  CardButtonFill,
  CardButtonOutline,
  Icon as ProductIcon,
} from '../FlashCard/FlashCard.styles'
import {
  H2Primary,
  H2Black,
  H2Danger,
  H4PrimaryDark,
  SecondaryTextStrikeThrough,
  PrimaryTextRegular,
  PSecondary,
  HrGray,
} from '../../GlobalStyles'
import { ImageGallery, ProductTags } from '..'

import { FREE_SHIPPING, INSTALLMENT } from '../../constants/pageTexts'
import {
  SHIPPING_DETAILS,
  PAYMENT_DETAILS,
} from '../../constants/shippingAndPayment'

import { calculateSavings } from '../../helpers/calculateSavings'

import { formatPrice } from '../FlashCard'

import { ICardItem } from '../../@types/cards'

import { useProductCountToggler } from '../../hooks'

import { addToCart } from '../../helpers/addProduct'

const SOCIAL_TEXT = 'เเชร์'
const FAVOURITE_TEXT = 'Favourite'
const ADD_TO_CART_TEXT = 'ใส่รถเข็น'
const BUY_NOW_TEXT = 'ซื้อเลย'
const TIMEOUT_DELAY = 2000
const ENCODED_CURRENT_PAGE_URL = encodeURI(window.location.href)
const FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${ENCODED_CURRENT_PAGE_URL}`
const LINE_URL = `https://line.me/R/share?text=${ENCODED_CURRENT_PAGE_URL}`

/*
 * @param domNode of type DOMNode (p, h1, div, ...)
 * @returns parsed react element
 *
 * takes in a html element and replaces certain onces like p, h1, and img with react
 * elements
 *
 * ex: <p>Text</p> will return <PSecondaryDark>Text</PSecondaryDark>
 */
function replaceElement(domNode: DOMNode) {
  if (domNode instanceof Element) {
    switch (domNode.name) {
      case 'ul':
        return <Ul>{domToReact(domNode.children)}</Ul>

      default:
        return <Li>{domToReact(domNode.children)}</Li>
    }
  }
}

interface IVariantData {
  count_on_hand: number
  volume_prices: [
    {
      range: string
      amount: string
    }
  ]
}

interface ProductPageProps {
  product: ICardItem
  masterPrice: string
  variantData: IVariantData
  images: Array<string>
  userId: number
  categories: {
    name: string
  }
  isFavorite: boolean
}

const ProductPage = ({
  product,
  masterPrice,
  variantData,
  images,
  userId,
  categories,
  isFavorite,
}: ProductPageProps) => {
  if (!product) {
    return null
  }

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isFavourite, setIsFavourite] = useState(isFavorite)

  const { itemCount, ProductCountToggler } = useProductCountToggler({})

  const savings = calculateSavings(
    Number(masterPrice),
    Number(product.discount_price)
  )

  /*
   * parser is used to parse the html string passed from the server,
   * the default html elements are replaced with react nodes
   */
  const parserOptions: HTMLReactParserOptions = {
    replace: replaceElement,
  }

  const handleActionButtonClick = async (action: 'addToCart' | 'checkout') => {
    /*
     * disable the add to cart button when, a request is being processed
     */
    if (isAddingToCart) {
      return
    }

    if (action === 'addToCart') {
      setIsAddingToCart(true)
    }

    const isAddedToCart = await addToCart(product.id, itemCount).then(
      (response) => {
        /*
         * to avoid abruptly stoping the spinner
         */
        setTimeout(() => setIsAddingToCart(false), TIMEOUT_DELAY)
        return response
      }
    )

    if (isAddedToCart) {
      /*
       * based on the action either show a toast or route to the cart page
       */
      if (action === 'addToCart') {
        toast.success('Item has been successfully added to cart')

        window.location.reload()
        return
      }

      window.location.href = '/cart'
      return
    }

    toast.error('There was an error adding item to cart')
  }

  /*
   * calls api to add a product to the favorites
   * api takes productId and userId as params
   */
  const addToFavourite = () => {
    const csrfToken =
      document.querySelector("[name='csrf-token']")?.content ?? ''
    const formData = new FormData()

    formData.append('product_id', String(product.id))
    formData.append('user_id', String(userId))

    fetch('/favorites', {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then(() => {
        setIsFavourite(true)
        toast.success('Product added to favorites')
        window.location.reload()
      })
      .catch(() => toast.error('Error adding product to favorites'))
  }

  const removeFavourite = () => {
    const csrfToken =
      document.querySelector("[name='csrf-token']")?.content ?? ''
    const formData = new FormData()

    formData.append('product_id', String(product.id))

    fetch(`/favorites/${product.id}`, {
      method: 'DELETE',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then(() => {
        setIsFavourite(false)
        toast.success('Product has been removed from favorites')
        window.location.reload()
      })
      .catch(() => toast.error('Error removing product from favorites'))
  }

  const toggleFavourite = () => {
    if (!userId) {
      toast.error('Please log in before toggling favorites')
      return
    }

    if (isFavourite) {
      removeFavourite()
      return
    }

    addToFavourite()
  }

  /*
   * @ returns string of type; buy 10 to get extra 10% discount
   *
   * there is a name attribute to the object @variants, i've used range
   * and amount to keep it consistent with how it's used in the home page
   * (look in FlashCard/index)
   *
   */
  const formatVolumePricing = (): string => {
    const volumePrice = variantData.volume_prices[0]
    if (!volumePrice) {
      return ''
    }

    const { range, amount } = volumePrice

    /*
     * is of the form 10...20 which is the range, only starting range needed
     */
    const formattedRange = range.split('...')[0]
    const formattedAmount = amount.split('.')[0]

    return `buy ${formattedRange} to get extra ${formattedAmount}% discount`
  }

  const copyURL = (): void => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => toast.success('URL copied to clipboard'))
      .catch(() => toast.error('Could not copy to clipboard'))
  }

  return (
    <Container>
      <ImageGalleryContainer>
        <SimpleReactLightbox>
          <ImageGallery images={images} />
        </SimpleReactLightbox>

        <IconsContainer>
          <SocialIcons>
            <PrimaryTextRegular>{SOCIAL_TEXT}</PrimaryTextRegular>
            <IconContainer target="_blank" href={FACEBOOK_URL}>
              <SocialIcon src="/images/lcsFblogo.png" />
            </IconContainer>
            <IconContainer target="_blank" href={LINE_URL}>
              <SocialIcon src="/images/lcsLineLogo.png" />
            </IconContainer>
            <IconContainer onClick={() => copyURL()}>
              <SocialIcon src="/images/linkIcon.png" />
            </IconContainer>
          </SocialIcons>
          <FavouriteIcon onClick={toggleFavourite}>
            <IconContainer>
              <SocialIcon
                src={
                  isFavourite
                    ? '/images/heart_icon_fill.png'
                    : '/images/heart_icon_dark.png'
                }
              />
            </IconContainer>
            <PrimaryTextRegular>{FAVOURITE_TEXT}</PrimaryTextRegular>
          </FavouriteIcon>
        </IconsContainer>
      </ImageGalleryContainer>
      <ProductDetailContainer>
        <BadgesContainer>
          {product.lcs_choice && (
            <RoundedBadgeContainer>
              <ProductIcon src="/images/lcsChoice.png" />
            </RoundedBadgeContainer>
          )}
        </BadgesContainer>

        <H2Primary>{product.name}</H2Primary>
        <OutlineButtonsFlex>
          {product.installment && (
            <OutlineButton $isVisible={product.installment}>
              {INSTALLMENT}
            </OutlineButton>
          )}
          {product.free_shipping && (
            <OutlineButton $isVisible={product.free_shipping}>
              {FREE_SHIPPING}
            </OutlineButton>
          )}
        </OutlineButtonsFlex>

        <div className="hidden lg:block">
          <DescriptionContainer>
            <H2Black>รายละเอียดสินค้า</H2Black>
            {parse(product.short_description, parserOptions)}
          </DescriptionContainer>
          <HrGray />
        </div>

        <PriceAndStockContainer>
          <PriceContainer>
            <div className="flex items-center space-x-1">
              {product.discount_price ? (
                <H2Danger>
                  ฿ {formatPrice(Number(product.discount_price))}
                </H2Danger>
              ) : (
                <H2Black>฿ {formatPrice(Number(masterPrice))}</H2Black>
              )}
              <PrimaryTextRegular className="text-base font-light">
                {' '}
                /ชิ้น
              </PrimaryTextRegular>
            </div>
            <div className="flex items-center space-x-1">
              {product.discount_price && savings > 0 && (
                <>
                  <SecondaryTextStrikeThrough className="">
                    ฿ {formatPrice(Number(masterPrice))}
                  </SecondaryTextStrikeThrough>
                  <PrimaryTextRegular className="font-light">
                    save ฿ {formatPrice(savings)}
                  </PrimaryTextRegular>
                </>
              )}
            </div>
          </PriceContainer>

          {variantData && variantData.count_on_hand < 1 && (
            <div className="flex items-center justify-center px-4 py-1 space-x-2 text-red-500 bg-red-200 rounded-full">
              <div className="w-1.5 h-1.5 bg-red-500 rounded-full" />
              <div>สินค้าหมด</div>
            </div>
          )}
        </PriceAndStockContainer>

        <ProductTags
          category={categories?.name || ''}
          tags={product.meta_keywords}
        />

        <FlexBetween>
          <H4PrimaryDark>{formatVolumePricing()}</H4PrimaryDark>
          <ProductCountToggler isInputWide isBorderLight={false} />
        </FlexBetween>

        <div className="flex lg:hidden">
          <SocialIcons>
            <PrimaryTextRegular>{SOCIAL_TEXT}</PrimaryTextRegular>
            <IconContainer target="_blank" href={FACEBOOK_URL}>
              <SocialIcon src="/images/lcsFblogo.png" />
            </IconContainer>
            <IconContainer target="_blank" href={LINE_URL}>
              <SocialIcon src="/images/lcsLineLogo.png" />
            </IconContainer>
            <IconContainer onClick={copyURL}>
              <SocialIcon src="/images/linkIcon.png" />
            </IconContainer>
          </SocialIcons>
          <FavouriteIcon onClick={toggleFavourite}>
            <IconContainer>
              <SocialIcon
                src={
                  isFavourite
                    ? '/images/heart_icon_fill.png'
                    : '/images/heart_icon_dark.png'
                }
              />
            </IconContainer>
            <PrimaryTextRegular>{FAVOURITE_TEXT}</PrimaryTextRegular>
          </FavouriteIcon>
        </div>

        <ButtonsContainer>
          <CardButtonOutline
            onClick={() => handleActionButtonClick('addToCart')}
          >
            {ADD_TO_CART_TEXT}
            {isAddingToCart && (
              /*
               * svg circle to show spinner
               */
              <svg
                className="w-5 h-5 ml-3 -mr-1 animate-spin text-primaryDark"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox=" 0 0 24 24"
              >
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
              </svg>
            )}
          </CardButtonOutline>
          <CardButtonFill onClick={() => handleActionButtonClick('checkout')}>
            {BUY_NOW_TEXT}
          </CardButtonFill>
        </ButtonsContainer>
      </ProductDetailContainer>

      <PaymentShippingDetailContainer>
        <PaymentShippingDetailInner>
          <HeaderText>{SHIPPING_DETAILS.title}</HeaderText>
          {SHIPPING_DETAILS.subTexts.map((text) => (
            <FlexBetweenText key={text.id}>
              <Icon src={text.icon} />
              <PSecondary>{text.value}</PSecondary>
            </FlexBetweenText>
          ))}
        </PaymentShippingDetailInner>

        <PaymentShippingDetailInner>
          <HeaderText>{PAYMENT_DETAILS.title}</HeaderText>
          {PAYMENT_DETAILS.subTexts.map((text) => (
            <FlexBetweenText key={text.id}>
              <Icon src={text.icon} />
              <PSecondary>{parse(text.value)}</PSecondary>
            </FlexBetweenText>
          ))}
        </PaymentShippingDetailInner>
      </PaymentShippingDetailContainer>
      <ToastContainer position="top-right" />
    </Container>
  )
}

export default ProductPage
