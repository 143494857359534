import { IUserForm } from '../components/editAccountPage'

export interface ICheckoutAddressInput {
  id: number
  label: string
  name: string
  type: 'text' | 'number' | 'password'
  isRequired: boolean
}

export const initialAddressValues: IUserForm = {
  firstname: '',
  lastname: '',
  address1: '',
  address2: '',
  lane: '',
  road: '',
  state_id: '',
  district_id: '1',
  subdistrict_id: '',
  country_id: 'Thailand',
  zipcode: '',
  alternative_phone: '',
}

export const CHECKOUT_ADDRESS_INPUTS: Array<ICheckoutAddressInput> = [
  {
    id: 1,
    label: 'ชื่อ',
    name: 'firstname',
    type: 'text',
    isRequired: true,
  },
  {
    id: 2,
    label: 'นามสกุล',
    name: 'lastname',
    type: 'text',
    isRequired: true,
  },
  {
    id: 3,
    label: 'บ้านเลขที่',
    name: 'address1',
    type: 'text',
    isRequired: true,
  },
  {
    id: 4,
    label: 'สถานที่/อาคาร',
    name: 'address2',
    type: 'text',
    isRequired: false,
  },
  {
    id: 5,
    label: 'ซอย',
    name: 'lane',
    type: 'text',
    isRequired: false,
  },
  {
    id: 6,
    label: 'ถนน',
    name: 'road',
    type: 'text',
    isRequired: false,
  },
]

export const USER_ADDRESS_INPUTS: ICheckoutAddressInput[] = [
  {
    id: 1,
    label: 'ชื่อ',
    name: 'firstname',
    type: 'text',
    isRequired: true,
  },
  {
    id: 2,
    label: 'นามสกุล',
    name: 'lastname',
    type: 'text',
    isRequired: true,
  },
  {
    id: 3,
    label: 'บ้านเลขที่',
    name: 'address1',
    type: 'text',
    isRequired: true,
  },
  {
    id: 4,
    label: 'สถานที่/อาคาร',
    name: 'address2',
    type: 'text',
    isRequired: false,
  },
  {
    id: 5,
    label: 'ซอย',
    name: 'lane',
    type: 'text',
    isRequired: false,
  },
  {
    id: 6,
    label: 'ถนน',
    name: 'road',
    type: 'text',
    isRequired: false,
  }
]
