import React from 'react'

import {
  InnerContainer,
  H1Primary,
  H3SecondaryDark,
  PSecondaryDark,
} from '../../GlobalStyles'
import { BreadCrumbs } from '..'
import { Container } from '../faqPage/Faq.styles'
import { FlexContainer } from './PurchasePage.styles'
import { IContent } from '../../@types/staticContent'

const PAGE_HEADING = 'วิธีการสั่งซื้อสินค้า'

interface PurchasePageProps {
  content: Array<IContent>
}

const PurchasePage = ({ content }: PurchasePageProps) => {
  return (
    <Container>
      <BreadCrumbs breadCrumb={PAGE_HEADING} />
      <H1Primary>{PAGE_HEADING}</H1Primary>
      <InnerContainer>
        {content.map(({ title, description }) => (
          <FlexContainer key={title}>
            <H3SecondaryDark>{title}</H3SecondaryDark>
            <PSecondaryDark>{description}</PSecondaryDark>
          </FlexContainer>
        ))}
      </InnerContainer>
    </Container>
  )
}

export default PurchasePage
