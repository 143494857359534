import { IFilters, FilterType } from '../@types/filter'

/*
 * You'll notice id's 2 & 4 is missing as they come from the server
 */
export const LEFT_FILTERS: Array<IFilters<FilterType>> = [
  {
    id: 1,
    type: 'price',
    component: 'slider',
    header: 'ราคา (฿)',
    values: { min: 0, max: 12000 },
  },
  {
    id: 3,
    type: 'offers',
    component: 'checkbox',
    header: 'ข้อเสนอ',
    values: {
      hot_deal: { value: 'Hot Deal', checked: false },
      flash_sale: {
        value: 'Flash Sale',
        checked: false,
      },
    },
  },
  {
    id: 5,
    type: 'width',
    component: 'slider',
    header: 'กว้าง (CM)',
    values: { min: 0, max: 12000 },
  },
  {
    id: 6,
    type: 'length',
    component: 'slider',
    header: 'ยาว (CM)',
    values: { min: 0, max: 12000 },
  },
  {
    id: 7,
    type: 'height',
    component: 'slider',
    header: 'สูง (CM)',
    values: { min: 0, max: 12000 },
  },
  {
    id: 8,
    type: 'delivery_methods',
    component: 'checkbox',
    header: 'การจัดส่ง',
    values: {
      kerry_express: { value: 'Kerry', checked: false },
      j_and_t_express: { value: 'J&T', checked: false },
      scg_express: { value: 'SCG', checked: false },
      nim_express: { value: 'NIM', checked: false },
      flash_express: { value: 'Flash', checked: false },
      lcs_express: { value: 'LCS', checked: false },
    },
  },
  {
    id: 9,
    type: 'payment_methods',
    component: 'checkbox',
    header: 'การชำระเงิน',
    values: {
      installment: { value: 'ผ่อน 0%', checked: false },
      cod: { value: 'ชำระปลายทาง', checked: false },
      credit_debit: { value: 'บัตรเครดิต/บัตรเดบิต ', checked: false },
      bank_transfer: { value: 'โอนผ่านธนาคาร', checked: false },
    },
  },
]

export const FILTER_SUBMIT_TEXT = 'ค้นหา'

export const FILTER_COLUMN_HEADER = 'ฟิลเตอร์'

export interface ICheckbox {
  label: string
  value: any
}

export const OFFERS_OPTIONS: ICheckbox[] = [
  {
    label: 'Hot Deal',
    value: 'hot_deal',
  },
  { label: 'Flash Sale', value: 'flash_sale' },
]

export const DELIVERY_METHODS: ICheckbox[] = [
  { label: 'Kerry', value: 'kerry_express' },
  { label: 'J&T', value: 'j_and_t_express' },
  { label: 'SCG', value: 'scg_express' },
  { label: 'NIM', value: 'nim_express' },
  { label: 'Flash', value: 'flash_express' },
  { label: 'LCS', value: 'lcs_express' },
]

export const PAYMENT_METHODS: ICheckbox[] = [
  { label: 'ผ่อน 0%', value: 'installment' },
  { label: 'ชำระปลายทาง', value: 'cod' },
  { label: 'บัตรเครดิต/บัตรเดบิต', value: 'credit_debit' },
  { label: 'โอนผ่านธนาคาร', value: 'bank_transfer' },
]

export const MIN_SLIDER_VALUE = 0
export const MAX_SLIDER_VALUE = 12000
export const CONTAINER_ID = '#ProductsSection'
