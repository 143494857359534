import * as React from 'react'
import { NavLanguage } from '../@types/nav'

const useLanguage = () => {
  const [language, setLanguage] = React.useState<NavLanguage>('name_th')

  React.useEffect(() => {
    const currentLang = localStorage.getItem('language') as NavLanguage
    setLanguage(currentLang ?? 'name_th')
  }, [])

  const changeLanguage = (lang: NavLanguage) => {
    if (lang === language) {
      return
    }
    setLanguage(lang)
    localStorage.setItem('language', lang)
  }

  return { language, changeLanguage }
}

export default useLanguage
