import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IBlog } from '../../@types/blogs'

const Blog = ({
  id,
  slug,
  short_description,
  thumbnail,
  taxon,
  title,
  blogLocation = 'home',
}: IBlog) => {
  const isFlexCol = blogLocation === 'categoryPage'
  const urlEndpoint = slug || id

  return (
    <a href={`/blogs/${urlEndpoint}`} className="no-underline">
      <div
        className={twMerge(
          'flex w-full h-full rounded shadow-md aspect-none',
          isFlexCol && 'lg:flex-col'
        )}
      >
        <div
          className={twMerge(
            'relative flex-1 w-full h-full aspect-w-4 aspect-h-1',
            isFlexCol && 'lg:aspect-none'
          )}
        >
          <img
            className={twMerge(
              'object-cover w-full h-full rounded-l',
              isFlexCol && 'lg:rounded-t lg:rounded-b-none'
            )}
            src={thumbnail}
            alt={title}
          />
          {taxon && (
            <div
              className={twMerge(
                'absolute left-0 top-4',
                isFlexCol && 'lg:top-8'
              )}
            >
              <span className="px-2 py-1 text-sm text-white capitalize rounded-r-full bg-primary">
                {taxon}
              </span>
            </div>
          )}
        </div>

        <div className="flex-1">
          <div className="h-full p-4 lg:space-y-2">
            <div className="w-full line-clamp-2">
              <h3 className="text-sm text-left text-primaryDark lg:text-lg">
                {title}
              </h3>
            </div>
            <div className="line-clamp-2">
              <p className="text-left">{short_description}</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default Blog
