import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-8
  pb-8
`

export const CheckoutStagesContainer = tw.div`
  flex
  items-center
  justify-between
  px-4
`

export const CheckoutStageContainer = tw.div`
  flex
  items-center
  justify-center
  space-x-4
  mb-8
`

export const CheckoutStageCount = tw.div<{
  $isActive: boolean
  $isComplete: boolean
}>`
  flex
  items-center
  justify-center
  rounded-full
  h-10
  w-10
  border-2
  ${(props) =>
    props.$isActive || props.$isComplete ? 'border-primary' : 'border-gray-200'}
  ${(props) => (props.$isActive ? 'text-primary' : 'text-secondaryDark')}
  ${(props) => (props.$isComplete ? 'bg-primary' : '')}
`

export const CheckoutStage = tw.div<{
  $isActive: boolean
  $isComplete: boolean
}>`
  hidden
  lg:block
  text-md
  ${(props) => (props.$isActive ? 'text-primary' : 'text-secondaryDark')}
  ${(props) => (props.$isComplete ? 'text-primary' : '')}
  font-bold
  text-center
`

export const FormAndCheckoutSectionContainer = tw.div`
  flex
  flex-col
  lg:flex-row
  space-y-4
  lg:space-y-0
  lg:space-x-4
`

export const FormContainer = tw.div`
  w-full
  lg:w-3/4
  space-y-8
`

export const CheckoutSectionContainer = tw.div`
  w-full
  lg:w-1/4
  space-y-8
`

export const ContactInfoContainer = tw.div`
  flex
  flex-col
  lg:flex-row
  items-center
  justify-between
  py-4
  px-2
  lg:py-6
  lg:px-8
  border
  border-primary
  rounded-md
  space-y-4
  lg:space-y-0
  lg:space-x-4
`

export const DeliveryFormContainer = tw.div`
  flex
  flex-col
  rounded-lg
  border
  border-primary
`

export const DeliveryHeaderContainer = tw.div`
  w-full
  text-lg
  text-white
  font-semibold
  flex
  items-center
  justify-center
  bg-primary
  py-8
  rounded-t-md
`

export const FormikContainer = tw.div`
  grid
  gap-6
  grid-cols-1
  lg:grid-cols-2
  py-4
  px-2
  lg:py-6
  lg:px-8
  border-primary
  text-primary
`

export const CartItemsContainer = tw.div`
  py-4
  px-2
  lg:py-6
  lg:px-8
  space-y-6
`

export const CouponContainer = tw.div`
  py-4
  px-2
  lg:py-6
  lg:px-8
  space-y-4
  border-primary
`

export const PrimaryButton = tw.button`
  border-2
  py-3
  rounded-md
  border-primaryDark
  bg-primaryDark
  text-white
  w-full
  hover:bg-primary
  hover:border-primary
`

export const SecondaryButton = tw.button`
  border-2
  py-3
  rounded-md
  border-primaryDark
  bg-white
  text-primaryDark
  w-full
  hover:bg-primary
  hover:text-white
  hover:border-primary
`

export const PriceContainer = tw.div`
  flex
  py-2
  items-start
  justify-between
  text-primary
`

export const PriceText = tw.p`
  font-normal
  text-xl
  text-primaryDark
`

export const TotalPriceContainer = tw.div`
  flex
  py-3
  items-start
  justify-between
  text-primaryDark
`

export const PriceTextBold = tw.p`
  font-semibold
  text-xl
`

export const PriceTaxContainer = tw.div`
  flex
  flex-col
  items-end
  justify-center
  space-y-1
`

export const TaxText = tw.p`
  font-sm
  text-primaryDark
`

export const PriceBold = tw.p`
  font-semibold
  text-xl
`

export const StyledForm = tw.form`
  flex
  flex-col
  lg:flex-row
  space-y-4
  lg:space-y-0
  lg:space-x-4
`
