import tw from 'tailwind-styled-components'

export const Table = tw.table`
  table
  w-full
  border-seperate
  space-y-6
  text-sm
  hidden
  lg:block
`

export const TableHead = tw.thead`
  bg-gray-200
  text-secondaryGrayDark
`

export const Th = tw.th`
  p-3
`

export const TableBody = tw.tbody``

export const Tr = tw.tr``

export const Td = tw.td`
  p-3
  text-center
`

export const Image = tw.img`
  w-20
  h-20
  object-cover
`

export const Flex = tw.div`
  flex
  items-center
  space-x-4
`

export const FlexBetween = tw.div<{ $isCartItemRemovable: boolean }>`
  flex
  items-center
  ${(props) => (props.$isCartItemRemovable ? 'justify-end' : 'justify-center')}
  space-x-4
`

export const FlexCol = tw.div`
  flex
  flex-col
  space-y-4
  justify-between
  text-left
`

export const PriceFlex = tw.div`
  flex
  flex-col
  justify-center
  items-center
  space-y-2
`

export const LineClamp = tw.div`
  line-clamp-2  
`
