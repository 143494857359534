import React from 'react'

import {
  Container,
  Label,
  RequiredSpan,
  InputElement,
  ErrorText,
} from './Input.styles'

interface InputProps {
  isRequired?: boolean
  labelText: string
  isLabelBold?: boolean
  flexCol?: boolean
  hasError?: boolean
  errorText?: string
  onChange: (e: React.ChangeEvent<any>) => void
  name: string
  type: 'number' | 'text' | 'password' | 'tel'
}

/* eslint react/display-name: 0 */
const Input = React.forwardRef(
  (
    {
      isRequired,
      labelText,
      isLabelBold,
      flexCol,
      hasError,
      errorText,
      onChange,
      name,
      type,
    }: InputProps,
    ref
  ) => (
    <Container $isFlexCol={!!flexCol}>
      <Label $isLabelBold={!!isLabelBold}>
        {labelText}
        {isRequired && <RequiredSpan>*</RequiredSpan>}
      </Label>
      <InputElement
        name={name}
        type={type}
        onChange={onChange}
        $hasError={!!hasError}
        ref={ref}
      />
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  )
)

Input.defaultProps = {
  isRequired: false,
  flexCol: false,
  hasError: false,
  errorText: '',
  isLabelBold: false,
}

export default Input
