import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  py-4
  lg:py-12
`

export const BlogsContainer = tw.div`
  w-full
  flex
  flex-col
  md:flex-row
  space-y-4
  md:space-y-0
  justify-between
`

export const HeaderContainer = tw.div`
  flex
  justify-between
  items-center
  pb-6
`
