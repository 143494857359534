import tw from 'tailwind-styled-components'

export const Label = tw.label`
  flex
  items-center
  space-x-3
`

export const Input = tw.input`
  appearance-none
  h-5
  w-5
  border
  border-gray-300
  rounded-md
  checked:border-transparent 
  focus:outline-none
`

export const Span = tw.span`
  text-gray-700
`
