import tw from 'tailwind-styled-components'

export const Container = tw.div`
  w-full
  bg-offWhite
  relative
  rounded-md
  mt-0
  shadow-md
  max-w-300
`

export const Inner = tw.div`
  px-3
  pt-3
  pb-5
  space-y-2
`

export const ImageContainer = tw.div`
  flex
  w-full
  h-200
  items-center
  justify-center
  bg-white
`

export const Image = tw.img`
  w-full
  h-full
  object-contain
`

export const BadgesContainer = tw.div`
  absolute
  top-5
  inset-x-0
  flex
  items-center
  justify-between
`

export const RoundedBadgeContainer = tw.div`
  w-26
`

export const ZigZagContainer = tw.div`
  flex
  items-center
  justify-center
  ml-auto
  text-white
  text-sm
  h-6
  w-14
  bg-discountLabel
`

export const IconContainer = tw.div``

export const Icon = tw.img`
  object-contain
`

export const CardDataContainer = tw.div`
  space-y-2
`

export const OutlineButtonsContainer = tw.div`
  flex
  justify-between
  space-x-2
`

export const OutlineButton = tw.button<{ $isVisible: boolean }>`
  ${(props) => (props.$isVisible ? 'opacity-1' : 'opacity-0')}
  py-1
  border-0.5
  border-primary
  text-xs
  rounded-sm
  text-primary
  font-light
  w-1/2
`

export const VolmuePricingText = tw.div`
  text-xs
  font-light
  text-primaryDark
  leading-4
  w-35
`

export const LineClamp = tw.div`
  h-10
  lg:h-14
  line-clamp-2
`

export const FlexBetween = tw.div`
  flex
  items-center
  justify-between
  space-x-2
`

export const PriceAndAmountGrid = tw.div`
  grid
  grid-rows-2
  grid-cols-2
  gap-4
  h-28
`

export const Flex = tw.div`
  flex
  items-baseline
  space-x-1
`

export const AmountButtonsContainer = tw.div<{ $isBorderLight: boolean }>`
  flex
  items-center
  justify-center
`

export const AmountButton = tw.button<{ $isBorderLight: boolean }>`
  border
  border-primaryDark
  text-primaryDark
  h-10
  w-12
  py-3
  flex
  items-center
  justify-center
  bg-white
  hover:bg:primary
`
/*
 * I've also set the border to be thinner when the input is wide,
 * as they are both true at the same time. Saves passing a second
 * prop
 */
export const AmountInput = tw.input<{
  $isInputWide: boolean
  $isBorderLight: boolean
}>`
  ${(props) => (props.$isInputWide ? 'w-16' : 'w-10')}
  border
  border-l-0
  border-r-0
  border-primaryDark
  text-primaryDark
  text-lg
  bold
  self-center
  py-3
  text-center
  h-10
  flex
  flex-1
  outline-none
`

export const CardButtonsContainer = tw.div`
  flex
  items-center
  justify-between
  space-x-2
`

export const CardButton = tw.button`
  py-1
  text-center
  text-sm
  font-light
  bold
  rounded-5px
  w-1/2
`

export const CardButtonOutline = tw(CardButton)`
  bg-white
  border
  border-primaryDark
  primaryDark
  text-primaryDark
  flex
  items-center
  justify-center
`

export const CardButtonFill = tw(CardButton)`
  border-2
  border-primaryDark
  primaryDark
  bg-primaryDark
  text-white
`
