import tw from 'tailwind-styled-components'

export const DropdownHeaderContainer = tw.div`
  flex
  items-center
  justify-between
`

export const IconContainer = tw.div`
  w-8
`

export const Icon = tw.img<{ $isOpen: boolean }>`
  ${(props) => (props.$isOpen ? '' : 'transform rotate-180')}
  object-contain
  transition-transform
`
