import tw from 'tailwind-styled-components'

export const PaymentButtonsContainer = tw.div`
  flex
  py-6
  px-8
  space-x-6
`

export const PaymentButton = tw.button<{ $isActive: boolean }>`
  cursor-pointer
  font-semibold
  border-2
  hover:text-primaryDark
  hover:border-primary
  ${(props) => (props.$isActive ? 'border-primaryDark' : 'border-gray-300')}
  ${(props) => (props.$isActive ? 'text-primaryDark' : 'text-gray-300')}
  rounded-md
  px-8
  py-6
`
