import React from 'react'

import { Container, InputElement, Button } from './InputButtonGroup.styles'

interface InputButtonGroupProps {
  placeholder: string
  buttonText: string
}

const InputButtonGroup = ({ placeholder, buttonText }: InputButtonGroupProps) => {
  return (
    <Container>
      <InputElement placeholder={placeholder} />
      <Button>{buttonText}</Button>
    </Container>
  )
}

export default InputButtonGroup
