import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  lg:py-6
  py-3
  pb-12
  block
  lg:flex
`

export const LeftContainer = tw.div`
  lg:w-5/12
  w-full
  space-y-8
`

export const ContactDetailsContainer = tw.div`
  lg:px-16
  px-4
  py-4
  space-y-2
`

export const Form = tw.form`
  lg:w-6/12
  w-full
  lg:px-16
  px-4
  lg:py-10
  py-4
  flex
  flex-col
  items-center
  space-y-6
  bg-gray-100
  rounded-md
`

export const Flex = tw.div`
  w-full
  flex
  justify-between
  lg:space-x-4
  space-x-2
`

export const HalfWidthDiv = tw.div`
  w-1/2
  lg:w-full
`

export const ButtonContainer = tw.div`
  w-3/12
  flex
  items-center
  justify-center
`

export const FlexCol = tw.div`
  flex
  flex-col
  space-y-2
  w-full
`

export const DetailContainer = tw.div`
  flex
  space-x-2
`

export const Textarea = tw.textarea`
  w-full
  h-100
  rounded-md
  border
  border-gray-300
  px-4
  py-2
`

export const GrayLabel = tw.p`
  text-sm
  text-gray-300
`
