import React from 'react'

import { Container, Link, Image } from './Banner.styles'

// import interface banner
import { IBanner } from '../../@types/banners'

interface BannerProps {
  banner: IBanner
}

const Banner = ({ banner }: BannerProps) => {
  if (!banner) {
    return null
  }

  return (
    <div className="container px-2 pb-6 mx-auto max-w-7xl md:px-4 lg:pb-8">
      <Link href={banner.link_url}>
        <Image loading="lazy" src={banner.image_url} />
      </Link>
    </div>
  )
}

export default Banner
