import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import queryString from 'query-string'
import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { CategoryProductsSection, FilterColumn } from '..'
import { ICardItem } from '../../@types/cards'
import { IPagination } from '../../@types/pagination'
import { MOBILE_WIDTH } from '../../constants/windowSize'
import { useWindowSize } from '../../hooks/useWindowSize'
import { PaginationLabel } from '../CategoryProductsSection/CategoryProductsSection.styles'
import { IconContainer } from '../LowerNav/LowerNav.styles'
import { Container, Outer } from './CategoryMainBody.styles'

export interface ISpecification {
  spec_id: number
  spec_name: string
  brand_id?: number
  brand_name?: string
}

export interface IBrand {
  spec_id?: number
  spec_name?: string
  brand_id: number
  brand_name: string
}

interface CategoryMainBodyProps {
  products: Array<ICardItem>
  specifications: Array<ISpecification>
  brands: Array<IBrand>
  totalProducts: number
  pagination: IPagination
}

const PaginationPrevious = () => (
  <PaginationLabel>
    <IconContainer>
      <ArrowLeftIcon className="w-6 h-6 text-primaryDark" />
    </IconContainer>
    <div>ก่อนหน้า</div>
  </PaginationLabel>
)

const PaginationNext = () => (
  <PaginationLabel className="float-right">
    <div>ถัดไป</div>
    <IconContainer>
      <ArrowRightIcon className="w-6 h-6 text-primaryDark" />
    </IconContainer>
  </PaginationLabel>
)

const CategoryMainBody = ({
  products,
  specifications,
  brands,
  totalProducts,
  pagination,
}: CategoryMainBodyProps) => {
  const isMobileWidth = (useWindowSize().width || 0) <= MOBILE_WIDTH
  const [shouldShowFilterColumn, setShouldShowFilterColumn] = useState(false)

  const toggleFilterColumnVisibility = () =>
    setShouldShowFilterColumn((prevState) => !prevState)

  const handlePageChange = ({ selected }: { selected: number }) => {
    const parsed = queryString.parse(window.location.search)
    const queryObj = {
      ...parsed,
      page: selected + 1,
    }
    const stringified = queryString.stringify(queryObj)
    window.location.search = stringified
  }

  return (
    <Outer id="ProductsSection">
      <Container>
        <FilterColumn
          specifications={specifications}
          brands={brands}
          isMobileWidth={isMobileWidth}
          showColumn={shouldShowFilterColumn}
          hideColumn={setShouldShowFilterColumn}
        />
        <div className="flex flex-col flex-1">
          <CategoryProductsSection
            products={products}
            toggleFilterColumnVisibility={toggleFilterColumnVisibility}
            totalProducts={totalProducts}
          />
          {pagination.total_pages > 1 && (
            <div className="px-4">
              <ReactPaginate
                pageCount={pagination.total_pages}
                initialPage={pagination.current_page - 1}
                disableInitialCallback
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                previousLabel={
                  !pagination.is_first_page && <PaginationPrevious />
                }
                previousClassName="w-1/3"
                nextLabel={!pagination.is_last_page && <PaginationNext />}
                nextClassName="w-1/3"
                breakLabel="..."
                breakClassName="hidden md:block p-4 text-gray-500"
                containerClassName="flex border-t-2 border-gray-300 justify-between items-start"
                pageClassName="hidden md:block flex-shrink border-t-2 border-transparent p-4 text-gray-500 hover:border-primaryDark"
                activeClassName="border-t-2 border-primaryDark text-primaryDark"
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </Container>
    </Outer>
  )
}

export default CategoryMainBody
