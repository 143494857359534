import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { DealCard, FlashCard, NoProductsMessage } from '..'
import { ICardItem } from '../../@types/cards'
import { CONTAINER_ID } from '../../constants/categoryFilters'
import { SortingType, SORTING_OPTION } from '../../constants/categorySorting'
import { PAGINATION_VALUES } from '../../constants/categoryTopFilters'
import { MOBILE_WIDTH } from '../../constants/windowSize'
import { scrollToHash } from '../../helpers'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  Container,
  FilterContainer,
  FilterHeading,
  FilterItem,
  FilterMobileContainer,
  Filters,
  Icon,
  IconContainer,
  Menu,
  MobileFiltersButton,
  Pagination,
  PaginationHeading,
  PaginationItem,
  PaginationLabel,
  ProductsGrid,
} from './CategoryProductsSection.styles'

const FILTERS_HEADING = 'เรียงจาก:'
const SHOW_FILTERS_HEADING = 'ฟิลเตอร์'
const PAGINATION_HEADING = 'แสดงผล'
const EMPTY_MESSAGE = 'ไม่พบสินค้า'

/*
 * @params: param key and value
 *
 * takes the currently set filters and sends them to the server as query params
 * ex: filter { price_order: 'high' }, will fetch http://localhost/t/categories?price_order=high
 */
function updatePageFilter(key: string, value: string) {
  const currentUrl = new URL(window.location.href)

  currentUrl.searchParams.set(key, value)

  window.location.href = String(currentUrl) + CONTAINER_ID
}

const parseUrl = () => queryString.parse(window.location.search)
const stringifyUrl = (obj: any) => queryString.stringify(obj)

interface CategoryProductsSectionProps {
  products: Array<ICardItem>
  toggleFilterColumnVisibility: () => void
  totalProducts: number
}

const CategoryProductsSection = ({
  products,
  toggleFilterColumnVisibility,
  totalProducts,
}: CategoryProductsSectionProps) => {
  const isMobileWidth = (useWindowSize().width || 0) <= MOBILE_WIDTH
  /*
   * component to view products changes for mobile view from falsh cards to
   * deal card
   */
  const Component = isMobileWidth ? DealCard : FlashCard
  /*
   * used to hide and show the right side filter menu in mobile
   */
  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [currentProductsPerPage, setCurrentProductsPerPage] = useState(
    PAGINATION_VALUES[0].value
  )
  const [currentPage, setCurrentPage] = useState(1)

  const handlePages = (page: number) => {
    setCurrentProductsPerPage(page)

    updatePageFilter('product_items', String(page))
  }

  useEffect(() => {
    // all the params from the url
    const parsed = queryString.parse(window.location.search)

    if ('product_items' in parsed) {
      setCurrentProductsPerPage(Number(parsed.product_items))
    }

    if ('page' in parsed) {
      setCurrentPage(Number(parsed.page))
    }

    if (window.location.href.includes('#')) {
      scrollToHash(CONTAINER_ID)
    }
  }, [])

  const handleSortChange = (sort: SortingType) => () => {
    const parsed = parseUrl()
    const newSortQuery = {
      ...parsed,
      sort_by: sort,
    }
    const stringifiedUrl = stringifyUrl(newSortQuery)
    window.location.search = stringifiedUrl
  }

  const topFilter = SORTING_OPTION.map((option) => {
    const { sort_by: sortBy } = parseUrl()

    return isMobileWidth ? (
      <FilterContainer
        key={option.value}
        $isActive={sortBy === option.value}
        onClick={handleSortChange(option.value)}
      >
        <FilterItem
          className="flex justify-between w-full text-primaryDark"
          $isActive={sortBy === option.value}
        >
          {option.label}
          {sortBy === option.value && (
            <IconContainer>
              <Icon src="/images/filterCheckIcon.png" />
            </IconContainer>
          )}
        </FilterItem>
      </FilterContainer>
    ) : (
      <FilterItem
        key={option.value}
        $isActive={sortBy === option.value}
        onClick={handleSortChange(option.value)}
      >
        {option.label}
      </FilterItem>
    )
  })

  const PaginationPrevious = (
    <PaginationLabel>
      <IconContainer>
        <Icon src="/images/paginationLeftIcon.png" />
      </IconContainer>
      Previous
    </PaginationLabel>
  )
  const PaginationNext = (
    <PaginationLabel className="float-right">
      Next
      <IconContainer>
        <Icon src="/images/paginationRightIcon.png" />
      </IconContainer>
    </PaginationLabel>
  )

  return (
    <Container>
      <Menu>
        {isMobileWidth ? (
          <>
            <MobileFiltersButton onClick={toggleFilterColumnVisibility}>
              <IconContainer>
                <Icon src="/images/filterIcon.svg" />
              </IconContainer>
              <FilterHeading>{SHOW_FILTERS_HEADING}</FilterHeading>
            </MobileFiltersButton>
            <MobileFiltersButton
              onClick={() => setShowFilterMenu((prevState) => !prevState)}
            >
              <FilterHeading>{FILTERS_HEADING}</FilterHeading>
              <IconContainer>
                <Icon src="/images/chevronDownPrimary.svg" />
              </IconContainer>

              <FilterMobileContainer $isVisible={showFilterMenu}>
                {topFilter}
              </FilterMobileContainer>
            </MobileFiltersButton>
          </>
        ) : (
          <>
            <Filters>
              <FilterHeading>{FILTERS_HEADING}</FilterHeading>
              {topFilter}
            </Filters>
            <Pagination>
              <PaginationHeading>{PAGINATION_HEADING}</PaginationHeading>
              {PAGINATION_VALUES.map((page) => (
                <PaginationItem
                  key={page.id}
                  onClick={() => handlePages(page.value)}
                  $isActive={currentProductsPerPage === page.value}
                >
                  {page.value}
                </PaginationItem>
              ))}
            </Pagination>
          </>
        )}
      </Menu>

      {products.length ? (
        <ProductsGrid>
          {products.map((product) => (
            <Component {...product} key={product.id} />
          ))}
        </ProductsGrid>
      ) : (
        <NoProductsMessage message={EMPTY_MESSAGE} isWhite />
      )}

      {products.length > currentProductsPerPage && (
        <ReactPaginate
          previousLabel={PaginationPrevious}
          nextLabel={PaginationNext}
          breakLabel="..."
          pageCount={
            totalProducts > 0 ? totalProducts / currentProductsPerPage : 0
          }
          marginPagesDisplayed={2}
          breakClassName="hidden md:block p-4 text-gray-500"
          containerClassName="flex border-t-2 border-gray-300 justify-between items-start"
          pageClassName="hidden md:block flex-shrink border-t-2 border-transparent p-4 text-gray-500 hover:border-primaryDark"
          activeClassName="border-t-2 border-primaryDark text-primaryDark"
          previousClassName="w-1/3"
          nextClassName="w-1/3"
          pageRangeDisplayed={3}
          forcePage={currentPage - 1}
          onPageChange={({ selected }) =>
            updatePageFilter('page', String(selected + 1))
          }
        />
      )}
    </Container>
  )
}

export default CategoryProductsSection
