import tw from 'tailwind-styled-components'

export const SliderNumber = tw.div<{ $index: number }>`
  ${(props) => (!props.$index ? '-left-2' : '-left-6')}
  absolute
  bg-white
  px-1
  py-1
  -bottom-14
  mb-4
  text-sm
  text-gray-700
  p-4
  focus-visible:outline-none
`
