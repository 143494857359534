import tw from 'tailwind-styled-components'

export const Button = tw.button`
  px-6
  py-3
  border
  border-gray-200
  text-gray-200
  hover:border-primary
  hover:text-primaryDark
  cursor-pointer
`
