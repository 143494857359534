import React from 'react'
import { useProductCountToggler } from '../../hooks'

import { getCSRFToken } from '../../helpers'

const updateUrl = `/cart`

const fetchBase = (formData: any) => {
  formData.append('cart', String(null))
  formData.append('authenticity_token', '')
  formData.append(`is_ajax`, String(true))

  fetch(updateUrl, {
    method: 'PATCH',
    headers: {
      'X-CSRF-TOKEN': getCSRFToken(),
    },
    body: formData,
  }).then((res) => {
    if (res.ok) {
      window.location.reload()
    }
  })
}

interface CartItemTogglerProps {
  index: number
  lineItem: {
    id: number
    master_variant: number
    quantity: number
  }
}

const CartItemToggler = ({ lineItem, index }: CartItemTogglerProps) => {
  const updateLineItem = (newItemCount: number) => {
    const formData = new FormData()

    formData.append(
      `order[line_items_attributes][${index}][quantity]`,
      String(newItemCount)
    )
    formData.append(
      `order[line_items_attributes][${index}][master_variant]`,
      String(lineItem.master_variant)
    )
    formData.append(
      `order[line_items_attributes][${index}][id]`,
      String(lineItem.id)
    )

    fetchBase(formData)
  }

  const { itemCount, ProductCountToggler } = useProductCountToggler({
    itemCount: lineItem.quantity,
    onChange: updateLineItem,
    onIncrease: () => updateLineItem(itemCount + 1),
    onDecrease: () => updateLineItem(itemCount - 1),
  })

  return <ProductCountToggler isBorderLight isInputWide />
}

export default CartItemToggler
