import React from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IBrand } from '../../@types/brand'
// custom swiper css for pagination
import './ShopByBrand.css'
import { Image, ImageContainer } from './ShopByBrand.styles'

interface ShopByBrandProps {
  brands: IBrand[]
}

SwiperCore.use([Pagination, Navigation, Scrollbar])

const ShopByBrand = ({ brands }: ShopByBrandProps) => {
  return (
    <div className="container px-2 py-6 mx-auto darkSwiperPagination max-w-7xl md:px-4 lg:py-8">
      <h2 className="text-xl font-bold text-primaryDark lg:text-2xl">
        Shop by Brand
      </h2>

      <Swiper
        slidesPerView={2}
        slidesPerColumn={2}
        slidesPerGroup={2}
        spaceBetween={5}
        slidesPerColumnFill="row"
        breakpoints={{
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
            slidesPerGroup: 2,
            slidesPerColumn: 2,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 16,
            slidesPerGroup: 6,
            slidesPerColumn: 2,
          },
        }}
        pagination
      >
        {brands.map((brand) => (
          <SwiperSlide key={brand.brand_id}>
            <a href={brand.brand_link}>
              <ImageContainer>
                <Image src={brand.brand_image} alt={brand.brand_name} />
              </ImageContainer>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default ShopByBrand
