import { useState, useEffect } from 'react'

interface ISize {
  width: number | undefined
  height: number | undefined
}

export function useWindowSize(): ISize {
  /*
   * set current height and width as the initial state
   */
  const [windowSize, setWindowSize] = useState<ISize>({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // window resize handler
    function handleResize() {
      // set window size
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // window resize event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
