import { CheckCircleIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import * as React from 'react'
import { Blog, BreadCrumbs } from '..'
import { IBlog } from '../../@types/blogs'
import { ICategory } from '../../@types/category'
import { MOBILE_WIDTH } from '../../constants/windowSize'
import { H1SecondaryDark } from '../../GlobalStyles'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  FilterContainer,
  FilterHeading,
  FilterItem,
  FilterMobileContainer,
  IconContainer,
  MobileFiltersButton,
} from '../CategoryProductsSection/CategoryProductsSection.styles'
import {
  BlogCategories,
  BlogCategory,
  BlogCategoryContainer,
  BlogCategoryHeading,
} from './BlogsPage.styles'

interface BlogsPageProps {
  blogs: Array<IBlog>
  categories: Array<ICategory>
}

const BLOGS_HEADER = 'บทความ'
const BLOG_CATEGORY_HEADING = 'หมวดหมู่'

const BlogsPage = ({ blogs, categories }: BlogsPageProps) => {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false)

  const isMobileWidth = (useWindowSize().width || 0) <= MOBILE_WIDTH

  const blogCategoriesJSX = isMobileWidth ? (
    <div className="relative">
      <MobileFiltersButton
        onClick={() => setShowFilterMenu((prevState) => !prevState)}
      >
        <FilterHeading>{BLOG_CATEGORY_HEADING}</FilterHeading>
        <IconContainer>
          <ChevronDownIcon className="w-10 h-10 text-gray-800" />
        </IconContainer>

        <FilterMobileContainer $isVisible={showFilterMenu}>
          {categories.map((category) => (
            <a
              key={category.cate_id}
              href={`/blogs?cate_id=${category.cate_id}`}
            >
              <FilterContainer $isActive={category.is_active}>
                <FilterItem $isActive={category.is_active}>
                  {category.cate_name}
                </FilterItem>
                {category.is_active && (
                  <CheckCircleIcon className="w-8 h-8 text-primaryDark" />
                )}
              </FilterContainer>
            </a>
          ))}
        </FilterMobileContainer>
      </MobileFiltersButton>
    </div>
  ) : (
    <BlogCategories>
      <BlogCategoryHeading>{BLOG_CATEGORY_HEADING}</BlogCategoryHeading>
      <BlogCategoryContainer>
        {categories.map((category) => (
          <a key={category.cate_id} href={`/blogs?cate_id=${category.cate_id}`}>
            <BlogCategory $isActive={category.is_active}>
              {category.cate_name}
            </BlogCategory>
          </a>
        ))}
      </BlogCategoryContainer>
    </BlogCategories>
  )

  return (
    <div className="container p-4 mx-auto space-y-6 max-w-7xl">
      <BreadCrumbs breadCrumb={BLOGS_HEADER} />
      <H1SecondaryDark>{BLOGS_HEADER}</H1SecondaryDark>

      {blogCategoriesJSX}

      <div className="grid gap-6 lg:grid-cols-3 auto-rows-fr">
        {blogs &&
          blogs.map((blog) => (
            <Blog key={blog.id} {...blog} blogLocation="categoryPage" />
          ))}
      </div>
    </div>
  )
}

export default BlogsPage
