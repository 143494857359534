import React from 'react'
import parse, {
  domToReact,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { Element } from 'domhandler/lib/node'

import { InnerContainer, H1ExtraBold, PSecondaryDark } from '../../GlobalStyles'
import { Container } from '../faqPage/Faq.styles'
import { BreadCrumbs } from '..'

/*
 * @param domNode of type DOMNode (p, h1, div, ...)
 * @returns parsed react element
 *
 * takes in a html element and replaces certain onces like p, h1, and img with react
 * elements
 *
 * ex: <p>Text</p> will return <PSecondaryDark>Text</PSecondaryDark>
 */
function replaceElement(domNode: DOMNode) {
  if (domNode instanceof Element) {
    switch (domNode.name) {
      case 'p':
        return <PSecondaryDark>{domToReact(domNode.children)}</PSecondaryDark>

      default:
        return domNode
    }
  }
}

interface IContent {
  title: string
  short_description: string
  description: string
}

interface WarrantyPageProps {
  content: IContent
}

const WarrantyPage = ({ content }: WarrantyPageProps) => {
  /*
   * parser is used to parse the html string passed from the server,
   * the default html elements are replaced with react nodes
   */
  const parserOptions: HTMLReactParserOptions = {
    replace: replaceElement,
  }

  return (
    <Container>
      <BreadCrumbs breadCrumb={content.title} />
      <InnerContainer>
        <H1ExtraBold>{content.title}</H1ExtraBold>
        <PSecondaryDark>{content.short_description}</PSecondaryDark>
        {parse(content.description, parserOptions)}
      </InnerContainer>
    </Container>
  )
}

export default WarrantyPage
