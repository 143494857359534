import React, { useState } from 'react'
import parse, {
  domToReact,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { Element } from 'domhandler/lib/node'

import {
  Container,
  TabsContainer,
  Tab,
  TabBorder,
  TabPanelContainer,
  TabPanel,
  Image,
  Ol,
  DropdownContainer,
} from './ProductFullDescription.styles'

import { Ul, Li } from '../productDetailSection/ProductPage.styles'

import { Dropdown } from '..'

import { H3SecondaryDark, PSecondaryDark } from '../../GlobalStyles'

// windowSize hook
import { useWindowSize } from '../../hooks/useWindowSize'
import { MOBILE_WIDTH } from '../../constants/windowSize'

/*
 * @param domNode of type DOMNode (p, h1, div, ...)
 * @returns parsed react element
 *
 * takes in a html element and replaces certain onces like p, h1, and img with react
 * elements
 *
 * ex: <p>Text</p> will return <PSecondaryDark>Text</PSecondaryDark>
 */
function replaceElement(domNode: DOMNode): React.ReactElement | Element | null {
  if (domNode instanceof Element) {
    switch (domNode.name) {
      case 'h1':
        return <H3SecondaryDark>{domToReact(domNode.children)}</H3SecondaryDark>

      /*
       * In some instances the images and iframes are wrapped withing a 'p' tag
       */
      case 'p':
        if (
          domNode.children &&
          domNode.children[0] instanceof Element &&
          domNode.children[0].name === 'img'
        ) {
          return <Image src={domNode.children[0].attribs.src} />
        }
        if (
          domNode.children &&
          domNode.children[0] instanceof Element &&
          domNode.children[0].name === 'iframe'
        ) {
          return (
            <iframe
              src={domNode.children[0].attribs.src ?? ''}
              className="w-full mx-auto lg:h-500 h-200 lg:w-3/4"
              title="Embeded elements"
            />
          )
        }
        return <PSecondaryDark>{domToReact(domNode.children)}</PSecondaryDark>

      case 'ul' || 'ol':
        return <Ul>{domToReact(domNode.children)}</Ul>

      case 'li':
        return <Li>{domToReact(domNode.children)}</Li>

      case 'ol':
        return (
          <Ol style={{ listStyle: 'decimal' }}>
            {domToReact(domNode.children)}
          </Ol>
        )

      case 'img':
        return <Image src={domNode.attribs.src ?? ''} />

      case 'iframe':
        return (
          <iframe
            src={domNode.attribs.src ?? ''}
            className="w-full mx-auto lg:h-500 h-200 lg:w-3/4"
            title="Embeded elements"
          />
        )

      default:
        return domNode
    }
  }
  return null
}

const TABS = ['รายละเอียดสินค้า', 'คุณสมบัติ', 'อื่นๆ']

interface IProductProperties {
  id: number
  value: string
  name: string
}

interface ProductFullDescriptionProps {
  description: string
  productProperties: Array<IProductProperties>
  extraInfo: any
}

const ProductFullDescription = ({
  description,
  productProperties,
  extraInfo,
}: ProductFullDescriptionProps) => {
  const [activeTab, setActiveTab] = useState<string>(TABS[0])

  const windowSize = useWindowSize()
  const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH

  /*
   * parser is used to parse the html string passed from the server,
   * the default html elements are replaced with react nodes
   */
  const parserOptions: HTMLReactParserOptions = {
    replace: replaceElement,
  }

  /*
   * As they are used by different components in mobile and desktop view
   * this keeps it DRY
   */
  const fullDescriptionJSX = parse(description, parserOptions)
  const productPropertiesJSX = (
    <table className="border-collapse rounded-md table-fixed">
      <thead>
        <th className="w-1/3 lg:w-1/12" aria-label="table-header" />
        <th className="w-2/3 lg:w-10/12" aria-label="table-header-2" />
      </thead>
      <tbody>
        {productProperties.map((property, index) => (
          <tr
            className={index % 2 === 0 ? 'bg-white' : 'bg-lightBlue'}
            key={property.id}
          >
            <td className="px-2 py-4 border lg:px-4 border-primary">
              {property.name}
            </td>
            <td className="px-2 py-4 border lg:px-4 border-primary">
              {property.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
  const extraInfoJSX = parse(extraInfo?.description || '', parserOptions)

  return (
    <Container>
      {isMobileWidth ? (
        <div className="divide-y">
          <DropdownContainer>
            <Dropdown showIcon={false} headerText={TABS[0]}>
              {fullDescriptionJSX}
            </Dropdown>
          </DropdownContainer>
          <DropdownContainer>
            <Dropdown defaultOpen={false} showIcon={false} headerText={TABS[1]}>
              {productPropertiesJSX}
            </Dropdown>
          </DropdownContainer>
          <DropdownContainer>
            <Dropdown defaultOpen={false} showIcon={false} headerText={TABS[0]}>
              {extraInfoJSX}
            </Dropdown>
          </DropdownContainer>
        </div>
      ) : (
        <>
          <TabsContainer>
            {TABS.map((tab, index) => (
              <TabBorder key={`${tab + index}`}>
                <Tab
                  $isActive={tab === activeTab}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </Tab>
              </TabBorder>
            ))}
          </TabsContainer>
          <TabPanelContainer $isVisible={activeTab === TABS[0]}>
            <TabPanel>{fullDescriptionJSX}</TabPanel>
          </TabPanelContainer>
          <TabPanelContainer $isVisible={activeTab === TABS[1]}>
            <TabPanel>{productPropertiesJSX}</TabPanel>
          </TabPanelContainer>
          <TabPanelContainer $isVisible={activeTab === TABS[2]}>
            <TabPanel>{extraInfoJSX}</TabPanel>
          </TabPanelContainer>
        </>
      )}
    </Container>
  )
}

export default ProductFullDescription
