import React from 'react'

import { Swiper } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'

// swiper css elements
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

import { useWindowSize } from '../../hooks/useWindowSize'
import { MOBILE_WIDTH } from '../../constants/windowSize'

const MOBILE_SLIDES_PER_VIEW = 2
const DESKTOP_SLIDES_PER_VIEW = 6
const MOBILE_SPACE_BETWEEN = 5
const DESKTOP_SPACE_BETWEEN = 20
const MOBILE_SLIDES_PER_GROUP = 2
const DESKTOP_SLIDES_PER_GROUP = 6

// install swiper addon
SwiperCore.use([Pagination, Navigation, Scrollbar])

interface SwiperElProps {
  mobileSpaceBetween?: number
  desktopSpaceBetween?: number
  mobileSlidesPerView?: number
  mobileSlidesPerGroup?: number
  desktopSlidesPerGroup?: number
  desktopSlidesPerView?: number
  slidesPerGroup?: number
  slidesPerColumnFill?: 'row' | 'column'
  slidesPerColumn?: number
  sliders: React.ReactNode
  hasNavigation?: boolean
  hasPagination?: boolean
}

const SwiperEl = ({
  mobileSlidesPerView = MOBILE_SLIDES_PER_VIEW,
  mobileSpaceBetween = MOBILE_SPACE_BETWEEN,
  desktopSpaceBetween = DESKTOP_SPACE_BETWEEN,
  desktopSlidesPerView = DESKTOP_SLIDES_PER_VIEW,
  mobileSlidesPerGroup = MOBILE_SLIDES_PER_GROUP,
  desktopSlidesPerGroup = DESKTOP_SLIDES_PER_GROUP,
  sliders,
  slidesPerColumn = 1,
  slidesPerColumnFill = 'column',
  hasNavigation = true,
  hasPagination = false,
}: SwiperElProps) => {
  const windowSize = useWindowSize()

  const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH

  const spaceBetween = isMobileWidth ? mobileSpaceBetween : desktopSpaceBetween
  const slidesPerView = isMobileWidth
    ? mobileSlidesPerView
    : desktopSlidesPerView
  const slidesPerGroup = isMobileWidth
    ? mobileSlidesPerGroup
    : desktopSlidesPerGroup

  /*
   * some props are only used in certain components. ex: not all use pagination
   * these must be added dynamically
   */
  const optionalProps: any = {}

  if (hasPagination) {
    optionalProps.pagination = {
      clickable: true,
    }
  }

  if (hasNavigation) {
    optionalProps.navigation = true
  }

  return (
    <Swiper
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      slidesPerColumn={slidesPerColumn}
      slidesPerGroup={slidesPerGroup}
      slidesPerColumnFill={slidesPerColumnFill}
      scrollbar={{ draggable: true }}
      {...optionalProps}
    >
      {sliders}
    </Swiper>
  )
}

export default SwiperEl
