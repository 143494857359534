import tw from 'tailwind-styled-components'

export const Container = tw.div<{ $isFlexCol: boolean }>`
  shadow-md
  rounded-md
  ${(props) => (props.$isFlexCol ? 'w-400' : 'w-full')}
  flex
  ${(props) => (props.$isFlexCol ? 'flex-col' : 'flex-row')}
  ${(props) => (props.$isFlexCol ? 'h-400' : 'h-200')}
  cursor-pointer
`

export const ImageContainer = tw.div<{ $isFlexCol: boolean }>`
  w-2/5
  ${(props) => (props.$isFlexCol ? 'md:w-full' : 'md:w-1/2')}
  ${(props) => (props.$isFlexCol ? 'md:h-1/2' : 'md:h-full')}
  relative
`

export const Image = tw.img<{ $isFlexCol: boolean }>`
  ${(props) => (props.$isFlexCol ? 'rounded-t-md' : 'rounded-l-md')}
  w-full
  h-full
  object-cover
`

export const BadgeContainer = tw.div`
  absolute
  top-8
  left-0
  bg-primary
  rounded-r-full
  px-2
  py-1
`

export const BadgeText = tw.p`
  text-sm
  text-white
  capitalize
`

export const TextContainer = tw.div<{ $isFlexCol: boolean }>`
  w-3/5
  ${(props) => (props.$isFlexCol ? 'md:w-full' : 'md:w-1/2')}
  flex
  flex-col
  justify-center
  items-center
  space-y-2
  ${(props) => (props.$isFlexCol ? 'p-6' : 'px-6')}
`

/*
 * text inner truncates (...) the text if it goes over a two line limit
 */
export const HeaderClamp = tw.div`
  w-full
  line-clamp-2
`

export const InnerClamp = tw.div<{ $isFlexCol: boolean }>`
  w-full
  ${(props) => (props.$isFlexCol ? 'line-clamp-3' : 'line-clamp-2')}
`
