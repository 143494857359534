import React from 'react'

import { Container, ClearButton, OutlineButton } from './CartPage.styles'
import { H1Primary, HrGray } from '../../GlobalStyles'
import { CartItemsTable, CheckoutPriceSection } from '..'
import {
  FormAndCheckoutSectionContainer,
  DeliveryFormContainer,
  FormContainer,
  CartItemsContainer,
} from '../checkoutAddress/CheckoutAddress.styles'
import { Header } from '../flashSales/FlashSales.styles'

import { ICartItem } from '../../@types/cartItem'

const ADD_MORE_PRODUCTS_TEXT = 'เลือกสินค้าเพิ่ม'
const CLEAR_ALL_ITEMS_TEXT = 'เคลียร์รายการทั้งหมด'

interface CartPageProps {
  cartItems: Array<ICartItem>
  isCartItemRemovable?: boolean
  itemTotalPrice: string
  totalPrice: string
  formToken: string
}

const CartPage = ({
  cartItems,
  isCartItemRemovable = false,
  itemTotalPrice,
  totalPrice,
  formToken,
}: CartPageProps) => {
  const [itemTotalPriceS, setItemTotalPriceS] = React.useState<number>(
    parseFloat(itemTotalPrice)
  )
  const [totalPriceS, setTotalPriceS] = React.useState<number>(
    parseFloat(totalPrice)
  )
  const [discountS, setDiscountS] = React.useState<number>(
    totalPriceS - itemTotalPriceS
  )

  const handleChangeTotalPrice = (
    subtotal: number,
    total: number,
    discount: number
  ) => {
    setItemTotalPriceS(subtotal)
    setTotalPriceS(total)
    setDiscountS(discount)
  }

  return (
    <Container>
      <H1Primary>รถเข็นสินค้า ({cartItems.length} รายการ)</H1Primary>
      <FormAndCheckoutSectionContainer>
        <FormContainer>
          <DeliveryFormContainer>
            <CartItemsContainer>
              <CartItemsTable
                cartItems={cartItems}
                isCartItemRemovable={isCartItemRemovable}
                formToken={formToken}
                handleChangeTotalPrice={handleChangeTotalPrice}
              />
            </CartItemsContainer>
          </DeliveryFormContainer>

          <HrGray />
          <Header>
            <ClearButton>{CLEAR_ALL_ITEMS_TEXT}</ClearButton>
            <OutlineButton>{ADD_MORE_PRODUCTS_TEXT}</OutlineButton>
          </Header>
        </FormContainer>

        <CheckoutPriceSection
          handleSubmit={() => window.location.replace('/checkout/address')}
          itemTotalPrice={itemTotalPriceS.toString()}
          totalPrice={totalPriceS.toString()}
          isHeaderHidden
        />
      </FormAndCheckoutSectionContainer>
    </Container>
  )
}

export default CartPage
