import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { DealCard, FlashCard } from '..'
import { ICardItem } from '../../@types/cards'
import './FlashSales.css'
import { Outer } from './FlashSales.styles'

SwiperCore.use([Pagination, Navigation, Scrollbar])

const RIGHT_LINK_TEXT = 'ดูทั้งหมด'
const FLASH_SALE_ITEMS_LINK = '/t/categories?flash_sale=true'

interface FlashSalesProp {
  flashSaleItems: Array<ICardItem>
}

const FlashSales = ({ flashSaleItems }: FlashSalesProp) => {
  return (
    <Outer>
      <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <img
              src="/images/flash.svg"
              alt="flash sale icon"
              className="w-4 lg:w-6"
            />
            <h2 className="text-xl font-bold text-primaryDark lg:text-2xl">
              Flash Sale
            </h2>
          </div>

          <a
            href={FLASH_SALE_ITEMS_LINK}
            className="flex items-center text-sm lg:text-base text-primaryDark"
          >
            <span>{RIGHT_LINK_TEXT}</span>
            <ChevronRightIcon className="w-4 lg:w-6" />
          </a>
        </div>

        <div className="mt-4 lg:hidden">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            navigation
          >
            {flashSaleItems.map((item) => (
              <SwiperSlide className="py-2" key={item.id}>
                <DealCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="hidden mt-6 lg:block">
          <Swiper
            slidesPerView={4}
            spaceBetween={16}
            scrollbar={{ draggable: true }}
            navigation
          >
            {flashSaleItems.map((item) => (
              <SwiperSlide className="py-2" key={item.id}>
                <FlashCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Outer>
  )
}

export default FlashSales
