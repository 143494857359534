import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const WhiteContainer = tw.div`
  w-full
  bg-white
`

export const Container = tw(DefaultContainer)`
  flex
  flex-row
  py-4
  justify-between
  space-x-2
  lg:space-x-0
  lg:justify-around
  lg:items-center
`

export const LinkContainer = tw.div`
  flex
  flex-col
  flex-1
  cursor-pointer
  border-b-0
  space-y-2
  lg:flex-row
  lg:space-x-2
  lg:space-y-0
  lg:items-center
  lg:justify-center
`

export const IconContainer = tw.div`
  flex 
  justify-center
  items-center
`

export const Icon = tw.img`
  lg:w-8
  w-12
  lg:h-8
  h-12
  object-contain
`

export const Text = tw.p`
  text-xs
  text-primary
  text-center
`
