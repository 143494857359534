import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  darkSwiperPagination
  space-y-4
  lg:space-y-10
  py-10
`

export const Item = tw.div`
  flex
  flex-col
  justify-center
  items-center
  text-center
  h-auto
  w-180
  lg:h-160
`

export const ImageContainer = tw.div`
  cursor-pointer
  mb-10
  mt-2
  ml-2
`

export const Image = tw.img`
  w-180
  h-160
  object-cover
  rounded-md
  border
  border-signupGray
`
