export const getCSRFToken = () => {
  const csrfToken =
    document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content') ?? ''
  return csrfToken
}

export function openNewTab(url: string): void {
  window.open(url, '_blank')
}
