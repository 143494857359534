import React from 'react'

import { Dropdown, BreadCrumbs } from '..'
import { InnerContainer, H1Primary, PSecondaryDark } from '../../GlobalStyles'
import { Container, FaqContainer } from './Faq.styles'
import { IContent } from '../../@types/staticContent'

interface FaqPageProps {
  faqs: Array<IContent>
}

const FaqPage = ({ faqs }: FaqPageProps) => {
  return (
    <Container>
      <BreadCrumbs breadCrumb="Frequently Asked Questions" />
      <H1Primary>Frequently Asked Question</H1Primary>
      <InnerContainer>
        {faqs.map(({ title, description }) => (
          <FaqContainer key={title}>
            <Dropdown defaultOpen={false} headerText={title} darkHeader>
              <div className="py-4">
                <PSecondaryDark>{description}</PSecondaryDark>
              </div>
            </Dropdown>
          </FaqContainer>
        ))}
      </InnerContainer>
    </Container>
  )
}

export default FaqPage
