import React from 'react'

import { Container, TextContainer, Flex } from './DeliveryCard.styles'

import { H3PrimaryDark, H3SecondaryDark, PSecondaryDark } from '../../GlobalStyles'

interface DeliveryCardProps {
  isSelected: boolean
}

const DeliveryCard = ({ isSelected }: DeliveryCardProps) => {
  return (
    <Container $isSelected={isSelected}>
      <TextContainer>
        <H3PrimaryDark />
        <Flex>
          <PSecondaryDark />
          <PSecondaryDark />
        </Flex>
      </TextContainer>
      <H3SecondaryDark />
    </Container>
  )
}

export default DeliveryCard
