import tw from 'tailwind-styled-components'

export const Button = tw.button`
  flex
  items-center
  justify-center
  px-2
  py-1
  border
  rounded-md
  border-primaryDark
  text-primaryDark
  hover:border-primary
  hover:text-primary
`
