import tw from 'tailwind-styled-components'

export const Container = tw.div`
  flex
  w-full
  border
  border-gray-200
  rounded-md
`

export const Button = tw.button`
  w-1/3
  py-2
  text-primaryDark
  bg-secondaryGray
  rounded-r-md
`

export const InputElement = tw.input`
  w-2/3
  placeholder-gray-300
  border
  px-4
  py-2
  rounded-l-md
  focus:outline-none
`
