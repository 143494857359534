import tw from 'tailwind-styled-components'

const H3Black = tw.h3`
  text-black
  lg:text-xl
  text-lg
  font-bold
`

export default H3Black
