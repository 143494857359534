import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-8
`

export const BreadCrumbContainer = tw.div`
  w-full
  hidden
  lg:flex
  space-x-6
  items-center
  text-primaryDark
  text-sm
`
