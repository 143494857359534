import tw from 'tailwind-styled-components'

export const Container = tw.div<{ $isSelected: boolean }>`
  flex
  p-4
  border
  ${(props) => (props.$isSelected ? 'border-primary' : 'border-gray-300')}
  rounded-md
  space-y-3
`

export const TextContainer = tw.div`
`

export const Flex = tw.div`
  flex
  flex-col
  space-y-2
`
