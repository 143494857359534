import { IInputValues } from './userAccountForm'

export const USER_ADDRESS_FORM: Array<IInputValues> = [
  {
    id: 1,
    label: 'ชื่อ',
    name: 'firstName',
    type: 'text',
    isRequired: true,
  },
  {
    id: 2,
    label: 'นามสกุล',
    name: 'lastName',
    type: 'text',
    isRequired: true,
  },
  {
    id: 3,
    label: 'บ้านเลขที่',
    name: 'houseNumber',
    type: 'number',
    isRequired: true,
  },
  {
    id: 4,
    label: 'สถานที่/อาคาร',
    name: 'building',
    type: 'text',
    isRequired: false,
  },
  {
    id: 5,
    label: 'ซอย',
    name: 'lane',
    type: 'text',
    isRequired: false,
  },
  {
    id: 6,
    label: 'ถนน',
    name: 'road',
    type: 'text',
    isRequired: false,
  },
  {
    id: 7,
    label: 'จังหวัด',
    name: 'province',
    type: 'text',
    isRequired: true,
  },
  {
    id: 8,
    label: 'อำเภอ',
    name: 'amphor',
    type: 'text',
    isRequired: true,
  },
  {
    id: 9,
    label: 'ตำบล',
    name: 'tambon',
    type: 'text',
    isRequired: true,
  },
  {
    id: 10,
    label: 'รหัสไปรษณีย์',
    name: 'zipCode',
    type: 'number',
    isRequired: true,
  },
  {
    id: 11,
    label: 'เบอร์ติดต่อสำรอง',
    name: 'backupNumber',
    type: 'number',
    isRequired: false,
  },
]
