import React from 'react'

import { Container, IconContainer, Icon, Text } from './LinkWithIcon.styles'

interface ILinkWithIconProps {
  innerText: string
  iconSrc: string
  textColor?: string
  url: string
}

const LinkWithIcon: React.FC<ILinkWithIconProps> = ({
  innerText,
  iconSrc,
  textColor = 'white',
  url,
}) => {
  return (
    <a href={url} className="no-underline">
      <Container>
        <IconContainer>
          <Icon src={iconSrc} />
        </IconContainer>
        <Text $textColor={textColor}>{innerText}</Text>
      </Container>
    </a>
  )
}

export default LinkWithIcon
