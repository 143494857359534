import tw from 'tailwind-styled-components'

const StaticPageContainer = tw.div`
  lg:px-20
  px-4
  py-8
  space-y-6
`

export default StaticPageContainer
