import * as React from 'react'
import { INavLink, NavLanguage } from '../../@types/nav'

interface MenuItemProps {
  menu: INavLink
  language: NavLanguage
}

export const MenubarItem = ({ menu, language = 'name_th' }: MenuItemProps) => {
  return (
    <div className="flex items-center space-x-2 text-xs cursor-pointer">
      <img
        src={menu.icon_url}
        className="w-4 h-4 brightness-0 invert"
        alt={menu.name_en}
        style={{
          filter: 'brightness(0) invert(1)',
        }}
      />
      <div>{menu?.[language]}</div>
    </div>
  )
}
