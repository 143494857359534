import * as React from 'react'
import { ICartItem } from '../../@types/cartItem'
import { formatPrice } from '../FlashCard'

interface CartItemCardProps {
  item: ICartItem
}

const CartItemCard = ({ item }: CartItemCardProps) => {
  return (
    <div className="grid grid-cols-8 gap-2 p-2 py-4 mb-4 bg-secondaryGray">
      <div className="col-span-2">
        <img src={item.thumbnail_url} alt={item.variant_name} />
      </div>

      <div className="col-span-6 space-y-1">
        <p className="text-primary line-item-description line-clamp-2">
          {item.variant_name}
        </p>
        <div className="text-sm line-item-description line-clamp-2 text-secondaryGrayDark">
          {' '}
          {item && item.short_description
            ? item.short_description.replace(
                /<[^>]+>|&nbsp;|&zwnj;|&raquo;|&laquo;/g,
                ''
              )
            : ''}
        </div>
      </div>

      <div
        className="col-span-6 col-start-3 mb-4 lg:text-center lg:col-span-3"
        data-hook="cart_item_price"
      >
        <p className="text-lg font-semibold text-primaryDark">
          ฿ {formatPrice(item.price)}
        </p>
        {item.discount_price && (
          <div className="space-x-2">
            <span className="text-sm font-light text-gray-300 line-through">
              ฿ {formatPrice(item.price + Number(item.discount_price))}
            </span>
            <span className="text-sm font-light text-primaryDark">
              ประหยัด ฿ {formatPrice(item.price - Number(item.discount_price))}
            </span>
          </div>
        )}
      </div>

      <div className="col-span-2 text-primaryDark">จำนวน</div>

      <div className="col-span-3 text-lg font-semibold text-primaryDark">
        {item.quantity}
      </div>

      <div className="col-span-3 text-lg font-semibold text-primaryDark">
        ฿ {formatPrice(Number(item.price) * item.quantity)}
      </div>
    </div>
  )
}

export default CartItemCard
