import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  flex
  flex-col
  lg:flex-row
  lg:space-x-4
  lg:space-y-0
  py-4
  lg:py-10
`
