import React from 'react'

import {
  Container,
  WhiteContainer,
  LinkContainer,
  IconContainer,
  Icon,
  Text,
} from './LowerNav.styles'

import { INavLinkWithIcon } from '../../@types/nav'

function getMobileIconUrl(url: string): string {
  const [path, extension] = url.split('.')

  return `${path}Fill.${extension}`
}

interface LowerNavProps {
  isMobileWidth?: boolean
  currentLanguage: 'name_th' | 'name_en'
  lowerNavLinks: Array<INavLinkWithIcon>
}

const LowerNav = ({
  isMobileWidth = false,
  lowerNavLinks,
  currentLanguage,
}: LowerNavProps) => {
  return (
    <WhiteContainer>
      <Container>
        {lowerNavLinks.map((item) => (
          <a key={item.url} href={item.url} className="flex-1 no-underline">
            <LinkContainer>
              <IconContainer>
                <Icon
                  loading="lazy"
                  src={
                    isMobileWidth
                      ? `/${getMobileIconUrl(item.icon_url)}`
                      : `/${item.icon_url}`
                  }
                />
              </IconContainer>
              <Text>{item[currentLanguage]}</Text>
            </LinkContainer>
          </a>
        ))}
      </Container>
    </WhiteContainer>
  )
}

export default LowerNav
