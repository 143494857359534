import React from 'react'

import { Label, Input, Span } from './Checkbox.styles'

interface CheckboxProps {
  text: string
  isChecked?: boolean
  onChange?: () => void
}

const Checkbox = ({ text, isChecked, onChange }: CheckboxProps) => {
  return (
    <Label>
      <Input type="checkbox" checked={isChecked} onChange={onChange} />
      <Span>{text}</Span>
    </Label>
  )
}

Checkbox.defaultProps = {
  isChecked: false,
}

export default Checkbox
