import tw from 'tailwind-styled-components'

const SecondaryTextStrikeThrough = tw.p`
  text-gray-300
  text-xs
  line-through
  font-light
`

export default SecondaryTextStrikeThrough
