import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-8
  py-12
  lg:py-0
  mb-8
`

export const BlogCategories = tw.div`
  bg-lightBlue
  px-8
  py-6
  flex
  flex-col
  space-y-4
  rounded-md
`

export const BlogCategoryHeading = tw.p`
  text-seccondaryGrayDark
  text-sm
`

export const BlogCategoryContainer = tw.div`
  flex
  space-x-4
`

export const BlogCategory = tw.div<{ $isActive: boolean }>`
  py-3
  px-6
  rounded-full
  ${(props) => (props.$isActive ? 'text-white' : 'text-primaryDark')}
  ${(props) => (props.$isActive ? 'bg-primaryDark' : 'bg-secondaryGray')}
  text-sm
`

export const BlogsContainer = tw.div`
  grid
  gap-6
  lg:grid-cols-3
`
