import {
  HeartIcon,
  MenuIcon,
  SearchIcon,
  ShoppingCartIcon,
  XIcon,
} from '@heroicons/react/outline'
import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import {
  LoginModal,
  LowerNav,
  NavLink,
  ReusableMenu,
  Search,
  SecondaryNav,
} from '..'
import { IGridMenu, INavLink } from '../../@types/nav'
import { menus, subMenus } from '../../constants/menus'
import { MOBILE_WIDTH } from '../../constants/windowSize'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
  Container,
  Image,
  LogoContainer,
  MenuCloseButton,
  MenuIconContainer,
  MobileMenuContainer,
  MobileMenuWrapper,
  MobileNavLinkContainer,
  MobileNavLinkWithBorder,
  Navbar,
  NavLinkContainer,
  Notification,
  Overlay,
  PrimaryNavShadow,
  SearchContainer,
} from './PrimaryNavbar.styles'

const TH_LANGUAGE_ICON = '/images/language_icon_th.png'
const EN_LANGUAGE_ICON = '/images/language_icon_en.png'
const ENGLISH = 'English'
const THAI = 'ไทย'

interface PrimaryNavbarProps {
  primaryNavLinks: Array<INavLink>
  gridLinks: Array<IGridMenu>
  itemsInCart: number
}

const PrimaryNavbar = ({
  primaryNavLinks,
  gridLinks,
  itemsInCart,
}: PrimaryNavbarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  /*
   * language only changes in the nav, use the state below to switch between
   * thai and english
   *
   * 'name_th | name_en' -> is the name variable for nav links
   */
  const [navLanguage, setNavLanguage] = useState<'name_th' | 'name_en'>(
    'name_th'
  )
  /*
   * check if window size is smaller than 640 on mount the state will not change
   * as the window won't be resized
   */
  const [isSearchVisible, setSearchVisible] = useState(false)

  // returns { width, height }
  const windowSize = useWindowSize()
  // to keep ts happy although, useWindowSize will always return a value
  const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH

  const toggleBodyScroll = () => {
    const body = document.getElementsByTagName('BODY')[0]
    if (!isMenuOpen) {
      body.className = 'overflow-y-hidden'
    } else {
      body.className = ''
    }
  }

  const toggleMenu = () => {
    toggleBodyScroll()
    setIsMenuOpen((prevState) => !prevState)
  }

  const LanguageButton = (
    <div className="block w-10">
      <Image
        alt="language icon"
        src={navLanguage === 'name_th' ? TH_LANGUAGE_ICON : EN_LANGUAGE_ICON}
      />
    </div>
  )
  const LanguageMenuItems = [
    <button
      key="button-1"
      type="button"
      className="block px-4 py-2 text-sm bg-white text-primaryDark hover:bg-primary hover:text-white"
      onClick={() => setNavLanguage('name_th')}
    >
      {THAI}
    </button>,
    <button
      key="button-2"
      type="button"
      className="block px-4 py-2 text-sm bg-white text-primaryDark hover:bg-primary hover:text-white"
      onClick={() => setNavLanguage('name_en')}
    >
      {ENGLISH}
    </button>,
  ]

  return (
    <>
      <Navbar $isFixed={isMenuOpen} className="h-80p lg:h-auto">
        <PrimaryNavShadow>
          <Container>
            <div className="flex items-center space-x-3 lg:space-x-0">
              <MenuIcon
                className="w-8 h-6 text-white lg:hidden"
                onClick={toggleMenu}
              />
              <LogoContainer>
                <a className="no-underline" href="/">
                  <Image alt="company logo" src="/images/navbar_logo.png" />
                </a>
              </LogoContainer>
            </div>
            <NavLinkContainer>
              {primaryNavLinks.map((link, index) => (
                <NavLink
                  key={link.name_en}
                  url={index === 0 ? '/blogs' : '/p/promotions'}
                  innerText={link[navLanguage]}
                />
              ))}
            </NavLinkContainer>

            {!isMobileWidth && <Search />}

            <div className="flex items-center justify-between space-x-4 lg:space-x-6">
              <button
                type="button"
                onClick={() =>
                  setSearchVisible((prevState) => !isMenuOpen && !prevState)
                }
                className="w-6 lg:hidden"
              >
                <SearchIcon className="w-6 h-6 text-white" />
              </button>
              <a
                href="/favorites"
                className="hidden w-10 no-underline lg:block"
              >
                <HeartIcon className="w-10 h-10 text-white" />
              </a>
              <a href="/cart" className="relative w-6 no-underline lg:w-10">
                <ShoppingCartIcon className="w-6 h-6 text-white lg:w-10 lg:h-10" />
                <Notification>{itemsInCart ?? 0}</Notification>
              </a>

              <div className="hidden lg:block">
                <ReusableMenu
                  menuButton={LanguageButton}
                  menuItems={LanguageMenuItems}
                />
              </div>

              <LoginModal />
            </div>
          </Container>
        </PrimaryNavShadow>
        <SecondaryNav
          isMobileWidth={isMobileWidth}
          isVisible={!isMobileWidth}
          gridMenu={gridLinks}
          secondaryNavLinks={menus}
          currentLanguage={navLanguage}
        />
        {!isMobileWidth && (
          <LowerNav lowerNavLinks={subMenus} currentLanguage={navLanguage} />
        )}
      </Navbar>
      <SearchContainer $isVisible={isSearchVisible}>
        <Search />
      </SearchContainer>
      <MobileMenuWrapper $isOpen={isMenuOpen}>
        <MenuCloseButton
          $isSearchVisible={isSearchVisible}
          onClick={toggleMenu}
          $isVisible={isMenuOpen}
        >
          <XIcon className="w-10 h-10 text-white" />
        </MenuCloseButton>
        <Overlay $isSearchVisible={isSearchVisible} onClick={toggleMenu}>
          <MobileMenuContainer
            onClick={(event) => event.stopPropagation()}
            $isSearchVisible={isSearchVisible}
          >
            <div>
              <MobileNavLinkContainer>
                {primaryNavLinks.map((link, index) => (
                  <MobileNavLinkWithBorder key={link.name_en}>
                    <NavLink
                      url={index === 0 ? '/blogs' : '/p/promotions'}
                      innerText={link[navLanguage]}
                      className="font-normal"
                    />
                  </MobileNavLinkWithBorder>
                ))}
              </MobileNavLinkContainer>

              <SecondaryNav
                currentLanguage={navLanguage}
                isVisible={isMobileWidth}
                secondaryNavLinks={menus}
                gridMenu={gridLinks}
                isMobileWidth={isMobileWidth}
              />
            </div>
            <div className="min-h-32">
              <ReusableMenu
                menuButton={LanguageButton}
                menuItems={LanguageMenuItems}
              />
            </div>
          </MobileMenuContainer>
        </Overlay>
      </MobileMenuWrapper>

      <ToastContainer />
    </>
  )
}

export default PrimaryNavbar
