import { MapOutline } from '@graywolfai/react-heroicons'
import * as React from 'react'
import { H3Primary, HrGray, PSecondaryDark } from '../../GlobalStyles'
import {
  Image,
  ImageContainer,
  Link,
  ShopContainer,
  ShopDetailContainer,
} from './StoresPage.styles'

interface IStores {
  id: number
  address: string
  zipcode: string
  company_name: string
  latitude: string
  longitude: string
  thumbnail: string
}

interface StoreItemProps {
  store: IStores
}

const StoreItem = ({ store }: StoreItemProps) => {
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`

  return (
    <ShopContainer>
      <ImageContainer>
        <Image src={store.thumbnail} />
      </ImageContainer>
      <ShopDetailContainer>
        <H3Primary>{store.company_name}</H3Primary>
        <HrGray />
        <PSecondaryDark>
          บริษัท บิทแมกซ์ อินเตอร์คอร์ป จำกัด :{' '}
          {`${store.address}, ${store.zipcode}`}
        </PSecondaryDark>
        <Link href={mapUrl} target="_blank">
          <MapOutline className="w-6 h-6" />
          View map
        </Link>
      </ShopDetailContainer>
    </ShopContainer>
  )
}

export default StoreItem
