import * as React from 'react'
import { ProductCountToggler } from '../components'

interface SetProductCountTogglerProps {
  isInputWide?: boolean
  isBorderLight?: boolean
}

interface UseProductCountTogglerProps {
  itemCount?: number
  onIncrease?: () => void
  onDecrease?: () => void
  onChange?: (newCount: number) => void
}

const INITIAL_PRODUCT_COUNT = 1
const MIN_CART_ITEM = 1
const MAX_CART_ITEM = 99

const useProductCountToggler = ({
  itemCount,
  onIncrease = () => {},
  onDecrease = () => {},
  onChange = () => {},
}: UseProductCountTogglerProps) => {
  const [itemCountDefault, setItemCountDefault] = React.useState(
    itemCount || INITIAL_PRODUCT_COUNT
  )
  /*
   * the minimun value of count is 1
   */
  const decreaseCount = () => {
    if (itemCountDefault === MIN_CART_ITEM) {
      return
    }
    setItemCountDefault(itemCountDefault - 1)
    onDecrease()
  }

  const increaseCount = () => {
    if (itemCountDefault === MAX_CART_ITEM) {
      return
    }
    setItemCountDefault(itemCountDefault + 1)
    onIncrease()
  }

  const setMinMaxCount = (newCount: number) => {
    if (newCount < MIN_CART_ITEM) {
      return MIN_CART_ITEM
    }
    if (newCount > MAX_CART_ITEM) {
      return MAX_CART_ITEM
    }
    return newCount
  }

  const handleCountChange = (newCount: number) => {
    setItemCountDefault(setMinMaxCount(newCount))
    onChange(newCount)
  }

  const setProductCountToggler = ({
    isInputWide = false,
    isBorderLight = false,
  }: SetProductCountTogglerProps) => {
    return (
      <ProductCountToggler
        itemCount={itemCountDefault}
        handleCountChange={handleCountChange}
        decreaseCount={decreaseCount}
        increaseCount={increaseCount}
        isInputWide={isInputWide}
        isBorderLight={isBorderLight}
      />
    )
  }

  return {
    itemCount: itemCountDefault || 1,
    decreaseCount,
    increaseCount,
    handleCountChange,
    ProductCountToggler: setProductCountToggler,
  }
}

export default useProductCountToggler
