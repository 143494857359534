export const CHECKOUT_STAGES = [
  {
    id: 0,
    stage: 'address',
    name: 'ที่อยู่',
  },
  {
    id: 1,
    stage: 'delivery',
    name: 'การจัดส่ง',
  },
  {
    id: 2,
    stage: 'payment',
    name: 'ช่องทางชำระเงิน',
  },
  {
    id: 3,
    stage: 'confirm',
    name: 'ยืนยันคำสั่งซื้อ',
  },
  {
    id: 4,
    stage: 'complete',
    name: 'คำสั่งซื้อเสร็จสมบูรณ์',
  },
]
