import * as React from 'react'
import debounce from 'lodash/debounce'

import { PlusOutline, MinusOutline } from '@graywolfai/react-heroicons'

import {
  AmountButtonsContainer,
  AmountButton,
  AmountInput,
} from '../FlashCard/FlashCard.styles'

interface ProductCountTogglerProps {
  itemCount: number
  decreaseCount: () => void
  increaseCount: () => void
  handleCountChange: (newCount: number) => void
  isBorderLight?: boolean
  isInputWide?: boolean
}

const ProductCountToggler = ({
  itemCount,
  decreaseCount,
  increaseCount,
  handleCountChange,
  isInputWide,
  isBorderLight,
}: ProductCountTogglerProps) => {
  const [inputCount, setInputCount] = React.useState<number>(itemCount || 1)
  const delayedInput = React.useCallback(
    debounce((newCount) => {
      handleCountChange(newCount)
    }, 500),
    []
  )
  const handleAmountInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCount = Number(event.currentTarget.value.trim())
    setInputCount(newCount)
    delayedInput(newCount)
  }

  return (
    <AmountButtonsContainer
      onClick={(event) => event.preventDefault()}
      $isBorderLight={!!isBorderLight}
    >
      <AmountButton
        $isBorderLight={!!isBorderLight}
        onClick={decreaseCount}
        className="rounded-l-sm"
      >
        <MinusOutline className="w-6 h-6" />
      </AmountButton>
      <AmountInput
        $isInputWide={!!isInputWide}
        $isBorderLight={!!isBorderLight}
        type="number"
        onChange={handleAmountInputChange}
        value={inputCount}
      />
      <AmountButton
        $isBorderLight={!!isBorderLight}
        onClick={increaseCount}
        className="rounded-r-sm"
      >
        <PlusOutline className="w-6 h-6" />
      </AmountButton>
    </AmountButtonsContainer>
  )
}

export default ProductCountToggler
