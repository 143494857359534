import { IGridMenuType } from '../@types/nav'

export const FOOTER_MENU_ITEMS: Array<IGridMenuType> = [
  {
    id: 'SubHeading1-00',
    menuHeading: 'LCS ออนไลน์',
    linkTo: '/',
    menuSubItems: [
      {
        id: 'SubItem1-00',
        header: 'หน้าแรก',
        linkTo: '/',
      },
      {
        id: 'SubItem2-01',
        header: 'เกี่ยวกับเรา',
        linkTo: '/p/about-us',
      },
      {
        id: 'SubItem3-02',
        header: 'ร้านที่ใกล้ที่สุด',
        linkTo: '/stores',
      },
      {
        id: 'SubItem3-03',
        header: 'ข่าวสารเเละบทความ',
        linkTo: '/blogs',
      },
      {
        id: 'SubItem3-04',
        header: 'ติดต่อเรา',
        linkTo: '/contact-us',
      },
    ],
  },
  {
    id: 'SubHeading2-01',
    menuHeading: 'ศูนย์ช่วยเหลือ',
    linkTo: '/',
    menuSubItems: [
      {
        id: 'SubItem1-00',
        header: 'ติดตามคำสั่งซื้อของฉัน',
        linkTo: '/my-orders',
      },
      {
        id: 'SubItem2-01',
        header: 'วิธีการสั่งซื้อสินค้า',
        linkTo: '/p/how-to-purchase',
      },
      {
        id: 'SubItem3-02',
        header: 'วิธีการชำระเงิน',
        linkTo: '/p/how-to-pay',
      },
      {
        id: 'SubItem4-03',
        header: 'การคืนสินค้าและการคืนเงิน',
        linkTo: '/p/how-to-refund',
      },
      {
        id: 'SubItem5-04',
        header: 'การรับประกันสินค้า',
        linkTo: '/p/warranty',
      },
      {
        id: 'SubItem5-05',
        header: 'บริการลูกค้าธุรกิจ',
        linkTo: '/p/b2b-customer-service',
      },
    ],
  },
  {
    id: 'SubHeading3-02',
    menuHeading: 'สินค้าขายดี',
    linkTo: '/',
    menuSubItems: [
      {
        id: 'SubItem1-00',
        header: 'กะทะล้อ',
        linkTo: '/t/categories/wheel',
      },
      {
        id: 'SubItem2-01',
        header: 'ถังน้ำมัน',
        linkTo: '/t/categories/oil-tanks',
      },
      {
        id: 'SubItem3-02',
        header: 'ดัมพ์และอุปกรณ์',
        linkTo: '/t/categories/hoist-and-accessories',
      },
      {
        id: 'SubItem4-03',
        header: 'ข้อต่อและสายลม',
        linkTo: '/t/categories/air-fittings-and-air-tubes',
      },
      {
        id: 'SubItem4-04',
        header: 'อุปกรณ์ไฟฟ้าและไฟสัญญา',
        linkTo: '/t/categories/electrical-components-and-signal-lightings',
      },
      {
        id: 'SubItem4-05',
        header: 'ระบบเบรคและหม้อลม',
        linkTo: '/t/categories/brake-system-and-brake-chambers',
      },
    ],
  },
]
