import { upperFirst } from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { SubCategoriesGrid } from '..'
import { ISubCategories } from '../../@types/subcategories'
import { H1Primary } from '../../GlobalStyles'
import {
  CategoriesContainer,
  CategoryTitleContainer,
  Container,
  HeroImage,
  HeroImageContainer,
} from './CategoryHeroSection.styles'

const PROMOTION_KEYS = [
  'hot_items',
  'flash_sale',
  'hot_deal',
  'new_items',
  'lcs',
  'products',
  'keywords',
]

interface IMainCategory {
  icon: string
  id: number
  name: string
  permalink: string
}

interface CategoryHeroSectionProps {
  heroImage: string
  subCategories: Array<ISubCategories>
  mainCategory: IMainCategory
  description?: string
}

const CategoryHeroSection = ({
  heroImage,
  subCategories,
  mainCategory,
  description = '',
}: CategoryHeroSectionProps) => {
  const isSubCategory = React.useMemo(() => {
    const splitLink = mainCategory.permalink.split('/')
    return splitLink.length >= 3 || splitLink.includes('brand')
  }, [mainCategory.id])

  const shouldReturnEarly = React.useMemo(() => {
    const parsed = queryString.parse(window.location.search)
    const parsedKeys = Object.keys(parsed)
    const checkKeysIntersect = PROMOTION_KEYS.filter((key) =>
      parsedKeys.includes(key)
    )
    return checkKeysIntersect.length > 0
  }, [])

  if (shouldReturnEarly || !heroImage) {
    return null
  }

  if (window.location.search) {
    return null
  }

  return (
    <>
      <Container>
        <CategoryTitleContainer>
          <H1Primary>{upperFirst(mainCategory.name)}</H1Primary>
        </CategoryTitleContainer>
        {!isSubCategory && (
          <>
            {heroImage && (
              <HeroImageContainer>
                <HeroImage src={heroImage} />
              </HeroImageContainer>
            )}

            {subCategories.length > 0 && (
              <CategoriesContainer>
                <SubCategoriesGrid subCategories={subCategories.slice(0, 12)} />
              </CategoriesContainer>
            )}
          </>
        )}
      </Container>
      {description && (
        <Container>
          <p>{description}</p>
        </Container>
      )}
    </>
  )
}

export default CategoryHeroSection
