import React from 'react'

import { Container } from './BlogAndLcsChoiceSection.styles'
import { BlogSection, LcsChoiceSection } from '..'
import { ICardItem } from '../../@types/cards'
import { IBlog } from '../../@types/blogs'

interface BlogAndLcsChoiceSectionProps {
  blogs: Array<IBlog>
  lcsChoiceCards: Array<ICardItem>
}

/*
 * @param -> blog to be sorted by date
 * @param -> same as above
 *
 * @returns -> a number, which is used to sort the items
 *
 * Sorts the blogs based on the created date
 */
function sortDates(date1: IBlog, date2: IBlog): number {
  return Date.parse(date2.created_at) - Date.parse(date1.created_at)
}

const BlogAndLcsChoiceSection = ({
  blogs,
  lcsChoiceCards,
}: BlogAndLcsChoiceSectionProps) => {
  const sortedBlogs = blogs.sort(sortDates)

  return (
    <div className="container px-2 mx-auto max-w-7xl md:px-4">
      <div className="grid grid-cols-1 gap-4 mb-6 lg:grid-cols-2">
        <div className="col-span-1">
          <BlogSection blogs={sortedBlogs} />
        </div>
        <div className="col-span-1">
          <LcsChoiceSection lcsCards={lcsChoiceCards} />
        </div>
      </div>
    </div>
  )
}

export default BlogAndLcsChoiceSection
