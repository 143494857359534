import tw from 'tailwind-styled-components'

export const Container = tw.div<{ $isVisible: boolean }>`
  ${(props) => (props.$isVisible ? 'flex' : 'hidden')}
  w-full
  lg:w-containerWidth
  mx-auto
  flex-col
  lg:flex-row
  lg:justify-around
  lg:items-center
  lg:h-12
  lg:px-4
  lg:py-6
`
