import React from 'react'

import { ICartItem } from '../../@types/cartItem'
import { formatPrice } from '../FlashCard'

import {
  Tr,
  Td,
  Image,
  Flex,
  FlexCol,
  LineClamp,
  PriceFlex,
} from '../CartItemsTable/CartItemsTable.styles'
import {
  H3PrimaryDark,
  H4PrimaryDark,
  PrimaryTextRegular,
  SecondaryTextStrikeThrough,
  PSecondaryDark,
} from '../../GlobalStyles'

interface CartTableRowProps {
  cartItem: ICartItem
}

const CartTableRow = ({ cartItem }: CartTableRowProps) => {
  return (
    <>
      <div className="col-span-6 px-2">
        <Flex>
          <Image src={cartItem.thumbnail_url} />
          <FlexCol>
            <LineClamp>
              <H4PrimaryDark>{cartItem.variant_name}</H4PrimaryDark>
            </LineClamp>
            <LineClamp>
              <PSecondaryDark>
                {cartItem && cartItem.short_description
                  ? cartItem.short_description.replace(
                      /<[^>]+>|&nbsp;|&zwnj;|&raquo;|&laquo;/g,
                      ''
                    )
                  : ''}
              </PSecondaryDark>
            </LineClamp>
          </FlexCol>
        </Flex>
      </div>

      <div className="col-span-2">
        <PriceFlex>
          <H3PrimaryDark>฿ {formatPrice(cartItem.price)}</H3PrimaryDark>

          {cartItem.discount_price && (
            <Flex>
              <SecondaryTextStrikeThrough>
                ฿{' '}
                {formatPrice(cartItem.price + Number(cartItem.discount_price))}
              </SecondaryTextStrikeThrough>
              <PrimaryTextRegular>
                ประหยัด ฿{' '}
                {formatPrice(cartItem.price - Number(cartItem.discount_price))}
              </PrimaryTextRegular>
            </Flex>
          )}
        </PriceFlex>
      </div>

      <div className="col-span-2 text-center">
        <H3PrimaryDark>{cartItem.quantity}</H3PrimaryDark>
      </div>

      <div className="col-span-2 text-center">
        <H3PrimaryDark>
          ฿ {formatPrice(cartItem.price * cartItem.quantity)}
        </H3PrimaryDark>
      </div>
    </>
  )
}

export default CartTableRow
