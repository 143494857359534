/* eslint-disable import/no-cycle */
/*
 * Components used in Navbar
 */
export { default as NavLink } from './NavLink'
export { default as SecondaryNav } from './SecondaryNav'
export { default as LowerNav } from './LowerNav'
export { default as LinkWithIcon } from './LinkWithIcon'
export { default as Search } from './Search'
export { default as GridMenu } from './GridMenu'

/*
 * Body components
 */
export { default as HeadCarousel } from './headCarousel'
export { default as SwiperEl } from './SwiperEl'

/*
 * Flash Sale Components
 */
export { default as FlashSales } from './flashSales'
export { default as FlashCard } from './FlashCard'
export { default as DealCard } from './DealCard'

export { default as ProductCountToggler } from './ProductCountToggler'

/*
 * New Items section
 */
export { default as NewItems } from './newItems'

/*
 * Shop by categories components
 */
export { default as Categories } from './categories'
export { default as Category } from './Category'

/*
 * Hot deals section
 */
export { default as HotDeals } from './hotDeals'

/*
 * Hot Items Components
 */
export { default as HotItems } from './hotItems'

/*
 * Banner
 */
export { default as Banner } from './banner'

/*
 * Home Bottom section,
 * contains the blog and lcs choice sections
 */
export { default as BlogSection } from './BlogSection'
export { default as Blog } from './Blog'
export { default as LcsChoiceSection } from './LcsChoiceSection'

/*
 * Footer
 */
export { default as Footer } from './footer'

/* -----------------------------------------------------------------------
 * Categories Page Components
 ----------------------------------------------------------------------- */

// breadCrumb
export { default as BreadCrumbs } from './breadCrumbs'

// hero section
export { default as CategoryHeroSection } from './categoryHeroSection'

// sub-categories grid
export { default as SubCategoriesGrid } from './SubCategoriesGrid'

/*
 * Categories Main Section
 */
export { default as CategoryMainBody } from './categoryMainBody'
// filter section
export { default as FilterColumn } from './FilterColumn'
export { default as Dropdown } from './Dropdown'
export { default as Slider } from './Slider'
export { default as Checkbox } from './Checkbox'

/* -----------------------------------------------------------------------
 * Product Detail Page Components
 ----------------------------------------------------------------------- */
export { default as ImageGallery } from './ImageGallery'
export { default as CategoryProductsSection } from './CategoryProductsSection'
export { default as ProductTags } from './ProductTags'

/* -----------------------------------------------------------------------
 * Checkout Pages Components
 ----------------------------------------------------------------------- */
/*
 * Form elements
 */
export { default as Input } from './Input'
export { default as InputButtonGroup } from './InputButtonGroup'

/*
 * Select is different from select el in a way that select is virtualized
 */
export { default as Select } from './Select'
export { default as SelectEl } from './SelectEl'

export { default as AddressForm } from './AddressForm'

/*
 * Cart Components
 */
export { default as CartItemsTable } from './CartItemsTable'
export { default as CartTableRow } from './CartTableRow'
export { default as CartItemToggler } from './cartItemToggler'

export { default as CheckboxRegular } from './CheckboxRegular'
export { default as CheckoutPriceSection } from './CheckoutPriceSection'

export { default as OutlineButton } from './OutlineButton'

/* -----------------------------------------------------------------------
 * My account page components
 ----------------------------------------------------------------------- */

export { default as EditButton } from './EditButton'

/* -----------------------------------------------------------------------
 * Registration
 ----------------------------------------------------------------------- */

export { default as LoginModal } from './LoginModal'
export { default as LoggedInSlideOver } from './LoggedInSlideOver'
export { default as ReusableMenu } from './ReusableMenu'

export { default as NoProductsMessage } from './NoProductsMessage'
