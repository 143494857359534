import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Blog } from '..'
import { IBlog } from '../../@types/blogs'
import { H2Primary } from '../../GlobalStyles'
import { Container, HeaderContainer } from './BlogSection.styles'

interface BlogSectionProps {
  blogs: IBlog[]
}

const BlogSection = ({ blogs = [] }: BlogSectionProps) => {
  return (
    <Container className="h-full">
      <HeaderContainer>
        <H2Primary>Blog</H2Primary>

        <a
          href="/blogs"
          className="flex items-center text-sm lg:text-base text-primaryDark"
        >
          <span>ดูทั้งหมด</span>
          <ChevronRightIcon className="w-4 lg:w-6" />
        </a>
      </HeaderContainer>

      <div className="grid gap-6 lg:gap-0 lg:flex lg:flex-col lg:justify-between lg:flex-1">
        {blogs && blogs.map((blog) => <Blog key={blog.id} {...blog} />)}
      </div>
    </Container>
  )
}

export default BlogSection
