import React from 'react'

import { CartTableRow } from '..'
import { Table, TableHead, Th, TableBody, Tr } from './CartItemsTable.styles'
import { ICartItem } from '../../@types/cartItem'
import CartItemCard from './CartItemCard'

const ITEM_HEADER = 'สินค้า'
const UNIT_PRICE_HEADER = 'ราคา'
const QUANTITY_HEADER = 'จำนวน'
const TOTAL_PRICE_HEADER = 'ราคารวม'

interface CartItemsTableProps {
  cartItems: Array<ICartItem>
}

const CartItemsTable = ({ cartItems }: CartItemsTableProps) => {
  return (
    <>
      <div className="lg:hidden">
        {cartItems.map((item) => (
          <CartItemCard item={item} key={item.id} />
        ))}
      </div>

      <div className="hidden grid-cols-12 py-2 bg-gray-200 lg:grid">
        <div className="col-span-6 px-2 text-center">{ITEM_HEADER}</div>
        <div className="col-span-2 text-center">{UNIT_PRICE_HEADER}</div>
        <div className="col-span-2 text-center">{QUANTITY_HEADER}</div>
        <div className="col-span-2 text-center">{TOTAL_PRICE_HEADER}</div>
      </div>

      <div className="hidden grid-cols-12 py-2 space-y-2 lg:grid">
        {cartItems.map((cartItem) => (
          <CartTableRow key={cartItem.id} cartItem={cartItem} />
        ))}
      </div>

      {/* <Table>
        <TableHead>
          <Tr>
            <Th>{ITEM_HEADER}</Th>
            <Th>{UNIT_PRICE_HEADER}</Th>
            <Th>{QUANTITY_HEADER}</Th>
            <Th>{TOTAL_PRICE_HEADER}</Th>
          </Tr>
        </TableHead>
        <TableBody>
          {cartItems.map((cartItem) => (
            <CartTableRow key={cartItem.id} cartItem={cartItem} />
          ))}
        </TableBody>
      </Table> */}
    </>
  )
}

export default CartItemsTable
