import tw from 'tailwind-styled-components'

export const Container = tw.div`
  grid
  grid-cols-3
  grid-rows-4
  md:grid-cols-6
  md:grid-rows-2
  cursor-pointer
  pb-8
`

export const Item = tw.a`
  flex
  flex-col
  justify-center
  items-center
  text-center
  w-120
  h-auto
  lg:w-180
  lg:h-180
`

export const ImageContainer = tw.div`
  w-100
  h-100
  lg:h-180
  lg:w-180
`

export const Image = tw.img`
  h-full
  w-full
  object-cover  
`
