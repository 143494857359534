import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import { Label, Input, Span } from '../Checkbox/Checkbox.styles'

interface CheckboxRegularProps {
  text: string
}

// eslint-disable-next-line react/display-name
const CheckboxRegular = React.forwardRef<
  HTMLInputElement,
  CheckboxRegularProps & ReturnType<UseFormRegister<any>>
>(({ text, onChange, name, onBlur }, ref) => {
  return (
    <div className="cursor-pointer">
      <Label>
        <Input name={name} ref={ref} onBlur={onBlur} type="checkbox" onChange={onChange} />
        <Span>{text}</Span>
      </Label>
    </div>
  )
})

export default CheckboxRegular
