import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
// swiper css elements
import 'swiper/swiper.scss'
// child components
import { DealCard } from '..'
import { ICardItem } from '../../@types/cards'
import { MOBILE_WIDTH } from '../../constants/windowSize'
// global styles
import { H2Primary } from '../../GlobalStyles'
// windowSize hook
import { useWindowSize } from '../../hooks/useWindowSize'
import { HeaderContainer } from '../BlogSection/BlogSection.styles'
import { CardsGrid, Container } from './LcsChoiceSection.styles'

const LCS_CHOICE_ITEMS = 'ดูทั้งหมด'
const LCS_CHOICE_LINK = '/t/categories?lcs=true'

interface LcsChoiceSectionProps {
  lcsCards: Array<ICardItem>
}

// install swiper addon
SwiperCore.use([Navigation, Pagination])

const LcsChoiceSection = ({ lcsCards = [] }: LcsChoiceSectionProps) => {
  // const windowSize = useWindowSize()
  // const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH

  const lcsChoice = React.useMemo(() => {
    if (!lcsCards || !lcsCards.length) {
      return []
    }

    return lcsCards.slice(0, 6)
  }, [lcsCards])

  return (
    <Container>
      <HeaderContainer>
        <H2Primary>LCS Choice</H2Primary>

        <a
          href={LCS_CHOICE_LINK}
          className="flex items-center text-sm lg:text-base text-primaryDark"
        >
          <span>{LCS_CHOICE_ITEMS}</span>
          <ChevronRightIcon className="w-4 lg:w-6" />
        </a>
      </HeaderContainer>

      <div className="flashCardsSwiper lg:hidden">
        <Swiper navigation spaceBetween={5} slidesPerView={2}>
          {lcsCards &&
            lcsCards.map((card) => (
              <SwiperSlide className="py-2" key={card.id}>
                <DealCard {...card} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>

      <div className="hidden lg:block">
        <CardsGrid>
          {lcsChoice.map((card) => (
            <DealCard key={card.id} {...card} />
          ))}
        </CardsGrid>
      </div>
    </Container>
  )
}

export default LcsChoiceSection
