import React from 'react'

import { Container, TagText } from './ProductTags.styles'

interface ProductTagsProps {
  category: string
  tags: string
}

const ProductTags = ({ tags, category }: ProductTagsProps) => {
  return (
    <Container>
      <TagText>หมวดหมู่ : {category} </TagText>
      <TagText>แท็ก : {tags}</TagText>
    </Container>
  )
}

export default ProductTags
