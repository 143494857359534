import tw from 'tailwind-styled-components'

export const Container = tw.div`
  lg:relative 
`

export const MenuButtonContainer = tw.div`
  flex
  w-full
  justify-start
  lg:justify-between
  items-center
  cursor-pointer
  border-b-2
  border-gray-100
  lg:border-b-0
  py-0
  lg:py-3
`

export const GridContainer = tw.div`
  cursor-default
  z-10
  fixed
  top-0
  bottom-0
  lg:absolute
  lg:top-12
  lg:bottom-80
  lg:h-600
  left-0
  w-10/12
  lg:w-1000
  px-2
  pt-2
  pb-4
  bg-white
  rounded-md
  shadow-md
  transition
  ease-in-out
  duration-300
  overflow-y-scroll
`

export const Grid = tw.div`
  flex
  flex-wrap
  lg:grid
  grid-cols-4
  gap-4
`

export const List = tw.div`
  flex
  flex-col
`

export const ListItem = tw.a`
  pb-2
  pl-2
  lg:py-2
  lg:px-2
  no-underline
  cursor-pointer
  text-black
`

export const ListHeading = tw.a`
  py-2
  px-3
  pl-2
  lg:px-3
  no-underline
  cursor-pointer
  text-primary
  font-semibold
  bg-lightBlue
`

export const DownIconContainer = tw.div`
  flex
  justify-center 
  items-center
  px-2
  py-2
  ml-auto
  lg:w-auto
  lg:ml-0
  lg:px-1
  lg:py-0
`
