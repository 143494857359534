interface IShippingAndPayment {
  title: string
  subTexts: Array<{ id: number; value: string; icon: string }>
}

export const SHIPPING_DETAILS: IShippingAndPayment = {
  title: 'การจัดส่ง',
  subTexts: [
    {
      id: 1,
      value: ' ขนส่งเอกชนใช้เวลา 2-4 วัน ',
      icon: '/images/Asset1.svg',
    },
    {
      id: 2,
      value: 'สินค้าน้ำหนักสูง ค่าจัดส่งเก็บเงินปลายทาง',
      icon: '/images/Asset2.svg',
    },
    {
      id: 3,
      value: 'เจ้าหน้าที่ LCS จะโทรแจ้งเมื่อนำส่งสินค้าให้กับทางขนส่งแล้ว',
      icon: '/images/Asset3.svg',
    },

    {
      id: 4,
      value: 'จัดส่งสินค้าทุกวันยกเว้นวันอาทิตย์',
      icon: '/images/Asset4.svg',
    },
  ],
}

export const PAYMENT_DETAILS: IShippingAndPayment = {
  title: 'การชำระเงิน',
  subTexts: [
    {
      id: 1,
      value: 'โอนเข้าบัญชีธนาคารบริษัท (ได้ทุกรายการสินค้า) ',
      icon: '/images/pay-1.svg',
    },
    {
      id: 2,
      value: 'จ่ายผ่านบัตรเครดิต/เดบิต <br>(ได้ทุกรายการสินค้า)',
      icon: '/images/pay-2.svg',
    },
    {
      id: 3,
      value: 'ชำระเงินสดที่ร้าน <br>(ได้ทุกรายการสินค้า)',
      icon: '/images/pay-3.svg',
    },
    {
      id: 4,
      value: 'เก็บเงินปลายทาง <br>(เฉพาะรายการสินค้าที่เข้าร่วม)',
      icon: '/images/pay-4.svg',
    },
    {
      id: 5,
      value: 'ผ่อนชำระผ่านบัตร <br>(เฉพาะรายการสินค้าที่เข้าร่วม)',
      icon: '/images/pay-5.svg',
    },
  ],
}
