import React from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
// swiper css elements
import 'swiper/swiper.scss'
import { DealCard, FlashCard } from '..'
// import type
import { ICardItem } from '../../@types/cards'
import { CardsGrid } from '../../GlobalStyles'
import { PrimaryOutlineButton } from './HotItems.styles'

interface HotItemsProps {
  hotItems: Array<ICardItem>
}

// install swiper addon
SwiperCore.use([Navigation, Scrollbar, Pagination])

const HotItems = ({ hotItems }: HotItemsProps) => {
  const items = React.useMemo(() => {
    if (!hotItems || !hotItems.length) {
      return []
    }

    return hotItems.slice(0, 4)
  }, [hotItems])

  const handleSeeAll = () => {
    window.location.href = '/t/categories?hot_items=true'
  }

  return (
    <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
      <div className="rounded-lg">
        <div className="bg-white">
          <div className="flex items-center justify-center w-full py-4 space-x-2 rounded-t-lg lg:py-6 bg-primaryDark">
            <img
              src="/images/hot_item.svg"
              className="w-8 h-8"
              alt="hot item icon"
            />

            <h2 className="text-xl font-bold text-white lg:text-2xl">
              Hot Item
            </h2>
          </div>

          <div className="px-2 border-4 border-t-0 rounded-b-lg lg:hidden border-primaryDark">
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              breakpoints={{
                768: {
                  slidesPerView: 4,
                  spaceBetween: 16,
                },
              }}
              navigation
            >
              {items.map((item) => (
                <SwiperSlide className="py-2" key={item.id}>
                  <DealCard {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="hidden px-2 py-4 border-8 border-t-0 rounded-b-lg lg:block border-primaryDark">
            <CardsGrid>
              {items.map((card) => (
                <FlashCard key={card.id} {...card} />
              ))}
            </CardsGrid>
          </div>

          <div className="flex justify-center pt-2 pb-4">
            <PrimaryOutlineButton onClick={handleSeeAll}>
              ดูทั้งหมด
            </PrimaryOutlineButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HotItems
