import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  hidden
  lg:flex
  item-center
  space-x-4
  my-4
  w-full
`

export const BreadCrumb = tw.a`
  no-underline
  text-primaryDark
  text-sm
`
