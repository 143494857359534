import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  lg:mb-10
  mb-4
`

export const Link = tw.a`
  no-underline
  block
  w-full
`

export const Image = tw.img`
  h-40p
  lg:h-128
  w-full
  object-cover
`
