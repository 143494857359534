import tw from 'tailwind-styled-components'

export const Container = tw.div`
  w-full
  px-2
  lg:px-4
`

export const Menu = tw.div`
  h-12
  flex
  items-center
  justify-between
  lg:space-x-6
  relative
`

export const MobileFiltersButton = tw.div`
  lg:hidden
  flex
  items-center
  space-x-2
  cursor-pointer
`

export const IconContainer = tw.div`
`

export const Icon = tw.img`
  object-contain
`

/*
 * holds heading and icon in mobile view
 */
export const FilterHeading = tw.p`
  lg:text-secondaryGrayDark
  text-primaryDark
  font-semibold
  lg:font-normal
`

/*
 * Holds the absolute container with filter in mobile view
 */
export const FilterMobileContainer = tw.div<{ $isVisible: boolean }>`
  z-10
  bg-white
  w-full
  border
  border-primaryDark
  flex
  flex-col
  rounded-md
  ${(props) => (props.$isVisible ? 'absolute' : 'hidden')}
  top-14
  -left-2
  right-0
`

/*
 * in mobile view holds the filters on the right
 */
export const FilterContainer = tw.div<{ $isActive: boolean }>`
  ${(props) => (props.$isActive ? 'bg-lightBlue' : '')}
  flex
  justify-between
  items-center
  px-3
  py-2
  border
  border-gray-100
`

export const ActiveIcon = tw(Icon)<{ $isActive: boolean }>`
  ${(props) => (props.$isActive ? 'block' : 'hidden')}
`

/*
 * in mobile view holds filter items and the check icon
 */
export const Filters = tw.div`
  flex
  justify-between
  items-center
  h-full
  lg:w-3/4
`

export const FilterItem = tw.button<{ $isActive: boolean }>`
  block
  h-full
  no-underline
  cursor-pointer
  lg:border-b-2
  ${(props) =>
    props.$isActive ? 'lg:text-primaryDark' : 'lg:text-secondaryGrayDark'}
  ${(props) => (props.$isActive ? 'font-bold' : 'font-normal')}
  ${(props) =>
    props.$isActive ? 'lg:border-primaryDark' : 'lg:border-transparent'}
  text-center
`

export const Pagination = tw.div`
  h-full
  hidden
  lg:flex
  justify-between
  items-center
  w-1/4
`

export const PaginationHeading = tw.p`
  text-secondaryGrayDark
`

export const PaginationItem = tw.button<{ $isActive: boolean }>`
  text-xs
  px-2
  py-1
  text-gray-500
  bg-white
  border-2
  border-gray-200
  rounded-md
  ${(props) => (props.$isActive ? 'ring ring-gray-600 ring-offset-2' : '')}
`

export const ProductsGrid = tw.div`
  py-6
  lg:border-t-2
  border-gray-300
  grid
  grid-cols-2
  gap-4
  lg:grid-cols-3
`

export const PaginationLabel = tw.div`
  border-t-2
  py-4
  space-x-2
  border-transparent
  inline-flex
  items-center
  text-sm
  font-medium
  text-primaryDark
  hover:border-primaryDark
`
