import tw from 'tailwind-styled-components'

export const Container = tw.div`
  flex-grow
  flex
  flex-col
  w-full
  space-y-4
`

export const HeaderContainer = tw.div`
  flex
  justify-between
  items-center
  lg:border-b-2
  border-b
  border-primaryDark
  pb-6
`

export const LinkContainer = tw.div`
  flex
  space-x-2
  items-center
`

export const BlogsContainer = tw.div`
  flex-grow
  flex
  flex-col
  justify-between
  space-y-4
  md:space-y-auto
`
