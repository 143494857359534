export const convertToObj = (arr: any) =>
  arr.reduce(
    (prev: any, curr: any) => ({
      ...prev,
      [curr]: true,
    }),
    {}
  )

export const getRange = (key: string, value: any) => {
  return value
    ? {
        [`${key}_min`]: value[0],
        [`${key}_max`]: value[1],
      }
    : undefined
}

export const rangeConverter = (key: any, queryData: any) => {
  if (!queryData || !queryData[`${key}_max`] || !queryData[`${key}_min`]) {
    return undefined
  }

  return [+queryData[`${key}_min`], +queryData[`${key}_max`]]
}

export const getIds = (list: any) => {
  return (
    [list]
      .flat()
      .map((item) => +item)
      .filter(Boolean) || []
  )
}

export const scrollToHash = (scrollTo: string) => {
  window.location.hash = scrollTo
}
