export interface IInputValues {
  id: number
  label: string
  name: string
  type: 'number' | 'text' | 'password'
  isRequired: boolean
}

export const USER_INFO_FORM: Array<IInputValues> = [
  {
    id: 1,
    label: 'ชื่อ',
    name: 'firstName',
    type: 'text',
    isRequired: true,
  },
  {
    id: 2,
    label: 'นามสกุล',
    name: 'lastName',
    type: 'text',
    isRequired: true,
  },
  {
    id: 3,
    label: 'เบอร์โทร',
    name: 'phone',
    type: 'number',
    isRequired: true,
  },
  {
    id: 4,
    label: 'อีเมล',
    name: 'email',
    type: 'text',
    isRequired: true,
  },
  {
    id: 5,
    label: 'รหัสผ่าน',
    name: 'password',
    type: 'password',
    isRequired: true,
  },
  {
    id: 6,
    label: 'ยืนยันรหัสผ่าน',
    name: 'confirmPassword',
    type: 'password',
    isRequired: true,
  },
]
