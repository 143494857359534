import React from 'react'

import { Anchor } from './NavLink.styles'

interface PropType {
  innerText: string
  url: string
  className?: string
}

const NavLink: React.FC<PropType> = ({ innerText, url, className }) => (
  <Anchor href={url} $className={className}>
    {innerText}
  </Anchor>
)

export default NavLink
