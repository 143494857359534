import { Popover, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { NavLanguage } from '../../@types/nav'
import SearchBar from './SearchBar'

interface SearchDropdownProps {
  language: NavLanguage
}

export const SearchDropdown = ({ language }: SearchDropdownProps) => {
  return (
    <Popover className="relative">
      <Popover.Button className="flex items-center">
        <SearchIcon className="w-6 h-6 cursor-pointer lg:hidden" />
      </Popover.Button>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-50 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="fixed left-0 w-screen p-4 top-top-nav bg-gradient-to-r from-gradientDark to-gradientLight">
          <SearchBar language={language} />
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
