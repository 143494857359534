import React from 'react'
import { getTrackBackground, Range } from 'react-range'
import { SliderNumber } from './Slider.styles'

/* -------------------------------------------------------------------------------------------
 * Helper Functions
 ------------------------------------------------------------------------------------------- */

/*
 * @param: number to be formatted
 * @returns: a string with large numbers formatted with comma
 *
 * ex: num = '12000' | '12000.00' will return '12,000'
 */
function formatWithCommas(num: string): string {
  /*
   * @returns a number without the trailling .00's
   * this only works if the numbers end with .00's, in this case it's fine
   * to use 'Number' as each step will only increment a 1000, so the decimal will
   * always be '.0'
   */
  const numWithoutDecimal = Number(num)

  return numWithoutDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const GRAY = '#ccc'
const LCS_PRIMARY = '#144A90'

interface SliderProps {
  minValue: number
  maxValue: number
  type: string
  step?: number
  values: Array<number>
  onChange: (values: number[]) => void
}

const Slider = ({
  minValue,
  maxValue,
  values,
  step,
  onChange,
}: SliderProps) => {
  return (
    <Range
      step={step}
      min={minValue}
      max={maxValue}
      values={values}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            background: getTrackBackground({
              values,
              colors: [GRAY, LCS_PRIMARY, GRAY],
              min: minValue,
              max: maxValue,
            }),
          }}
          className="w-full rounded-sm h-0.5 focus-visible:outline-none"
        >
          {children}
        </div>
      )}
      renderThumb={({ index, props }) => (
        <div
          {...props}
          key={`div-${index}`}
          style={{
            ...props.style,
          }}
          className="w-4 h-4 rounded-full bg-primary focus-visible:outline-none"
        >
          <SliderNumber $index={index}>
            {formatWithCommas(values[index].toFixed(1))}
          </SliderNumber>
        </div>
      )}
    />
  )
}

Slider.defaultProps = {
  step: 1000,
}

export default Slider
