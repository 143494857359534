import React from 'react'

import { Item, ImageContainer, Image } from './CategoriesGrid.styles'

import { ICategory } from '../../@types/category'

const Category = ({ cate_link, cate_image, cate_name }: ICategory) => {
  return (
    <Item href={cate_link}>
      <ImageContainer>
        <Image src={cate_image} />
      </ImageContainer>
      <h2 className="text-sm text-primaryDark">{cate_name}</h2>
    </Item>
  )
}

export default Category
