import React from 'react'
// import types
import { ICardItem } from '../../@types/cards'
import {
  H3Black,
  H3Danger,
  H3PrimaryDarkRegular,
  PrimaryTextRegular,
  PrimaryTextThin,
  SecondaryTextStrikeThrough,
} from '../../GlobalStyles'
// helper function
import { formatPrice } from '../FlashCard'
import { LineClamp } from '../FlashCard/FlashCard.styles'
import {
  BadgesContainer,
  Container,
  FavIcon,
  FavIconContainer,
  Flex,
  Image,
  ImageContainer,
  Inner,
  RoundedBadgeContainer,
  ZigZagContainer,
} from './DealCards.styles'

const UNIT_TEXT = 'ชิ้น'

const DealCard = ({
  name,
  share_image,
  discount_price,
  master_price,
  lcs_choice,
  slug,
  onFavorite,
  isFavourite = false,
}: ICardItem) => {
  /*
   * additional values to be inferred from server values
   */
  const masterPrice = Number(master_price)
  const savings = masterPrice - Number(discount_price)
  const savingsPercentage = Math.round((savings / masterPrice) * 100)

  return (
    <Container>
      <a href={`/products/${slug}`} className="no-underline">
        <BadgesContainer>
          {lcs_choice && (
            <RoundedBadgeContainer>
              <img
                src="/images/lcsChoice.png"
                className="w-24"
                alt="lcs choice icon"
              />
            </RoundedBadgeContainer>
          )}

          {discount_price && savingsPercentage > 0 && (
            <ZigZagContainer>-{savingsPercentage}%</ZigZagContainer>
          )}
        </BadgesContainer>

        <ImageContainer>
          <Image loading="lazy" src={share_image} />
        </ImageContainer>

        <Inner>
          <LineClamp>
            <H3PrimaryDarkRegular>{name}</H3PrimaryDarkRegular>
          </LineClamp>

          <div>
            <Flex>
              {discount_price ? (
                <H3Danger>฿ {formatPrice(Number(discount_price))}</H3Danger>
              ) : (
                <H3Black>฿ {formatPrice(Number(master_price))}</H3Black>
              )}
              <PrimaryTextRegular> /{UNIT_TEXT}</PrimaryTextRegular>
            </Flex>
            <Flex className="h-8">
              {discount_price && (
                <>
                  <SecondaryTextStrikeThrough>
                    ฿{formatPrice(Number(master_price))}
                  </SecondaryTextStrikeThrough>
                  <PrimaryTextThin>
                    ประหยัด ฿{formatPrice(savings)}
                  </PrimaryTextThin>
                </>
              )}
            </Flex>
          </div>
        </Inner>
      </a>
      <div className="p-2 pt-0 rounded-b-md bg-offWhite">
        {isFavourite && (
          <FavIconContainer onClick={onFavorite}>
            <FavIcon src="/images/heart_icon_fill.png" />
          </FavIconContainer>
        )}
      </div>
    </Container>
  )
}

export default DealCard
