import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-6
`

export const CoverImage = tw.img`
  h-200
  lg:h-400
  w-full
  object-cover
  rounded-lg
`

export const TagsContainer = tw.div`
  flex
  items-center
  lg:px-4
  space-x-2
  mb-8
`

export const BlogTagsContainer = tw.div`
  flex
  flex-wrap
`

export const BlogTag = tw.div`
  rounded-full
  lg:py-3
  py-1
  lg:px-6
  px-3
  mb-1
  mr-2
  text-sm
  text-primaryDark
  bg-secondaryGray
`
