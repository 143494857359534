import React from 'react'

import { CheckoutPriceSection, CartItemsTable } from '..'
import {
  Container,
  FormAndCheckoutSectionContainer,
  DeliveryFormContainer,
  DeliveryHeaderContainer,
  FormContainer,
  CartItemsContainer,
} from '../checkoutAddress/CheckoutAddress.styles'

import { ICartItem } from '../../@types/cartItem'

const DELIVERYPAGE_HEADER = 'การจัดส่ง'

interface ConfirmPageProps {
  itemTotalPrice: string
  totalPrice: string
  cartItems: Array<ICartItem>
  isCouponInputVisible: boolean
  isTwoCeeTwoPee: boolean
  isButtonHidden?: boolean
  orderNumber: string
  valuePromo?: number
  promocode?: string
}

const ConfirmPage = ({
  itemTotalPrice,
  totalPrice,
  isCouponInputVisible,
  cartItems,
  isTwoCeeTwoPee,
  isButtonHidden,
  orderNumber,
  valuePromo,
  promocode,
}: ConfirmPageProps) => {
  return (
    <Container>
      <FormAndCheckoutSectionContainer>
        <FormContainer>
          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {DELIVERYPAGE_HEADER}
            </DeliveryHeaderContainer>

            <CartItemsContainer>
              <CartItemsTable cartItems={cartItems} />
            </CartItemsContainer>
          </DeliveryFormContainer>
        </FormContainer>

        <CheckoutPriceSection
          itemTotalPrice={itemTotalPrice}
          totalPrice={totalPrice}
          handleSubmit={() => {}}
          hasCouponInput={isCouponInputVisible}
          isCheckoutButtonHidden={isTwoCeeTwoPee}
          isButtonHidden={isButtonHidden}
          isBackButtonHidden
          orderNumber={orderNumber}
          valuePromo={valuePromo}
          promocode={promocode}
        />
      </FormAndCheckoutSectionContainer>
    </Container>
  )
}

export default ConfirmPage
