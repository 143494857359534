import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-6
  mb-6
`

export const ClearButton = tw.button`
  text-primaryDark
  underline
  text-xs
  hover:text-primary
`

export const OutlineButton = tw.div`
  space-x-2
  px-6
  py-2
  bg-white
  border-2
  border-primaryDark
  primaryDark
  text-primaryDark
  flex
  items-center
  justify-center
  hover:border-primary
  hover:text-primary
  rounded-md
`
