import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Outer = tw.div`
  darkSwiperPagination
  w-full 
  bg-secondaryGray
`

export const Container = tw(DefaultContainer)`
  py-10
  space-y-10
`

export const Header = tw.div`
  flex
  justify-between
  items-center
`

export const HeaderContainer = tw.div`
  flex
  items-center
  space-x-2
`

export const LinkContainer = tw.div`
  flex
  items-center
  space-x-2
`

export const Link = tw.a`
  text-primaryDark
  no-underline
  cursor-pointer
`

export const SmallImageContainer = tw.div`
  w-6
`

export const ImageContainer = tw.div`
  w-8
`

export const Image = tw.img`
  object-contain  
`
