import React from 'react'
import { SwiperSlide } from 'swiper/react'

// components
import { FlashCard, DealCard, SwiperEl } from '..'
// tw styled components
import { Outer, Header, HeaderContainer } from '../flashSales/FlashSales.styles'
import { Container } from './RelatedProducts.styles'
// global styles
import { H2Primary } from '../../GlobalStyles'

// windowSize hook
import { useWindowSize } from '../../hooks/useWindowSize'
import { MOBILE_WIDTH } from '../../constants/windowSize'
// import type
import { ICardItem } from '../../@types/cards'

const DESKTOP_SLIDES_PER_VIEW = 4
const DESKTOP_SLIDES_PER_GROUP = 4

interface RelatedProductsProps {
  relatedProducts: Array<ICardItem>
  heading: string
}

const RelatedProducts = ({
  relatedProducts,
  heading,
}: RelatedProductsProps) => {
  const windowSize = useWindowSize()
  /*
   * The card component show in mobile view is the 'DealCard' component,
   * whereas in the desktop view it's the 'FlashCard' component
   *
   * Also, the number of cards shown in desktop view is 4 compared to two
   * in the mobile view.
   *
   * Hence, the useWindowSize hook is used to decide which component and how many
   * to show based on mobile or desktop windowSize
   */
  const isMobileWidth = (windowSize.width || 0) <= MOBILE_WIDTH
  const CardComponent = isMobileWidth ? DealCard : FlashCard

  const swiperSlidersJSX =
    relatedProducts &&
    relatedProducts.map((item) => (
      <SwiperSlide key={item.id}>
        <CardComponent {...item} />
      </SwiperSlide>
    ))

  return (
    <Outer>
      <Container>
        <Header>
          <HeaderContainer>
            <H2Primary>{heading}</H2Primary>
          </HeaderContainer>
        </Header>

        <div className="flashCardsSwiper">
          <SwiperEl
            sliders={swiperSlidersJSX}
            desktopSlidesPerView={DESKTOP_SLIDES_PER_VIEW}
            desktopSlidesPerGroup={DESKTOP_SLIDES_PER_GROUP}
          />
        </div>
      </Container>
    </Outer>
  )
}

export default RelatedProducts
