import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  bg-white
`

export const FaqContainer = tw.div`
  border-b-2
  border-secondaryGray
`
