import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import { Input, Select } from '..'

import { ILocationData, ICountry } from '../../@types/formData'
import { getCSRFToken } from '../../helpers/utils'

import { USER_ADDRESS_INPUTS } from '../../constants/checkoutFormFields'

interface IFormType {
  firstname: string
  lastname: string
  address1: string
  address2: string
  lane: string
  road: string
  country_id: number
  state_id: number
  district_id: number
  subdistrict_id: number
  zipcode: number
}

interface UserAddressEditProps extends IFormType {
  addressId: number
  countryList: ICountry[]
  stateList: ILocationData[]
  districtList: ILocationData[]
  subdistrictList: ILocationData[]
}

const STATE_HEADING = 'จังหวัด'
const ZIPCODE_HEADING = 'รหัสไปรษณีย์'
const DISTRICT_HEADING = 'อำเภอ'
const SUB_DISTRICT_HEADING = 'ตำบล'
const COUNTRY_HEADING = 'ประเทศ'
const SUBMIT_ADDRESS_TEXT = 'บันทึก'
const BACK_BUTTON_TEXT = 'ยกเลิก'
const API_URL = '/users/update_address_info'

const UserAddressEdit = ({
  addressId,
  firstname,
  lastname,
  address1,
  address2,
  lane,
  road,
  country_id,
  state_id,
  district_id,
  subdistrict_id,
  countryList,
  stateList,
  districtList,
  subdistrictList,
  zipcode,
}: UserAddressEditProps) => {
  const [currentDistrictList, setCurrentDistrictList] =
    React.useState(districtList)
  const [currentSubDistrictList, setCurrentSubDistrictList] =
    React.useState(subdistrictList)

  const formMethods = useForm({
    defaultValues: {
      firstname,
      lastname,
      address1,
      address2,
      lane,
      road,
      country_id,
      state_id,
      district_id,
      subdistrict_id,
      zipcode,
    },
  })

  const address2Value = formMethods.watch('address2')

  React.useEffect(() => {
    if (address2Value === 'null') {
      formMethods.watch('address2', '')
    }
  }, [])

  /*
   * watch form values for stateList and districtList as the list rendered on screen
   * for districtList and subDistrictList depends on what was selected before.
   *
   * ex: render subDistricts based on the district selected
   */
  const currentStateId =
    formMethods.watch('state_id') ?? districtList[0].state_id
  const currentDistrictId =
    formMethods.watch('district_id') ?? subdistrictList[0].district_id

  React.useEffect(() => {
    // update district and subDistrict list to show only those values within the selected
    // state and district respectively
    setCurrentDistrictList(
      districtList.filter((district) => district.state_id === currentStateId)
    )

    setCurrentSubDistrictList(
      subdistrictList.filter(
        (subDistrict) => subDistrict.district_id === currentDistrictId
      )
    )
  }, [currentStateId, currentDistrictId])

  /*
   * @param values: form values from formik
   *
   * Calls api to send address with values from the form, and proceed to the next
   * step
   */
  function submitForm(values: any) {
    const data = new FormData()

    data.append('address[id]', String(addressId))

    Object.keys(values).forEach((key) => {
      data.append(`address[${key}]`, values[key])
    })

    // get csrf token
    const csrfToken = getCSRFToken()

    fetch(API_URL, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
      body: data,
    })
      .then(() => window.location.assign('/account'))
      .catch((error) => alert(error))
  }

  return (
    <form onSubmit={formMethods.handleSubmit(submitForm)}>
      <div className="grid w-full grid-cols-1 gap-6 px-8 py-6 lg:w-2/3 lg:grid-cols-2">
        {USER_ADDRESS_INPUTS.map(({ id, label, name, type, isRequired }) => (
          <Input
            key={id}
            type={type}
            {...formMethods.register(name as any, { required: isRequired })}
            hasError={formMethods.formState.errors[name]}
            errorText={formMethods.formState.errors[name]?.message}
            flexCol
            labelText={label}
            isRequired={isRequired}
          />
        ))}

        <FormProvider {...formMethods}>
          <Select
            selectName="country_id"
            labelText={COUNTRY_HEADING}
            isRequired
            list={countryList}
          />
          <Select
            selectName="state_id"
            labelText={STATE_HEADING}
            isRequired
            list={stateList}
          />
          <Select
            selectName="district_id"
            labelText={DISTRICT_HEADING}
            isRequired
            list={currentDistrictList}
          />
          <Select
            selectName="subdistrict_id"
            labelText={SUB_DISTRICT_HEADING}
            isRequired
            list={currentSubDistrictList}
          />
          <Input
            flexCol
            isRequired
            labelText={ZIPCODE_HEADING}
            type="number"
            {...formMethods.register('zipcode', { required: true })}
            errorText={formMethods.formState.errors.zipcode?.message}
            hasError={formMethods.formState.errors.zipcode}
          />
        </FormProvider>
      </div>
      <div className="flex w-full px-8 space-x-4 lg:w-1/4">
        <button
          type="submit"
          className="w-full py-1 text-sm font-light text-center text-white border-2 cursor-pointer border-primaryDark primaryDark bg-primaryDark bold rounded-5px lg:w-1/2"
        >
          {SUBMIT_ADDRESS_TEXT}
        </button>
        <a
          href="/account"
          className="flex items-center justify-center w-full py-1 text-sm font-light text-center bg-white border border-primaryDark primaryDark text-primaryDark bold rounded-5px lg:w-1/2"
        >
          {BACK_BUTTON_TEXT}
        </a>
      </div>
    </form>
  )
}

export default UserAddressEdit
