import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import * as React from 'react'
import ReactPaginate from 'react-paginate'
import { IPagination } from '../../@types/pagination'
import { H1Primary } from '../../GlobalStyles'
import {
  IconContainer,
  PaginationLabel,
} from '../CategoryProductsSection/CategoryProductsSection.styles'
import StoreItem from './StoreItem'
import {
  Container,
  Inner,
  LargeSearchButton,
  SearchContainer,
  SearchHeader,
  SearchInput,
  ShopsContainer,
} from './StoresPage.styles'

const PAGE_TITLE = 'ร้านค้าใกล้คุณ'
const SEARCH_HEADER = 'ค้นหาร้านค้าที่อยู่ใกล้คุณได้ ที่นี่'
const SEARCH_BUTTON_TEXT = 'ค้นหา'
const INPUT_PLACEHOLDER_TEXT = 'กรอกรหัสไปรษณีย์'

interface IStores {
  id: number
  address: string
  zipcode: string
  company_name: string
  latitude: string
  longitude: string
  thumbnail: string
}

interface StoresPageProps {
  stores: Array<IStores>
  pagination: IPagination
  search_term: any
}

const PaginationPrevious = () => (
  <PaginationLabel>
    <IconContainer>
      <ArrowLeftIcon className="w-6 h-6 text-primaryDark" />
    </IconContainer>
    <div>ก่อนหน้า</div>
  </PaginationLabel>
)

const PaginationNext = () => (
  <PaginationLabel className="float-right">
    <div>ถัดไป</div>
    <IconContainer>
      <ArrowRightIcon className="w-6 h-6 text-primaryDark" />
    </IconContainer>
  </PaginationLabel>
)

const StoresPage = ({ stores, pagination, search_term }: StoresPageProps) => {
  if (!stores) {
    return null
  }

  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const handlePageChange = ({ selected }: { selected: number }) => {
    const query = search_term
      ? `q=${search_term}&page=${selected + 1}`
      : `page=${selected + 1}`

    window.location.search = query
  }

  const handleSearchChange = () => {
    const query = searchInputRef?.current?.value
      ? `q=${searchInputRef?.current?.value}`
      : ''

    window.location.search = query
  }

  return (
    <Container>
      <H1Primary>{PAGE_TITLE}</H1Primary>
      <Inner>
        <div className="space-y-3">
          <SearchHeader>{SEARCH_HEADER}</SearchHeader>
          <SearchContainer>
            <SearchInput
              ref={searchInputRef}
              placeholder={INPUT_PLACEHOLDER_TEXT}
              defaultValue={search_term}
            />
            <LargeSearchButton onClick={handleSearchChange}>
              {SEARCH_BUTTON_TEXT}
            </LargeSearchButton>
          </SearchContainer>
        </div>

        <ShopsContainer>
          {stores.map((store) => (
            <StoreItem store={store} key={store.id} />
          ))}
        </ShopsContainer>

        {pagination.total_pages > 1 && (
          <ReactPaginate
            pageCount={pagination.total_pages}
            initialPage={pagination.current_page - 1}
            disableInitialCallback
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel={!pagination.is_first_page && <PaginationPrevious />}
            previousClassName="w-1/3"
            nextLabel={!pagination.is_last_page && <PaginationNext />}
            nextClassName="w-1/3"
            breakLabel="..."
            breakClassName="hidden md:block p-4 text-gray-500"
            containerClassName="flex border-t-2 border-gray-300 justify-between items-start"
            pageClassName="hidden md:block flex-shrink border-t-2 border-transparent p-4 text-gray-500 hover:border-primaryDark"
            activeClassName="border-t-2 border-primaryDark text-primaryDark"
            onPageChange={handlePageChange}
          />
        )}
      </Inner>
    </Container>
  )
}

export default StoresPage
