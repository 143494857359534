import React from 'react'
import WindowedSelect from 'react-windowed-select'
import { useFormContext, Controller } from 'react-hook-form'

import { Container, Label, RequiredSpan } from '../Input/Input.styles'

import { ILocationData, ICountry } from '../../@types/formData'

interface IRenderOptions {
  label: string
  value: string
}

interface SelectProps {
  labelText: string
  isLabelBold?: boolean
  isRequired?: boolean
  list: Array<ILocationData | ICountry>
  selectName: string
}

// eslint-disable-next-line react/display-name
const Select = ({
  labelText,
  selectName,
  isRequired = false,
  isLabelBold = false,
  list,
}: SelectProps) => {
  const { control } = useFormContext()
  /*
   * make options virtualized as the list is quite long upto 30,000 values on some
   */
  const renderOptions = list.map((option) => ({
    label: option.name_th ?? option.name,
    value: option.id,
  }))

  return (
    <Container $isFlexCol>
      <Label $isLabelBold={isLabelBold}>
        {labelText} {isRequired && <RequiredSpan>*</RequiredSpan>}
      </Label>
      <Controller
        control={control}
        defaultValue={renderOptions[0]?.value || ''}
        name={selectName}
        rules={{ required: isRequired }}
        render={({ field: { onChange, value, ref } }) => (
          <WindowedSelect
            default={renderOptions[0]}
            options={renderOptions}
            inputRef={ref}
            value={renderOptions.filter((option) => value === option.value)}
            onChange={(val: IRenderOptions) => onChange(val.value)}
          />
        )}
      />
    </Container>
  )
}

export default Select
