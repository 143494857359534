import tw from 'tailwind-styled-components'

export const InputContainer = tw.div`
  relative
  w-11/12
  lg:w-2/5
  self-center
  lg:block
`

export const Input = tw.input`
  bg-white
  mx-4
  w-full
  h-10
  px-5
  pl-12
  rounded-lg
  text-sm
  focus:outline-none
  placeholder-primary
`

export const InputIconContainer = tw.div`
  absolute
  top-0
  h-full
  flex 
  justify-center 
  items-center 
  w-6 
  ml-8
`

export const Image = tw.img`
  object-contain
`
