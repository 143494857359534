import React from 'react'
import { PencilAltOutline } from '@graywolfai/react-heroicons'

import { Button } from './EditButton.styles'

interface EditButtonProps {
  innerText: string
  onClick: () => void
}

const EditButton = ({ innerText, onClick }: EditButtonProps) => {
  return (
    <Button onClick={onClick}>
      <PencilAltOutline className="w-6 h-6 mr-2" />
      {innerText}
    </Button>
  )
}

export default EditButton
