import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  lg:py-10
  py-4
  px-2
`

export const Inner = tw.div`
  rounded-lg
  border-primaryDark
  lg:border-8
  border-4
  bg-primaryDark
`

export const InnerWhite = tw.div`
  bg-white
`

export const ItemsContainer = tw.div`
  flex
  flex-col
  py-6
  space-y-6
`

export const Header = tw.div`
  w-full
  bg-primaryDark
  lg:border-8
  border-4
  border-primaryDark
  flex
  items-center
  justify-center
  space-x-3
  py-4
`

export const IconContainer = tw.div`
  w-8
`

export const Icon = tw.img`
  object-contain  
`

export const PrimaryOutlineButton = tw.button`
  self-center
  py-2
  px-10
  lg:px-20
  rounded-md
  border
  border-primaryDark
  lg:text-lg
  text-primaryDark
  text-center
`
