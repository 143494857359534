import tw from 'tailwind-styled-components'

export const Container = tw.div`
  grid
  md:grid-cols-4
  grid-cols-3
  gap-4
`

export const Item = tw.div`
  flex
  flex-col
  justify-center
  items-center
`

export const ImageContainer = tw.div`
  w-20
  lg:w-40
  cursor-pointer
`

export const Image = tw.img`
  object-contain  
`
