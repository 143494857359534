import React from 'react'
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { IBanner } from '../../@types/banners'
import { NavLanguage } from '../../@types/nav'
import { USPMenubar } from '../primaryNavbar/USPMenubar'
import './LcsSwiper.css'

const AUTOPLAY_DELAY = 5000

interface IBanners {
  primaryBanner: Array<IBanner>
  secondaryBanner: Array<IBanner>
  ternaryBanner: Array<IBanner>
}

interface HeadCarouselProp {
  banners: IBanners
}

SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay])

const HeadCarousel = ({ banners }: HeadCarouselProp) => {
  return (
    <>
      <div className="container px-2 mx-auto mb-4 max-w-7xl md:px-4">
        <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-4">
          <Swiper
            className="w-full"
            pagination={{ clickable: true }}
            slidesPerView={1}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: AUTOPLAY_DELAY }}
            navigation
            loop
          >
            {banners &&
              banners.primaryBanner.map((banner) => (
                <SwiperSlide
                  key={banner.image_url}
                  className="aspect-w-16 aspect-h-9"
                >
                  <a href={banner.link_url}>
                    <img
                      loading="lazy"
                      src={banner.image_url}
                      alt={banner.image_url}
                      className="object-contain w-full h-full"
                    />
                  </a>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className="flex flex-col space-y-2 md:flex-row md:justify-evenly md:space-y-0 lg:flex-col lg:justify-between lg:space-y-4">
            <Swiper
              className="w-full md:w-5/12 lg:w-260"
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              slidesPerView={1}
              autoplay={{ delay: AUTOPLAY_DELAY }}
              loop
            >
              {banners &&
                banners.secondaryBanner.map((banner) => (
                  <SwiperSlide
                    key={banner.image_url}
                    className="aspect-w-1 aspect-h-1"
                  >
                    <a href={banner.link_url}>
                      <img
                        loading="lazy"
                        src={banner.image_url}
                        alt={banner.image_url}
                        className="object-contain w-full h-full"
                      />
                    </a>
                  </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
              className="w-full md:w-5/12 lg:w-260"
              pagination={{ clickable: true }}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
              autoplay={{ delay: AUTOPLAY_DELAY }}
              loop
            >
              {banners &&
                banners.ternaryBanner.map((banner) => (
                  <SwiperSlide
                    key={banner.image_url}
                    className="aspect-w-1 aspect-h-1"
                  >
                    <a href={banner.link_url}>
                      <img
                        loading="lazy"
                        src={banner.image_url}
                        alt={banner.image_url}
                        className="object-contain w-full h-full"
                      />
                    </a>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <USPMenubar
          language={
            (localStorage.getItem('language') as NavLanguage) ?? 'name_th'
          }
        />
      </div>
    </>
  )
}

export default HeadCarousel
