import tw from 'tailwind-styled-components'

const CardsGrid = tw.div`
  grid
  grid-cols-2
  lg:grid-cols-4
  gap-2
`

export default CardsGrid
