import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  py-4
  space-y-8
`

export const Inner = tw.div`
  lg:px-10
  w-full
  px-0
  lg:space-y-8
  space-y-6
`

export const SearchHeader = tw.p`
  text-sm
  text-primary
`

export const SearchContainer = tw.div`
  flex
  space-x-3
  lg:space-x-6
  w-full
`

export const SearchInput = tw.input`
  lg:w-8/12
  w-3/4
  lg:p-4
  p-2
  rounded-md
  border
  border-gray-200
  focus:outline-none
  focus:ring
  focus:ring-primary
`

export const LargeSearchButton = tw.button`
  lg:w-4/12
  w-1/4
  lg:p-4
  p-2
  rounded-md
  bg-primaryDark
  hover:bg-primary
  text-white
  font-semibold
`
export const ShopsContainer = tw.div`
  lg:space-y-3
  space-y-2
`

export const ShopContainer = tw.div`
  flex
  lg:flex-row
  flex-col
  lg:space-x-4
  space-x-0
  space-y-4
  lg:space-y-0
  w-full
`

export const ImageContainer = tw.div`
  lg:w-2/5
  lg:h-full
  w-full
  h-200
`

export const Image = tw.img`
  rounded-md
  object-contain
`

export const ShopDetailContainer = tw.div`
  w-full
  lg:w-3/5
  flex
  flex-col
  lg:space-y-4
  space-y-3
`

export const Link = tw.a`
  flex
  space-x-3
  text-primary
  hover:text-primaryDark
  lg:ml-auto
`
