import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  flex
  flex-col
  md:flex-row
  space-y-4
  mb-12
`

export const CategoryTitleContainer = tw.div`
  lg:hidden
  p-4
`

export const HeroImageContainer = tw.div`
  hidden
  lg:block
  w-2/5
  h-full
`

export const HeroImage = tw.img`
  w-full
  h-full
  object-cover
`

export const CategoriesContainer = tw.div`
  lg:w-3/5
  w-full
  h-full
`
