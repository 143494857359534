import tw from 'tailwind-styled-components'

export const Container = tw.div`
  px-4
  w-full
  lg:px-6
  py-6
  my-4
  text-secondaryGrayDark
  text-sm
  rounded-md
`
