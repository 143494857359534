import React, { useState } from 'react'
import { CheckoutPriceSection } from '..'
import { getCSRFToken } from '../../helpers'
import {
  Container,
  DeliveryFormContainer,
  DeliveryHeaderContainer,
  FormAndCheckoutSectionContainer,
  FormContainer,
} from '../checkoutAddress/CheckoutAddress.styles'
import { PaymentButton } from './PaymentPage.styles'

const PAYMENT_PAGE_HEADER = 'การจัดส่ง'

interface PaymentMethod {
  id: number
  name: string
}

interface DeliveryPageProps {
  itemTotalPrice: string
  totalPrice: string
  payment_methods: PaymentMethod[]
  orderNumber: string
  valuePromo?: number
  promocode?: string
}

const PaymentPage = ({
  itemTotalPrice,
  totalPrice,
  payment_methods,
  orderNumber,
  valuePromo,
  promocode,
}: DeliveryPageProps) => {
  const [paymentType, setPaymentType] = useState(payment_methods[0])

  const handlePayment = async (e: any) => {
    e.preventDefault()

    await fetch('/checkout/update/payment', {
      method: 'PATCH',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': getCSRFToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: {
          payments_attributes: [{ payment_method_id: String(paymentType.id) }],
        },
      }),
    }).then((res) => {
      if (res.ok) {
        window.location.href = '/checkout/confirm'
      }
    })
  }

  return (
    <Container>
      <FormAndCheckoutSectionContainer>
        <FormContainer>
          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {PAYMENT_PAGE_HEADER}
            </DeliveryHeaderContainer>

            <div className="flex flex-col px-2 pt-4 pb-6 space-y-6 lg:px-6 lg:flex-row lg:space-y-0 lg:space-x-6">
              {payment_methods.map((payment) => (
                <PaymentButton
                  key={payment.name}
                  $isActive={payment.name === paymentType.name}
                  onClick={(event) => {
                    event.preventDefault()
                    setPaymentType(payment)
                  }}
                >
                  {payment.name}
                </PaymentButton>
              ))}
            </div>
          </DeliveryFormContainer>
        </FormContainer>

        <CheckoutPriceSection
          itemTotalPrice={itemTotalPrice}
          totalPrice={totalPrice}
          handleSubmit={handlePayment}
          orderNumber={orderNumber}
          valuePromo={valuePromo}
          promocode={promocode}
        />
      </FormAndCheckoutSectionContainer>
    </Container>
  )
}

export default PaymentPage
