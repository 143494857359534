import React from 'react'

import { Button } from './OutlineButton.styles'

interface OutlineButtonProps {
  innerText: string
  handleClick: () => void
}

const OutlineButton = ({ innerText, handleClick }: OutlineButtonProps) => {
  return <Button onClick={handleClick}>{innerText}</Button>
}

export default OutlineButton
