/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { LogoutOutline, UserOutline } from '@graywolfai/react-heroicons'
import { Popover } from '@headlessui/react'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LoggedInSlideOver } from '..'
import { HrGray, PSecondaryDark } from '../../GlobalStyles'
import { addBodyScroll, removeBodyScroll } from '../../helpers/util'
import { getCSRFToken } from '../../helpers/utils'
import { useScreenSize } from '../../hooks'
import {
  Bottom,
  Container,
  FacebookButton,
  Flex,
  Form,
  Inner,
  Input,
  LoginFormHeader,
  menuWrapperClassNames,
  MobileTopRow,
  smMenuWrapperClassNames,
  SocialLoginText,
  SubmitButton,
} from './LoginModal.styles'

const LOGIN_FORM_HEADER = 'เข้าใช้งานบัญชีของคุณ'
const SOCIAL_LOGIN_TEXT = 'เข้าใช้งานด้วย'
const REGISTER_TEXT = 'สร้างบัญชีใหม่'
const FORGOT_PASSWORD = 'ลืมรหัสผ่าน?'
const FACEBOOK = 'Facebook'
const SEPERATOR_TEXT = 'หรือ'
const SUBMIT_BUTTON_TEXT = 'เข้าใช้งาน'
const PASSWORD_PLACEHOLDER = 'กรอกรหัสผ่าน'
const EMAIL_PLACEHOLDER = 'you@example.com'
const ACCOUNT_TEXT = 'บัญชีของฉัน'
// get csrf token
const NETWORK_ERROR_MESSAGE = 'There seems to be a network error'

/*
 * @returns user email if user is looged in otherwise returns empty string
 *
 * checks if user is logged in and returns email
 */
async function getUserEmail(): Promise<string> {
  const userDetails = await fetch('/details').then((res) => res.json())

  return userDetails.email ?? ''
}

interface IFormValues {
  email: string
  password: string
}

const LoginModal = () => {
  const [userEmail, setUserEmail] = React.useState('')

  React.useEffect(() => {
    const effectSetUserEmail = async () => {
      const currentUserEmail = await getUserEmail()
      setUserEmail(currentUserEmail)
    }

    effectSetUserEmail()
  }, [])
  const { minSize } = useScreenSize()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>()

  const isMobile = !minSize('md')

  const loginButton = (
    <img
      src="/images/avatar_icon.svg"
      className="w-6 h-6 lg:h-8 lg:w-8"
      alt="avatar"
    />
  )

  const smRemoveBodyScroll = () => {
    if (isMobile) {
      removeBodyScroll()
    }
  }

  const smAddBodyScroll = () => {
    if (isMobile) {
      addBodyScroll()
    }
  }

  const login = async (values: IFormValues) => {
    const data = new FormData()

    data.append('spree_user[email]', values.email)
    data.append('spree_user[password]', values.password)

    try {
      await fetch('/login', {
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': getCSRFToken(),
        },
        body: data,
      }).then((res) => {
        if (!res.ok) {
          throw new Error(NETWORK_ERROR_MESSAGE)
        }
      })

      /*
       * As the login api does not return a response body, the details api
       * is called to check if there is a logged in user
       */
      const userDetails = await fetch('/details')
        .then((res) => res.json())
        .then((userData) => userData)

      if (!userDetails.email) {
        throw new Error('Please check email or password')
      }

      window.location.reload()
    } catch (error) {
      toast.error(String(error))
    }
  }

  const LoginBody = () => {
    return (
      <Popover.Panel className="absolute right-0 z-10">
        {({ close }) => (
          <div
            className={`${
              isMobile ? smMenuWrapperClassNames : menuWrapperClassNames
            }`}
          >
            <MobileTopRow>
              <div className="text-xl">{LOGIN_FORM_HEADER}</div>
              <button
                type="button"
                className="absolute right-2 top-7"
                onClick={() => {
                  close()
                }}
              >
                <img
                  className="w-6 h-6"
                  alt="menu close icon"
                  src="/images/X.png"
                />
              </button>
            </MobileTopRow>
            <Container>
              <Inner>
                <LoginFormHeader>{LOGIN_FORM_HEADER}</LoginFormHeader>
                <HrGray className="hidden lg:block" />
                <div className="space-y-2">
                  <SocialLoginText>{SOCIAL_LOGIN_TEXT}</SocialLoginText>
                  <FacebookButton
                    href={`/users/auth/facebook?r=${Math.random()}`}
                  >
                    <img
                      alt="facebook logo"
                      className="object-cover w-6 mr-3"
                      src="/images/facebookLoginLogo.png"
                    />
                    {FACEBOOK}
                  </FacebookButton>
                </div>
                <Flex>
                  <HrGray />
                  <PSecondaryDark>{SEPERATOR_TEXT}</PSecondaryDark>
                  <HrGray />
                </Flex>
                <Form onSubmit={handleSubmit(login)}>
                  <Input
                    $hasError={Boolean(errors.email)}
                    type="email"
                    placeholder={EMAIL_PLACEHOLDER}
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  <Input
                    $hasError={Boolean(errors.password)}
                    type="password"
                    placeholder={PASSWORD_PLACEHOLDER}
                    {...register('password', { required: true })}
                  />
                  <SubmitButton type="submit">
                    {SUBMIT_BUTTON_TEXT}
                  </SubmitButton>
                </Form>
              </Inner>
              <Bottom>
                <a
                  href="/user/spree_user/sign_up"
                  className="underline hover:text-primary"
                >
                  {REGISTER_TEXT}
                </a>
                <p>|</p>
                <a
                  href="/password/recover"
                  className="underline hover:text-primary"
                >
                  {FORGOT_PASSWORD}
                </a>
              </Bottom>
            </Container>
          </div>
        )}
      </Popover.Panel>
    )
  }

  const logout = () => {
    fetch('/logout', {
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': getCSRFToken(),
      },
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => toast.error(error))
  }

  const LogoutBody = () => (
    <Popover.Panel className="absolute right-0 z-10">
      {({ close }) => (
        <div className="flex flex-col w-48 space-y-3 bg-white rounded-md">
          <a
            className="flex px-6 py-3 cursor-pointer text-primaryDark hover:bg-primary hover:text-white"
            href="/account"
          >
            <UserOutline className="w-6 h-6 pr-2" />
            {ACCOUNT_TEXT}
          </a>
          <div
            className="flex px-6 py-3 cursor-pointer text-primaryDark hover:bg-primary hover:text-white"
            onClick={() => {
              logout()
              close()
            }}
          >
            <LogoutOutline className="w-6 h-6 pr-2" />
            Logout
          </div>
        </div>
      )}
    </Popover.Panel>
  )

  return (
    <>
      {userEmail && isMobile ? (
        <LoggedInSlideOver />
      ) : (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button className="flex items-center">
                {loginButton}
              </Popover.Button>
              <Popover.Overlay
                className={`${
                  open ? 'opacity-30 fixed inset-0' : 'opacity-0'
                } bg-black`}
              />
              {userEmail.length ? <LogoutBody /> : <LoginBody />}
            </>
          )}
        </Popover>
      )}
    </>
  )
}

export default LoginModal
