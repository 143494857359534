import * as React from 'react'

interface CouponSectionProps {
  handleSubmit: (coupon: string) => (e?: any) => void
}

export const CouponSection = ({ handleSubmit }: CouponSectionProps) => {
  const [coupon, setCoupon] = React.useState('')

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCoupon(e.target.value)

  return (
    <div className="flex w-full border border-gray-200 rounded-md">
      <input
        type="text"
        placeholder="รหัสคูปอง"
        className="w-2/3 px-4 py-2 placeholder-gray-300 border border-gray-200 rounded-l-md focus:outline-none"
        onChange={handleCouponChange}
      />
      <button
        type="button"
        className="w-1/3 py-2 text-primaryDark bg-secondaryGray rounded-r-md"
        onClick={handleSubmit(coupon)}
      >
        ใช้คูปอง
      </button>
    </div>
  )
}
