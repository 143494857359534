import tw from 'tailwind-styled-components'

export const Anchor = tw.a<{ $className?: string }>`
  ${(props) => (props.$className ? props.$className : '')}
  ${(props) => (props.$className?.includes('font-') ? '' : 'font-semibold')}
  ${(props) => (props.$className?.includes('text-') ? '' : 'text-xs')}
  no-underline 
  cursor-pointer
  flex
  justify-start 
  items-center
  text-primaryDark
  px-2
  py-3
  ml-4
  md:text-base
  md:mx-2
  md:text-white
  md:px-0
  md:py-0
  md:block
`
