import React from 'react'

import { EditButton } from '..'
import {
  H3PrimaryDark,
  PrimaryTextRegular,
  PSecondaryDark,
} from '../../GlobalStyles'
import {
  Container,
  Flex,
  InfoContainer,
  Row,
  Inner,
} from './MyAccountPage.styles'

const INFO_HEADER = 'บัญชีของฉัน'
const NAME_HEADER = 'ชื่อ'
const PHONE_HEADER = 'เบอร์มือถือ'
const EMAIL_HEADER = 'อีเมล'
const PASSWORD_HEADER = 'รหัสผ่าน'
const EDIT_TEXT = 'แก้ไข'
const ADDRESS_INFO_HEADER = 'ที่อยู่ในการจัดส่ง'
const ADDRESS_HEADER = 'ที่อยู่'
const ALTERNATE_PHONE_HEADER = 'เบอร์ติดต่อสำรอง'

interface IAddress {
  firstname: string
  lastname: string
  address: string
  address1: string
  address2: string
  subdistrict: string
  district: string
  city: string
  state: string
  country: string
  phone: string
  alternative_phone: string
  zipcode: string
}

interface IUser {
  firstname: string
  lastname: string
  email: string
  password: string
  phone: number
}

interface MyAccountPageProps {
  user: IUser
  address: IAddress
}

const MyAccountPage = ({ user, address }: MyAccountPageProps) => {
  const userName = `${user?.firstname || ''} ${user?.lastname || ''}`
  const addressName = `${address?.firstname || ''} ${address?.lastname || ''}`

  const routeToEdit = () => {
    window.location.href = '/account/edit'
  }

  const editButton = <EditButton onClick={routeToEdit} innerText={EDIT_TEXT} />

  return (
    <Container>
      <Inner>
        <Flex>
          <H3PrimaryDark>{INFO_HEADER}</H3PrimaryDark>
          {editButton}
        </Flex>
        <InfoContainer>
          <Row>
            <PrimaryTextRegular>{NAME_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{userName}</PSecondaryDark>
          </Row>
          <Row>
            <PrimaryTextRegular>{PHONE_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{user?.phone || ''}</PSecondaryDark>
          </Row>
          <Row>
            <PrimaryTextRegular>{EMAIL_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{user?.email || ''}</PSecondaryDark>
          </Row>
          <Row>
            <PrimaryTextRegular>{PASSWORD_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>*********</PSecondaryDark>
          </Row>
        </InfoContainer>
      </Inner>

      <Inner>
        <Flex>
          <H3PrimaryDark>{ADDRESS_INFO_HEADER}</H3PrimaryDark>
          {editButton}
        </Flex>
        <InfoContainer>
          <Row>
            <PrimaryTextRegular>{NAME_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{addressName}</PSecondaryDark>
          </Row>
          <Row>
            <PrimaryTextRegular>{ADDRESS_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{`${address?.address || ''}`}</PSecondaryDark>
          </Row>
          <Row>
            <PrimaryTextRegular>{ALTERNATE_PHONE_HEADER} :</PrimaryTextRegular>
            <PSecondaryDark>{address?.phone || ''}</PSecondaryDark>
          </Row>
        </InfoContainer>
      </Inner>
    </Container>
  )
}

export default MyAccountPage
