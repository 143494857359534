import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Input, Select } from '..'
import { FormikContainer } from '../checkoutAddress/CheckoutAddress.styles'
import { ICheckoutAddressInput } from '../../constants/checkoutFormFields'

import { ILocationData, ICountry } from '../../@types/formData'

interface AddressFormProps {
  subDistrictList: Array<ILocationData>
  districtList: Array<ILocationData>
  countryList: Array<ICountry>
  fields: Array<ICheckoutAddressInput>
}

const AddressForm = ({ districtList, subDistrictList, countryList, fields }: AddressFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormikContainer>
      {fields.map(({ id, label, name, type, isRequired }) => (
        <Input
          key={id}
          type={type}
          {...register(name)}
          hasError={errors[name]}
          errorText={errors[name]?.message}
          flexCol
          labelText={label}
          isRequired={isRequired}
        />
      ))}
      <Select
        listType="subDistrictList"
        labelText={SUB_DISTRICT_HEADING}
        isRequired
        list={subDistrictList}
      />
      <Select listType="districtList" labelText={DISTRICT_HEADING} isRequired list={districtList} />
      <Select listType="countryList" labelText={COUNTRY_HEADING} isRequired list={countryList} />
      <Input
        flexCol
        labelText={ALTERNATIVE_CONTACT_HEADING}
        type="number"
        {...register('backupNumber')}
        errorText={errors.phone?.backupNumber}
        hasError={errors.backupNumber}
      />
    </FormikContainer>
  )
}

export default AddressForm
