import * as React from 'react'
import { ChevronRightSolid } from '@graywolfai/react-heroicons'
import { ToastContainer, toast } from 'react-toastify'

import { Container, BreadCrumbContainer } from './FavoritePage.styles'
import { H1Primary } from '../../GlobalStyles'
// components
import { DealCard } from '..'

// import type card
import { ICardItem } from '../../@types/cards'

const PAGE_HEADER = 'รายการโปรด'

interface FavoritePageProps {
  favouriteProducts: Array<ICardItem>
}

const FavoritePage = ({ favouriteProducts = [] }: FavoritePageProps) => {
  const [products, setProducts] = React.useState<ICardItem[]>([])

  React.useEffect(() => {
    setProducts(favouriteProducts)
  }, [favouriteProducts])

  if (!favouriteProducts) {
    return null
  }

  const handleFavItem = (favId: number) => () => {
    const csrfToken =
      document.querySelector("[name='csrf-token']")?.content ?? ''
    const formData = new FormData()

    formData.append('favorite_id', String(favId))

    fetch(`/favorites/${favId}`, {
      method: 'DELETE',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then(() => {
        toast.success('Product has been removed from favorites')
        const newList = products.filter(
          (product) => product.favorite_id !== favId
        )
        setProducts(newList)
      })
      .catch(() => toast.error('Error removing product from favorites'))
  }

  return (
    <Container>
      <BreadCrumbContainer>
        <a className="no-underline" href="/">
          LCS
        </a>
        <ChevronRightSolid className="w-6 h-6" />
        <p>Favourite</p>
      </BreadCrumbContainer>

      <H1Primary>{PAGE_HEADER}</H1Primary>

      <div className="grid grid-cols-2 gap-4 lg:gap-6 lg:grid-cols-6">
        {products.map((product) => (
          <DealCard
            key={product.id}
            {...product}
            onFavorite={handleFavItem(product.favorite_id)}
            isFavourite
          />
        ))}
      </div>

      <ToastContainer position="top-right" />
    </Container>
  )
}

export default FavoritePage
