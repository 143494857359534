import { ChevronDownIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { COPYRIGHT_TEXT } from '../../constants/copyright'
import {
  DELIVERY_ICONS,
  PAYMENT_ICONS,
  SOCIAL_ICONS,
} from '../../constants/footerIcons'
import { FOOTER_MENU_ITEMS } from '../../constants/footerMenuItems'
import { H4Primary, HrPrimary } from '../../GlobalStyles'
import {
  BankIconsGrid,
  CopyrightContainer,
  CopyRightText,
  DeliveryIconsGrid,
  DownIconContainer,
  Expandable,
  FooterElement,
  HeaderContainer,
  Image,
  ImageContainer,
  LinkText,
  LogoContainer,
  MenuHeaderContainer,
  Outer,
  PaymentImageContainer,
} from './Footer.styles'

const BANK_ICONS_HEADER = 'วิธีการชำระเงิน'
const DELIVERY_ICONS_HEADER = 'จัดส่งสินค้าโดย'
const SOCIAL_ICONS_HEADER = ' เชื่อมต่อกับเรา'

const Footer = () => {
  const [current, setCurrent] = useState('')

  return (
    <Outer>
      <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <FooterElement className="hidden lg:block lg:col-span-3">
            <a className="no-underline" href="/">
              <LogoContainer>
                <Image src="/images/lcsLogoDark.png" />
              </LogoContainer>
            </a>
            <HeaderContainer>
              <H4Primary>{SOCIAL_ICONS_HEADER}</H4Primary>
            </HeaderContainer>
            <div className="flex items-center mb-4 space-x-4">
              {SOCIAL_ICONS.map(({ id, iconSrc, altText, linkTo }) => (
                <a className="no-underline" href={linkTo} key={id}>
                  <img className="w-8 lg:w-10" src={iconSrc} alt={altText} />
                </a>
              ))}
            </div>
          </FooterElement>

          {FOOTER_MENU_ITEMS.map((item) => (
            <div className="col-span-1 lg:col-span-2" key={item.id}>
              <MenuHeaderContainer
                onClick={() => setCurrent(item.id === current ? '' : item.id)}
              >
                <H4Primary>{item.menuHeading}</H4Primary>
                <DownIconContainer>
                  <ChevronDownIcon className="w-6 h-6 text-primaryDark" />
                </DownIconContainer>
              </MenuHeaderContainer>

              <div className="hidden lg:flex">
                <Expandable $isVisible>
                  {item.menuSubItems.map((subItem) => (
                    <LinkText key={subItem.id} href={subItem.linkTo}>
                      {subItem.header}
                    </LinkText>
                  ))}
                </Expandable>
              </div>

              <div className="lg:hidden">
                <Expandable $isVisible={current === item.id}>
                  {item.menuSubItems.map((subItem) => (
                    <LinkText key={subItem.id} href={subItem.linkTo}>
                      {subItem.header}
                    </LinkText>
                  ))}
                </Expandable>
              </div>
            </div>
          ))}

          <div className="col-span-1 lg:col-span-3">
            <div>
              <HeaderContainer>
                <H4Primary>{BANK_ICONS_HEADER}</H4Primary>
              </HeaderContainer>

              <BankIconsGrid>
                {PAYMENT_ICONS.map((icon) => (
                  <PaymentImageContainer key={icon.id}>
                    <Image src={icon.iconSrc} alt={icon.altText} />
                  </PaymentImageContainer>
                ))}
              </BankIconsGrid>
            </div>
            <div>
              <HeaderContainer>
                <H4Primary>{DELIVERY_ICONS_HEADER}</H4Primary>
              </HeaderContainer>

              <DeliveryIconsGrid>
                {DELIVERY_ICONS.map((icon) => (
                  <ImageContainer key={icon.id}>
                    <Image src={icon.iconSrc} alt={icon.altText} />
                  </ImageContainer>
                ))}
              </DeliveryIconsGrid>
            </div>
          </div>
        </div>

        <div className="col-span-1 lg:hidden">
          <HeaderContainer>
            <H4Primary>{SOCIAL_ICONS_HEADER}</H4Primary>
          </HeaderContainer>
          <div className="flex items-center mb-4 space-x-2">
            {SOCIAL_ICONS.map(({ id, iconSrc, altText, linkTo }) => (
              <a className="no-underline" href={linkTo} key={id}>
                <img className="w-8 lg:w-10" src={iconSrc} alt={altText} />
              </a>
            ))}
          </div>
        </div>

        <HrPrimary />
        <CopyrightContainer>
          <CopyRightText>{COPYRIGHT_TEXT.english}</CopyRightText>
          <CopyRightText>{COPYRIGHT_TEXT.thai}</CopyRightText>
        </CopyrightContainer>
      </div>
    </Outer>
  )
}

export default Footer
