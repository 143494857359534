import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { DealCard } from '..'
import { ICardItem } from '../../@types/cards'
import { Outer } from './HotDeals.styles'

const RIGHT_LINK_TEXT = 'ดูทั้งหมด'
const RIGHT_LINK = '/t/categories?hot_deal=true'

interface HotDealsProps {
  hotDeals: Array<ICardItem>
  taxonLink: string
}

const HotDeals = ({ hotDeals = [], taxonLink }: HotDealsProps) => {
  if (!hotDeals.length) {
    return null
  }

  return (
    <Outer>
      <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <img
              src="/images/hot_deal.svg"
              alt="hot deal icon"
              className="w-4 lg:w-6"
            />
            <h2 className="text-xl font-bold text-white lg:text-2xl">
              Hot Deal
            </h2>
          </div>

          <a
            href={taxonLink ? `/t/${taxonLink}?hot_deal=true` : RIGHT_LINK}
            className="flex items-center text-sm text-white lg:text-base"
          >
            {RIGHT_LINK_TEXT}
            <ChevronRightIcon className="w-4 text-white lg:w-6" />
          </a>
        </div>

        <div className="mt-4 lg:mt-6">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            scrollbar={{ draggable: true }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 16,
              },
            }}
            navigation
          >
            {hotDeals.map((item) => (
              <SwiperSlide className="py-2" key={item.id}>
                <DealCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Outer>
  )
}

export default HotDeals
