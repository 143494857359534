import React from 'react'
import { Disclosure, Transition } from '@headlessui/react'

import { H3Primary, H3SecondaryDark } from '../../GlobalStyles'
import { DropdownHeaderContainer, IconContainer, Icon } from './Dropdown.styles'

interface DropdownProps {
  headerText: string
  children: React.ReactNode
  darkHeader?: boolean
  defaultOpen?: boolean
  showIcon?: boolean
}

const Dropdown = ({
  headerText,
  children,
  darkHeader = false,
  defaultOpen = true,
  showIcon = true,
}: DropdownProps) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        /*
         * "open" is a render prop is exposed by Disclosure.
         */
        <>
          <Disclosure.Button className="w-full">
            <DropdownHeaderContainer>
              {darkHeader ? (
                <H3SecondaryDark>{headerText}</H3SecondaryDark>
              ) : (
                <H3Primary>{headerText}</H3Primary>
              )}
              <IconContainer className={showIcon ? '' : 'hidden'}>
                <Icon $isOpen={open} src="/images/chevronUp.png" />
              </IconContainer>
            </DropdownHeaderContainer>
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="px-1 pt-2 pb-8 space-y-4">
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

Dropdown.defaultProps = {
  darkHeader: false,
  defaultOpen: true,
  showIcon: true,
}

export default Dropdown
