import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Navbar = tw.nav<{ $isFixed?: boolean }>`
  ${(props) => (props.$isFixed ? 'fixed top-0 w-full' : 'relative')}
  bg-gradient-to-r from-gradientDark to-gradientLight
  w-full
  lg:h-auto
`

export const PrimaryNavShadow = tw.div`
  w-full
  lg:shadow-md
`

export const Container = tw(DefaultContainer)`
  flex
  py-3
  justify-between
  items-center
`

export const MenuCloseButton = tw.button<{
  $isVisible: boolean
  $isSearchVisible: boolean
}>`
  w-10
  ${(props) => (props.$isVisible ? 'fixed' : 'hidden')}
  ${(props) => (props.$isSearchVisible ? 'top-400' : '')}
  right-2
  xs:right-3
  z-10
  cursor-pointer
  lg:hidden
  transform
  duration-300
  ${(props) => (props.$isVisible ? 'translate-x-0' : 'translate-x-20')}
`

export const MenuIconContainer = tw.div`
  lg:hidden
  w-6
  flex
  align-center
`

export const Overlay = tw.div<{ $isSearchVisible: boolean }>`
  z-5
  cursor-default
  absolute
  ${(props) => (props.$isSearchVisible ? 'top-400' : '')}
  left-0
  w-screen
  min-h-no-top-nav 
  bg-overlay-60
  duration-100
  transform
`

export const MobileMenuWrapper = tw.div<{ $isOpen: boolean }>`
  min-h-no-top-nav 
  w-full
  fixed 
  overflow-y-scroll
  top-nav
  z-50
  duration-300
  transform
  ${(props) => (props.$isOpen ? 'translate-x-0' : '-translate-x-full')}
`

export const MobileMenuContainer = tw.div<{ $isSearchVisible: boolean }>`
  z-50
  w-5/6
  xs:w-3/4
  min-h-no-top-nav
  lg:hidden
  bg-white
  ${(props) => (props.$isSearchVisible ? 'top-400' : '')}
  transform
  duration-300
  flex
  flex-col
  justify-between
`

export const MobileNavLinkContainer = tw.div`
  flex
  flex-col
`

export const MobileNavLinkWithBorder = tw.div`
  border-b-2
  border-gray-100
  lg:border-b-0
`

export const LogoContainer = tw.div`
  lg:w-32
  w-24
`

export const Image = tw.img`
  object-contain
`

export const NavLinkContainer = tw.div`
  hidden
  lg:flex
  justify-between
`

export const NavIconsContainer = tw.div`
  flex
  items-center
  justify-between
`

export const SearchContainer = tw.div<{ $isVisible: boolean }>`
  w-screen
  h-16
  py-2
  bg-gradient-to-r from-gradientDark to-gradientLight
  lg:hidden  
  transition
  duration-300
  transform
  absolute
  ${(props) => (props.$isVisible ? 'translate-y-0' : '-translate-y-36')}
`

export const Notification = tw.div`
  absolute
  -left-2
  -top-2
  bg-red-500
  text-white
  flex
  items-center
  justify-center
  rounded-full
  lg:h-6
  lg:w-6
  h-5
  w-5
`
