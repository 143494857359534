import React from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Category } from '..'
import { ICategory } from '../../@types/category'

interface CategoriesProps {
  categories: ICategory[]
}

SwiperCore.use([Pagination, Navigation, Scrollbar])

const Categories = ({ categories }: CategoriesProps) => {
  return (
    <div className="w-full darkSwiperPagination bg-secondaryGray">
      <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
        <div className="flex items-center mb-4 space-x-2 lg:mb-6">
          <img
            src="/images/category.svg"
            alt="category icon"
            className="w-4 lg:w-6"
          />
          <h2 className="text-xl font-bold text-primaryDark lg:text-2xl">
            Shop by Category
          </h2>
        </div>

        <div className="darkSwiperPagination">
          <Swiper
            slidesPerView={3}
            slidesPerColumn={3}
            slidesPerGroup={3}
            spaceBetween={5}
            slidesPerColumnFill="row"
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
                slidesPerGroup: 12,
                slidesPerColumn: 2,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 12,
                slidesPerColumn: 2,
              },
            }}
            pagination
          >
            {categories.map((category) => (
              <SwiperSlide className="pb-4" key={category.cate_id}>
                <Category {...category} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Categories
