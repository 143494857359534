import React from 'react'
import { ChevronRightSolid } from '@graywolfai/react-heroicons'
import parse, {
  domToReact,
  DOMNode,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { Element } from 'domhandler/lib/node'

import {
  InnerContainer,
  H1Primary,
  H3SecondaryDark,
  PSecondaryDark,
  HrGray,
} from '../../GlobalStyles'
import {
  Image,
  Ol,
} from '../productFullDescription/ProductFullDescription.styles'
import { Ul, Li } from '../productDetailSection/ProductPage.styles'
import { BlogsContainer } from '../categoryBlogs/CategoryBlogs.styles'
import {
  Container,
  CoverImage,
  TagsContainer,
  BlogTagsContainer,
  BlogTag,
} from './ArticlePage.styles'

import { IBlog } from '../../@types/blogs'

import { BreadCrumb } from '../breadCrumbs/BreadCrumbs.styles'

const TAG_TEXT = 'แท็ก :'
const BLOGS_HEADING = 'บทความที่เกี่ยวข้อง'

/*
 * @param domNode of type DOMNode (p, h1, div, ...)
 * @returns parsed react element
 *
 * takes in a html element and replaces certain onces like p, h1, and img with react
 * elements
 *
 * ex: <p>Text</p> will return <PSecondaryDark>Text</PSecondaryDark>
 */
function replaceElement(domNode: DOMNode): React.ReactElement | Element | null {
  if (domNode instanceof Element) {
    switch (domNode.name) {
      case 'p':
        if (
          domNode.children &&
          domNode.children[0] instanceof Element &&
          domNode.children[0].name === 'img'
        ) {
          return <Image src={domNode.children[0].attribs.src} />
        }
        return <PSecondaryDark>{domToReact(domNode.children)}</PSecondaryDark>

      case 'img':
        return <Image src={domNode.attribs.src ?? ''} />

      case 'ul' || 'ol':
        return <Ul>{domToReact(domNode.children)}</Ul>

      case 'li':
        return <Li>{domToReact(domNode.children)}</Li>

      case 'ol':
        return (
          <Ol style={{ listStyle: 'decimal' }}>
            {domToReact(domNode.children)}
          </Ol>
        )

      case 'iframe':
        return (
          <iframe
            src={domNode.attribs.src ?? ''}
            className="w-full mx-auto lg:w-3/4 lg:h-500 h-200"
            title="Embeded elements"
          />
        )

      default:
        return <H3SecondaryDark>{domToReact(domNode.children)}</H3SecondaryDark>
    }
  }

  return null
}

interface ArticlePageProps {
  blog: IBlog
}

const ArticlePage = ({ blog }: ArticlePageProps) => {
  const parserOptions: HTMLReactParserOptions = {
    replace: replaceElement,
  }

  return (
    <div className="container mx-auto max-w-7xl">
      <div className="items-center hidden my-4 space-x-4 lg:flex">
        <BreadCrumb href="/">LCS</BreadCrumb>
        <ChevronRightSolid className="w-4 h-4 text-primaryDark" />
        <BreadCrumb href="/blogs">Blogs</BreadCrumb>
        <ChevronRightSolid className="w-4 h-4 text-primaryDark" />
        <BreadCrumb>{blog.title}</BreadCrumb>
      </div>

      <InnerContainer>
        <div className="w-full aspect-w-2 aspect-h-1">
          <img
            className="w-full h-full"
            src={blog.share_image}
            alt={blog.title}
          />
        </div>
        <H1Primary>{blog.title}</H1Primary>
        {parse(blog.description, parserOptions)}
        <HrGray />

        {blog.meta_keywords && (
          <TagsContainer>
            <PSecondaryDark>{TAG_TEXT}</PSecondaryDark>
            <BlogTagsContainer>
              {blog.meta_keywords.split(' ').map((tag, index) => (
                <BlogTag key={tag + String(index)}>{tag}</BlogTag>
              ))}
            </BlogTagsContainer>
          </TagsContainer>
        )}

        <H1Primary>{BLOGS_HEADING}</H1Primary>
        <BlogsContainer />
      </InnerContainer>
    </div>
  )
}

export default ArticlePage
