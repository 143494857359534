import tw from 'tailwind-styled-components'

export const SelectEl = tw.select`
  cursor-pointer
  text-left
  border
  border-gray-300
  py-2
  rounded-md
  focus:outline-none
`

export const Option = tw.option`
  w-full
  px-4
  py-2
  border-t
  border-gray-200
`
