import tw from 'tailwind-styled-components'

export const Container = tw.div`
  flex
  justify-start
  lg:justify-between
  items-center
  cursor-pointer
  border-b-2
  border-gray-100
  lg:border-b-0
  py-0
  lg:py-3
`

export const IconContainer = tw.div`
  flex 
  justify-center 
  items-center
  p-2
  ml-4
  w-10
  lg:w-8
  lg:ml-0
  lg:px-0
  lg:py-0
`

export const Icon = tw.img`
  object-contain
`

export const Text = tw.p<{ $textColor: string }>`
  text-xs
  ${(props) => `lg:text-${props.$textColor}`}
  text-primaryDark
  ml-2
`
