import tw from 'tailwind-styled-components'

interface OverlayProps {
  $isVisible: boolean
  $isHidden: boolean
}

export const Container = tw.div<{ $isHidden: boolean }>`
  ${(props) => (props.$isHidden ? 'hidden' : 'flex')}
  flex-col
  rounded-md
  shadow-primary-box-shadow
  border-primary
  absolute
  top-24
  bottom-0
  inset-x-2
  bg-white
  lg:static
  lg:flex
  w-11/12
  mx-auto
  lg:w-1/4
  z-10
  lg:z-0
  h-fit
`

export const Overlay = tw.div<OverlayProps>`
  cursor-default
  ${(props) => (props.$isHidden ? 'hidden' : 'fixed')}
  lg:hidden
  inset-0
  top-10
  bg-overlay
  transition
  ease-in-out
  duration-300
  ${(props) => (props.$isVisible ? 'opacity-100' : 'opacity-0')}
`

export const Header = tw.div`
  bg-primary
  text-white
  text-md
  font-bold
  flex
  items-center
  justify-between
  lg:justify-center
  shadow-primary-box-shadow
  rounded-t-md
  border-primary
  px-3
  py-4
`

export const CloseIconContainer = tw.div`
  cursor-pointer
  w-8
  lg:hidden
`

export const Icon = tw.img`
  object-contain
`

export const FilterColumnBody = tw.div`
  w-full
  px-4
  py-4
`

export const FilterItem = tw.div`
  py-4
  flex
  flex-col
  space-y-2
  border-b-2
  border-gray-100
`

export const FilterButton = tw.button`
  w-full
  text-white
  font-bold
  bg-primary
  py-2
  rounded-md
  hover:bg-primaryDark
`

export const CheckboxGroupContainer = tw.div`
  space-y-2
`
