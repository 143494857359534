import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
// import { ChevronRightIcon } from '@heroicons/react/solid'
import * as React from 'react'
import { IGridMenu, INavLink, NavLanguage } from '../../@types/nav'
import { menus } from '../../constants/menus'
import { useLanguage } from '../../hooks'
import { LanguageSelector } from './LanguageSelector'
// import { GridMenus, Menus, PrimaryNavLinks } from '../utils/constant'
import { SubDrawerMenu } from './SubDrawerMenu'

interface DrawerMenuProps {
  open: boolean
  onClose: () => void
  primaryNavLinks: INavLink[]
  gridLinks: IGridMenu[]
  language: NavLanguage
  onLanguageChange: (lang: NavLanguage) => void
}

export const DrawerMenu = ({
  open = false,
  primaryNavLinks,
  gridLinks,
  language,
  onClose,
  onLanguageChange,
}: DrawerMenuProps) => {
  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={() => null}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="absolute w-screen h-screen transition-opacity bg-gray-500 bg-opacity-75 top-top-nav"
              onClick={onClose}
            />
          </Transition.Child>
          <div className="fixed left-0 flex h-screen max-w-full top-top-nav">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed right-0 flex p-2 top-top-nav">
                    <button
                      type="button"
                      className="text-gray-300 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon
                        className="w-8 h-8 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col w-64 h-full overflow-y-auto bg-white shadow-xl">
                  <div className="relative border-b divide-y">
                    {primaryNavLinks.map((menu) => (
                      <div
                        key={menu.name_en}
                        className="px-4 py-3 text-xs text-primaryDark"
                      >
                        <a href={menu.url}>{menu?.[language]}</a>
                      </div>
                    ))}

                    {gridLinks.map((menu) => (
                      <SubDrawerMenu
                        key={menu.cate_name_en}
                        menu={menu}
                        language={language}
                      />
                    ))}

                    {menus.map((menu) => (
                      <a
                        href={menu.url}
                        key={menu.name_en}
                        className="flex justify-between px-4 py-3 text-xs text-primaryDark"
                      >
                        <div className="flex space-x-2">
                          <img
                            src={menu.icon_url}
                            className="w-4 h-4 brightness-50"
                            alt={menu.name_en}
                          />
                          <div>{menu?.[language]}</div>
                        </div>
                      </a>
                    ))}
                  </div>

                  <div className="px-4 py-6">
                    <LanguageSelector
                      language={language}
                      onLanguageChange={onLanguageChange}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
