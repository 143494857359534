import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Outer = tw.div`
  w-full
  bg-primary
`

export const Container = tw(DefaultContainer)`
  py-10
  space-y-10
`

export const Link = tw.a`
  text-white
  no-underline
  cursor-pointer
`

export const SmallImageContainer = tw.div`
  w-6
`

export const ImageContainer = tw.div`
  w-8
`

export const Image = tw.img`
  object-contain  
`
