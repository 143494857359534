import tw from 'tailwind-styled-components'
/*
 * this container can be extended to have other styles
 * ex:
 * const RedContainer = tw(DefaultContainer)`
 *   bg-red-500
 * `
 */
const DefaultContainer = tw.div`
  w-full
  px-2
  lg:px-0
  lg:w-containerWidth
  mx-auto
`

export default DefaultContainer
