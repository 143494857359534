import { HeartIcon, MenuIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { IGridMenu, INavLink } from '../../@types/nav'
import { useLanguage } from '../../hooks'
import LoginModal from '../LoginModal'
import { CartButton } from './CartButton'
import { DrawerMenu } from './DrawerMenu'
import { LanguageSelector } from './LanguageSelector'
import { Menubar } from './Menubar'
import SearchBar from './SearchBar'
import { SearchDropdown } from './SearchDropdown'
import { USPMenubar } from './USPMenubar'

interface PrimaryNavbarProps {
  primaryNavLinks: INavLink[]
  gridLinks: IGridMenu[]
  itemsInCart: number
}

const PrimaryNavbar = ({
  primaryNavLinks,
  gridLinks,
  itemsInCart,
}: PrimaryNavbarProps) => {
  const [open, setOpen] = React.useState(false)
  const { language, changeLanguage } = useLanguage()

  return (
    <div className="sticky top-0 z-40">
      <nav className="z-40 text-white bg-gradient-to-r from-primaryDark to-gradientLight">
        <div className="container flex items-center justify-between h-full p-4 mx-auto max-w-7xl">
          <div className="flex items-center space-x-6">
            <MenuIcon
              className="w-6 h-6 cursor-pointer lg:hidden"
              onClick={() => setOpen(!open)}
            />
            <DrawerMenu
              open={open}
              onClose={() => setOpen(false)}
              primaryNavLinks={primaryNavLinks}
              gridLinks={gridLinks}
              language={language}
              onLanguageChange={changeLanguage}
            />

            <a href="/">
              <div>
                <img
                  src="/images/navbar_logo.svg"
                  className="cursor-pointer w-28 lg:w-32"
                  alt="LCS logo"
                />
              </div>
            </a>

            <div className="hidden space-x-6 lg:flex">
              {primaryNavLinks.map((link) => (
                <a href={link.url} key={link.name_en}>
                  {link?.[language]}
                </a>
              ))}
            </div>
          </div>

          <div className="items-center justify-center flex-1 hidden w-full px-6 text-center lg:block">
            <SearchBar language={language} />
          </div>

          <div className="flex items-center space-x-4 lg:space-x-6">
            <a href="/favorites">
              <HeartIcon className="hidden w-8 h-8 text-white cursor-pointer lg:block" />
            </a>
            <div className="lg:hidden">
              <SearchDropdown language={language} />
            </div>
            <CartButton itemsInCart={itemsInCart ?? 0} />
            <div className="items-center hidden lg:flex">
              <LanguageSelector
                language={language}
                onLanguageChange={changeLanguage}
              />
            </div>
            <div className="cursor-pointer">
              <LoginModal />
            </div>
          </div>
        </div>
      </nav>
      <Menubar gridLinks={gridLinks} language={language} />
      <div className="hidden lg:block">
        <USPMenubar language={language} />
      </div>
    </div>
  )
}

export default PrimaryNavbar
