import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Outer = tw.div`
  w-full
  bg-secondaryGray
`

export const Container = tw(DefaultContainer)`
  py-6
  lg:py-12
`

export const Grid = tw.div`
  flex
  flex-col
  lg:grid
  lg:grid-cols-5
`

export const FooterElement = tw.div`
  mb-3
  flex
  flex-col
`

export const Expandable = tw.div<{ $isVisible: boolean }>`
  flex-col
  ${(props) => (props.$isVisible ? 'flex' : 'hidden')}
`

export const MenuHeaderContainer = tw.div`
  mb-3
  flex
  justify-between
  items-center
  pr-2
  cursor-pointer
  lg:cursor-default
`

export const DownIconContainer = tw.div`
  w-10
  lg:hidden
`

export const DownIcon = tw.img`
  object-contain
`

export const LogoContainer = tw.div`
  hidden
  lg:block
  w-40
  mb-6
`

export const PaymentImageContainer = tw.div`
  w-10
  lg:w-14
  lg:mr-6
`

export const ImageContainer = tw.div`
  w-16
  lg:w-20
  mr-6
`

export const SocialImageContainer = tw.div`
  w-10
`

export const Image = tw.img`
  mr-8
  mb-4
  object-contain
`

export const BankIconsGrid = tw.div`
  w-3/4
  lg:w-full
  grid
  grid-cols-6
  grid-row-1
  lg:grid-cols-4
  lg:grid-row-2
`

export const DeliveryIconsGrid = tw.div`
  grid
  grid-cols-4
  grid-row-2
  lg:grid-cols-3
  lg:grid-row-2
`

export const FooterIconsContainer = tw.div`
  flex
  flex-wrap
  py-2
`

export const SocialIconsContainer = tw(FooterIconsContainer)`
  lg:justify-between
  space-x-2
  lg:space-x-0
  w-1/2
`

export const LinkText = tw.a`
  no-underline
  text-sm
  text-primaryDark
  mb-2
  ml-4
  lg:ml-0
`

export const HeaderContainer = tw.div`
  mb-3
`

export const CopyrightContainer = tw.div`
  flex
  flex-col
  lg:flex-row
  justify-between
  py-2
`

export const CopyRightText = tw.p`
  mb-2
  text-sm
  text-primaryDark
  font-light
`
