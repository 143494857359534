import tw from 'tailwind-styled-components'

export const Container = tw.div`
  w-full
  bg-gray-200
  px-3
  py-4
  rounded-md
  flex
  flex-col
  hidden
  md:block
  space-y-1
`

export const TagText = tw.p`
  text-sm
  text-secondaryGrayDark
`
