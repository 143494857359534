import tw from 'tailwind-styled-components'

export const Container = tw.div<{ $isFlexCol: boolean }>`
  flex
  ${(props) => (props.$isFlexCol ? 'flex-col' : '')}
  ${(props) => (props.$isFlexCol ? 'space-y-2' : 'space-x-4')}
  ${(props) => (props.$isFlexCol ? '' : 'items-center')}
  w-full
`

export const Label = tw.label<{ $isLabelBold: boolean }>`
  ${(props) => (props.$isLabelBold ? 'text-primaryDark' : 'text-primary')}
  ${(props) => (props.$isLabelBold ? 'font-bold' : 'font-semibold')}
`

export const RequiredSpan = tw.span`
  text-red-500
  text-xs
  align-top
`

export const InputElement = tw.input<{ $hasError: boolean }>`
  flex-grow
  border
  border-gray-300
  px-4
  py-2
  rounded-md
  text-secondaryGrayDark
  focus:outline-none
  ${(props) => (props.$hasError ? 'border-red-500' : '')}
`

export const ErrorText = tw.label`
  text-red-500
  text-xs
`
