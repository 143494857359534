import * as React from 'react'
import { INavLink, NavLanguage } from '../../@types/nav'
import { subMenus } from '../../constants/menus'
// import { INavLink, USPMenus } from "../utils/constant";

interface USPItemProps {
  menu: INavLink
  language: NavLanguage
}

const USPItem = ({ menu, language }: USPItemProps) => {
  return (
    <div className="flex flex-col items-center space-y-1 text-xs text-center lg:flex-row lg:items-center lg:space-y-0 lg:space-x-2">
      <div className="flex items-center justify-center w-12 h-12 text-center rounded-full bg-primary lg:bg-white lg:p-0 lg:w-auto">
        <img
          src={menu.icon_url}
          className="w-6 h-6 brightness-0 invert filter lg:h-4 lg:w-4 lg:filter-none"
          alt={menu.name_en}
        />
      </div>
      <div>{menu?.[language]}</div>
    </div>
  )
}

interface USPMenubarProps {
  language: NavLanguage
}

export const USPMenubar = ({ language }: USPMenubarProps) => {
  return (
    <div className="w-full px-2 py-4 bg-white lg:px-4 lg:py-2 text-primary">
      <div className="container flex justify-between max-w-6xl mx-auto lg:justify-evenly">
        {subMenus.map((menu) => (
          <a className="flex-1 lg:flex-none" href={menu.url} key={menu.name_th}>
            <USPItem menu={menu} language={language} />
          </a>
        ))}
      </div>
    </div>
  )
}
