import React from 'react'

import {
  Container,
  Item,
  ImageContainer,
  Image,
} from './SubCategoriesGrid.styles'

import { ISubCategories } from '../../@types/subcategories'

interface SubCategoriesGridProps {
  subCategories: Array<ISubCategories>
}

const SubCategoriesGrid = ({ subCategories }: SubCategoriesGridProps) => {
  const handleClick = (permalink: string) => {
    const splitEndpoints = permalink.split('/').filter((_, index) => index > 0)

    const updatedPermaLink = splitEndpoints.join('/')

    window.location.assign(updatedPermaLink || '')
  }

  return (
    <Container>
      {subCategories.map((subCategory) => (
        <Item
          key={subCategory.id}
          onClick={() => handleClick(subCategory.permalink)}
        >
          <ImageContainer>
            <Image
              src={
                /*
                 * as some images are just names and not the url for uploaded images
                 */
                (subCategory.icon.includes('amazonaws.com') &&
                  subCategory.icon) ||
                '/images/lcsCategoryPlaceholder.png'
              }
            />
          </ImageContainer>
          <h2 className="text-sm text-center text-primaryDark">
            {subCategory.name}
          </h2>
        </Item>
      ))}
    </Container>
  )
}

export default SubCategoriesGrid
