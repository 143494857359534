interface IIcon {
  id: string
  iconSrc: string
  altText: string
}

interface ILinkIcon {
  id: string
  iconSrc: string
  altText: string
  linkTo: string
}

export const SOCIAL_ICONS: Array<ILinkIcon> = [
  {
    id: 'fb-00',
    iconSrc: '/images/lcsFblogo.png',
    altText: 'lcs facebook logo',
    linkTo: 'https://www.facebook.com/lcsgroupthailand',
  },
  {
    id: 'line-00',
    iconSrc: '/images/lcsLineLogo.png',
    altText: 'lcs line logo',
    linkTo: 'https://lin.ee/C15o2A0',
  },
  {
    id: 'youtube-00',
    iconSrc: '/images/lcsYoutubeLogo.png',
    altText: 'lcs youtube logo',
    linkTo: 'https://www.youtube.com/channel/UCuwK83RROEgr4zUxMNIaVcw',
  },
]

export const PAYMENT_ICONS: Array<IIcon> = [
  {
    id: 'visa-00',
    iconSrc: '/images/lcsVisaLogo.png',
    altText: 'lcs visa logo',
  },
  {
    id: 'mastercard-00',
    iconSrc: '/images/lcsMastercardLogo.png',
    altText: 'lcs mastercard logo',
  },
  {
    id: 'kbank-00',
    iconSrc: '/images/lcsKasikornBank.png',
    altText: 'lcs kasikorn bank logo',
  },
  {
    id: 'scb-00',
    iconSrc: '/images/lcsScbLogo.png',
    altText: 'lcs scb logo',
  },
  {
    id: 'kma-00',
    iconSrc: '/images/lcsKmaLogo.png',
    altText: 'lcs kma bank logo',
  },
  {
    id: 'tmb-00',
    iconSrc: '/images/lcsTmbLogo.png',
    altText: 'lcs tmb bank logo',
  },
]

export const DELIVERY_ICONS: Array<IIcon> = [
  {
    id: 'kerry-00',
    iconSrc: '/images/lcsKerryLogo.png',
    altText: 'lcs kerry logo',
  },
  {
    id: 'jandt-00',
    iconSrc: '/images/lcsJ&TLogo.png',
    altText: 'lcs j&t logo',
  },
  {
    id: 'scg-00',
    iconSrc: '/images/lcsSCGLogo.png',
    altText: 'lcs scg logo',
  },
  {
    id: 'nim-00',
    iconSrc: '/images/lcsNimExpressLogo.png',
    altText: 'lcs nim express logo',
  },
  {
    id: 'flash-00',
    iconSrc: '/images/lcsFlashExpressLogo.png',
    altText: 'lcs flash express logo',
  },
  {
    id: 'lcs-00',
    iconSrc: '/images/lcsDeliveryLogo.png',
    altText: 'lcs delivery logo',
  },
]
