import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  space-y-6
  lg:space-y-8
  mb-6
  lg:mb-6
`

export const Inner = tw.div`
  space-y-4
`

export const Flex = tw.div`
  flex
  space-x-4
  items-center
  justify-between
  lg:justify-start
`

export const InfoContainer = tw.div`
  flex
  flex-col
  space-y-2
  px-4
  lg:px-6
  py-4
  bg-lightBlue
  rounded-md
`

export const Row = tw.div`
  flex
  items-center
  space-x-4
`
