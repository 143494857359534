import React from 'react'
import { ChevronRightSolid } from '@graywolfai/react-heroicons'

import { Container, BreadCrumb } from './BreadCrumbs.styles'

interface BreadCrumbsProps {
  breadCrumb: string
}

const BreadCrumbs = ({ breadCrumb }: BreadCrumbsProps) => {
  return (
    <Container>
      <BreadCrumb href="/">LCS</BreadCrumb>
      <ChevronRightSolid className="w-4 h-4 text-primaryDark" />
      <BreadCrumb>{breadCrumb}</BreadCrumb>
    </Container>
  )
}

export default BreadCrumbs
