import { SearchIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { NavLanguage } from '../../@types/nav'

interface SearchBarProps {
  language: NavLanguage
}

interface ISearchForm {
  searchTerm: string
}

function getCurrentKeyword(): string {
  const queryString = window.location.search

  const urlParams = new URLSearchParams(queryString)
  const keywordsParam = urlParams.get('keywords') || ''

  // remove `"` from string as the parmam is stringified below
  return keywordsParam.replace(/"/g, '')
}

const SearchBar = ({ language }: SearchBarProps) => {
  const { register, handleSubmit } = useForm<ISearchForm>({
    defaultValues: { searchTerm: getCurrentKeyword() },
  })
  const onSubmit = (data: ISearchForm) =>
    window.location.replace(`/t/categories?keywords=${data.searchTerm.trim()}`)

  return (
    <div className="relative">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          className="w-full pl-12 pr-4 text-sm text-gray-900 bg-white rounded-lg placeholder-gradientLight focus:outline-none"
          placeholder={
            language === 'name_th' ? 'ค้นหาสินค้าที่ต้องการที่นี่' : 'Search'
          }
          {...register('searchTerm', { required: true })}
        />

        <div className="absolute top-0 flex items-center justify-center w-5 h-full ml-4">
          <SearchIcon className="w-5 h-5 text-gray-400" />
        </div>
      </form>
    </div>
  )
}

export default SearchBar
