import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
// components
import { DealCard } from '..'
// import card type for creating new items interface
import { ICardItem } from '../../@types/cards'

const RIGHT_LINK_TEXT = 'ดูทั้งหมด'
const RIGHT_LINK = '/t/categories?new_items=true'

interface NewItemsProps {
  newItems: Array<ICardItem>
}

const NewItems = ({ newItems = [] }: NewItemsProps) => {
  return (
    <div className="container px-2 py-6 mx-auto max-w-7xl md:px-4 lg:py-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <img
            src="/images/new_item.svg"
            alt="new items icon"
            className="w-4 lg:w-6"
          />
          <h2 className="text-xl font-bold text-primaryDark lg:text-2xl">
            New Items
          </h2>
        </div>

        <a
          href={RIGHT_LINK}
          className="flex items-center text-sm lg:text-base text-primaryDark"
        >
          <span>{RIGHT_LINK_TEXT}</span>
          <ChevronRightIcon className="w-4 lg:w-6" />
        </a>
      </div>

      <div className="mt-4 lg:mt-6">
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          scrollbar={{ draggable: true }}
          breakpoints={{
            768: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 16,
            },
          }}
          navigation
        >
          {newItems.map((item) => (
            <SwiperSlide className="py-2" key={item.id}>
              <DealCard {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* <div className="flashCardsSwiper">
        <SwiperEl sliders={swiperSlidersJSX} />
      </div> */}
    </div>
  )
}

export default NewItems
