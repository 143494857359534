import React from 'react'

import { Container } from './NoProductsMessage.styles'

interface NoProductsMessageProps {
  message: string
  isWhite?: boolean
}

const NoProductsMessage = ({ message, isWhite = false }: NoProductsMessageProps) => (
  <Container className={isWhite ? 'bg-white' : 'bg-secondaryGray'}>{message}</Container>
)

export default NoProductsMessage
