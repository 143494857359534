import { SearchIcon } from '@heroicons/react/outline'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Input, InputContainer, InputIconContainer } from './Search.styles'

const PLACEHOLDER_TEXT = 'ค้นหาสินค้าที่ต้องการที่นี่'

interface ISearchForm {
  searchTerm: string
}

/*
 * Gets default value for the search term to be filled if there is one
 *
 * ex: http://localhost:3000/t/categories?keywords="test" returns test
 */
function getCurrentKeyword(): string {
  const queryString = window.location.search

  const urlParams = new URLSearchParams(queryString)
  const keywordsParam = urlParams.get('keywords') || ''

  // remove `"` from string as the parmam is stringified below
  return keywordsParam.replace(/"/g, '')
}

const Search: React.FC = () => {
  const { register, handleSubmit } = useForm<ISearchForm>({
    defaultValues: { searchTerm: getCurrentKeyword() },
  })
  const onSubmit = (data: ISearchForm) =>
    window.location.replace(
      // keyword is "stringified"
      `/t/categories?keywords=${data.searchTerm.trim()}`
    )

  return (
    <InputContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder={PLACEHOLDER_TEXT}
          {...register('searchTerm', { required: true })}
        />
        <InputIconContainer>
          <SearchIcon className="w-6 h-6 text-gray-400" />
        </InputIconContainer>
      </form>
    </InputContainer>
  )
}

export default Search
