import React from 'react'
import replace from 'lodash/replace'
import kebabCase from 'lodash/kebabCase'

import { LinkWithIcon, GridMenu } from '..'
import { Container } from './SecondaryNav.styles'
import { INavLinkWithIcon, IGridMenu } from '../../@types/nav'

/* ---------------------------------------------------------------------------------------
 * Helper Functions
 * ---------------------------------------------------------------------------------------
 *
 *
 * @param url; icon url to switch normal icon to the blue icon for mobile
 * @returns url; for blue icon
 *
 * takes a url for a icon and returns the blue icon url for the same
 * ex: url: /images/lcsTruck.png -> /images/lcsTruckBlue.png
 */
function getBlueIconUrl(url: string): string {
  const [urlString] = url.split('.')

  return `/${urlString}Blue.png`
}

/*
 * @param name of the category
 * @return url of type category-name
 *
 * URL's for categories are their names but with special characters and
 * spaces replaced by '-' and prefixes the string with /t/categories
 */
function createURLFromName(name: string): string {
  const removeAmp = replace(name, '&', ' ')

  return `/t/categories/${kebabCase(removeAmp)}`
}

interface SecondaryNavProps {
  isMobileWidth: boolean
  isVisible: boolean
  gridMenu: Array<IGridMenu>
  secondaryNavLinks: Array<INavLinkWithIcon>
  currentLanguage: 'name_th' | 'name_en'
}

/*
 * the prop isVisible is used to make the secondaryNav hidden in mobile view
 * the second copy of secondary nav (the one used for mobile menu) will be marked
 * the opposite value
 */
const SecondaryNav = ({
  isVisible,
  isMobileWidth,
  gridMenu,
  secondaryNavLinks,
  currentLanguage,
}: SecondaryNavProps) => {
  const subCatVariableName =
    currentLanguage === 'name_en' ? 'cate_name_en' : 'cate_name_th'
  return (
    <div className="lg:bg-primaryDark">
      <Container $isVisible={isVisible}>
        {
          /*
           * Items with grid menus (first two items)
           */
          gridMenu.map((menu) => (
            <GridMenu
              key={menu.cate_name_en}
              isMobileWidth={isMobileWidth}
              innerText={menu[subCatVariableName]}
              /*
               * in mobile width the icons are blue
               */
              iconSrc={
                isMobileWidth
                  ? getBlueIconUrl(menu.icon_url)
                  : `/${menu.icon_url}`
              }
              menuItems={menu.sub_cate_lv1}
              currentLanguage={currentLanguage}
            />
          ))
        }

        {secondaryNavLinks.map((item) => (
          <LinkWithIcon
            key={item.name_en}
            innerText={item[currentLanguage]}
            iconSrc={
              isMobileWidth
                ? getBlueIconUrl(item.icon_url)
                : `/${item.icon_url}`
            }
            url={`${createURLFromName(item.name_en.toLowerCase())}`}
          />
        ))}
      </Container>
    </div>
  )
}

export default SecondaryNav
