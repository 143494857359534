import { Popover } from '@headlessui/react'
import * as React from 'react'
import { NavLanguage } from '../../@types/nav'

interface LanguageSelectorProps {
  language: NavLanguage
  onLanguageChange: (lang: NavLanguage) => void
}

export const LanguageSelector = ({
  language,
  onLanguageChange,
}: LanguageSelectorProps) => {
  return (
    <Popover className="relative flex items-center">
      <Popover.Button>
        <img
          src={
            language === 'name_th' ? '/images/thailand.svg' : '/images/uk.svg'
          }
          className="w-8 h-8 cursor-pointer"
          alt="Thailand"
        />
      </Popover.Button>

      <Popover.Panel className="absolute top-0 w-20 text-gray-900 bg-white shadow lg:-left-5 lg:top-9 left-4">
        {({ close }) => (
          <>
            <button
              type="button"
              className="w-full px-4 py-2 text-sm text-center cursor-pointer hover:bg-gradientLight hover:text-white"
              onClick={() => {
                onLanguageChange('name_th')
                close()
              }}
            >
              ไทย
            </button>
            <button
              type="button"
              className="w-full px-4 py-2 text-sm text-center cursor-pointer hover:bg-gradientLight hover:text-white"
              onClick={() => {
                onLanguageChange('name_en')
                close()
              }}
            >
              English
            </button>
          </>
        )}
      </Popover.Panel>
    </Popover>
  )
}
