import tw from 'tailwind-styled-components'

export const DeliveryHeading = tw.div`
  p-3
  bg-gray-200
  text-secondaryGrayDark
  rounded-md
  w-full
  text-center
`

export const DeliveryOptionsContainer = tw.div<{ $isSelected: boolean }>`
  rounded-md
  border-2
  ${(props) => (props.$isSelected ? 'border-primary' : 'border-gray-300')}
  flex
  flex-col
  space-y-6
  justify-between
  lg:flex-row
  lg:items-center
  lg:space-y-0
  px-6
  py-4
  cursor-pointer
  hover:border-primary
`
