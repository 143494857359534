import { ChevronRightIcon } from '@heroicons/react/solid'
import * as React from 'react'
import { ISubCategoryLvl2, NavLanguage } from '../../@types/nav'

interface GridMenuItemProps {
  menu: ISubCategoryLvl2
  language: NavLanguage
}

export const GridMenuItem = ({ menu, language }: GridMenuItemProps) => {
  return (
    <div className="col-span-1 text-gray-900">
      <a
        href={menu.link}
        className="flex items-center justify-between px-4 py-2 text-sm font-bold rounded-md bg-blue-50 text-primary"
      >
        {menu?.[language]} <ChevronRightIcon className="w-6 h-6" />
      </a>
      <div className="flex flex-col px-4 py-2 space-y-2">
        {menu.sub_cate_lv2.map((subCat) => (
          <a
            href={subCat.link}
            key={subCat.name_en}
            className="text-sm line-clamp-2"
          >
            {subCat?.[language]}
          </a>
        ))}
      </div>
    </div>
  )
}
