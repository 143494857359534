import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Blog } from '..'
import { IBlog } from '../../@types/blogs'
import { MOBILE_WIDTH } from '../../constants/windowSize'
import { H2PrimaryDark } from '../../GlobalStyles'
import { useWindowSize } from '../../hooks/useWindowSize'
import { LinkContainer } from '../BlogSection/BlogSection.styles'
import { Link, SmallImageContainer } from '../flashSales/FlashSales.styles'
import {
  BlogsContainer,
  Container,
  HeaderContainer,
} from './CategoryBlogs.styles'

const ALL_BLOGS_TEXT = 'ดูทั้งหมด'

interface BlogSectionProps {
  blogs: Array<IBlog>
}

const CategoryBlogs = ({ blogs }: BlogSectionProps) => {
  const isMobileWidth = (useWindowSize().width || 0) <= MOBILE_WIDTH
  return (
    <Container>
      <HeaderContainer>
        <H2PrimaryDark>Blog</H2PrimaryDark>

        <LinkContainer>
          <Link href="/blogs">{ALL_BLOGS_TEXT}</Link>
          <SmallImageContainer>
            <ChevronRightIcon className="w-8 h-8 text-primary" />
          </SmallImageContainer>
        </LinkContainer>
      </HeaderContainer>

      <BlogsContainer>
        {blogs &&
          blogs
            .slice(0, 3)
            .map((blog) => (
              <Blog
                key={blog.id}
                {...blog}
                blogLocation={isMobileWidth ? 'home' : 'categoryPage'}
              />
            ))}
      </BlogsContainer>
    </Container>
  )
}

export default CategoryBlogs
