import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Transition } from '@headlessui/react'

// other components
import { Input, Select, CheckoutPriceSection, CheckboxRegular } from '..'
// styles
import {
  Container,
  FormContainer,
  ContactInfoContainer,
  DeliveryFormContainer,
  DeliveryHeaderContainer,
  FormikContainer,
  StyledForm,
} from './CheckoutAddress.styles'
// types
import { ILocationData, ICountry } from '../../@types/formData'
import { getCSRFToken } from '../../helpers/utils'

/*
 * Costants
 */
import {
  CHECKOUT_ADDRESS_INPUTS,
  initialAddressValues,
} from '../../constants/checkoutFormFields'

const EMAIL_TEXT = 'อีเมล'
const MOBILE_TEXT = 'เบอร์มือถือ'
const DELIVERY_FORM_HEADER = 'ที่อยู่ในการจัดส่ง'
const TAX_INVOICE_HEADER = 'ใบกำกับภาษี'
const TAX_INVOICE_TEXT = 'ต้องการใบกำกับภาษีเต็มรูปแบบ'
const BILLING_ADDRESS_HEADER = 'ที่อยู่ในการวางบิล'
const BILLING_ADDRESS_TEXT = 'ใช้ที่อยู่ในการจัดส่ง'
const TAX_ID_TEXT = 'เลขประจำตัวผู้เสียภาษี / เลขที่บัตรประจำตัวประชาชน'
const COMPANY_NAME_TEXT = 'บริษัท / ชื่อผู้เสียภาษี'
const STATE_HEADING = 'จังหวัด'
const ZIPCODE_HEADING = 'รหัสไปรษณีย์'
const DISTRICT_HEADING = 'อำเภอ'
const SUB_DISTRICT_HEADING = 'ตำบล'
const COUNTRY_HEADING = 'ประเทศ'
const ALTERNATIVE_CONTACT_HEADING = 'เบอร์ติดต่อสำรอง'
const API_URL = '/checkout/update/address'

function createObjectAttributes(values: any, data: FormData): FormData {
  Object.keys(initialAddressValues).forEach((key) => {
    data.append(`order[bill_address_attributes][${key}]`, values[key])
  })

  if (!values.isBillingAddressSame) {
    Object.keys(initialAddressValues).forEach((key) => {
      data.append(`order[ship_address_attributes][${key}]`, values[`b_${key}`])
    })
  }

  data.append('order[use_billing]', values.isBillingAddressSame)
  data.append('order[tax_invoice]', values.isTaxRecieptRequired)
  data.append('order[email]', values.email)
  data.append('order[bill_address_attributes][phone]', values.phone)
  data.append('order[bill_address_attributes][taxpayer]', values.taxpayer ?? '')
  data.append(
    'order[bill_address_attributes][taxpayer_id]',
    values.taxpayer_id ?? ''
  )

  return data
}

function submitForm(values: any, formAuthToken: string) {
  const data = new FormData()

  data.append('authenticity_token', formAuthToken)

  const order = createObjectAttributes(values, data)

  // get csrf token
  const csrfToken = getCSRFToken()

  fetch(API_URL, {
    method: 'PATCH',
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    },
    body: order,
  })
    .then(() => window.location.replace('delivery'))
    .catch((error) => alert(error))
}

function changeSavedBillAddressKeys(savedBillAddress: any) {
  return Object.keys(savedBillAddress).reduce((acc: any, key) => {
    const isNullValue =
      savedBillAddress[key] === 'null' || savedBillAddress[key] === null
    acc[`b_${key}`] = !isNullValue ? savedBillAddress[key] : ''

    return acc
  }, {})
}

function removeNullValue(obj: any) {
  return Object.keys(obj).reduce((acc: any, curr: any) => {
    const isNullValue = obj[curr] === 'null' || obj[curr] === null
    acc[curr] = !isNullValue ? obj[curr] : ''

    return acc
  }, {})
}

interface CheckoutAddressProps {
  districtList: Array<ILocationData>
  subDistrictList: Array<ILocationData>
  stateList: Array<ICountry>
  countryList: Array<ICountry>
  itemTotalPrice: string
  totalPrice: string
  formAuthToken: string
  savedShipAddress: any
  savedBillAddress: any
  savedEmail: string
  orderNumber: string
  valuePromo?: number
  promocode?: string
}

const CheckoutAddress = ({
  districtList,
  stateList,
  countryList,
  subDistrictList,
  itemTotalPrice,
  totalPrice,
  formAuthToken,
  savedBillAddress,
  savedShipAddress,
  savedEmail,
  orderNumber,
  valuePromo,
  promocode,
}: CheckoutAddressProps) => {
  const [currentDistrictList, setCurrentDistrictList] =
    React.useState(districtList)
  const [currentSubDistrictList, setCurrentSubDistrictList] =
    React.useState(subDistrictList)

  /*
   * savedBillAddress is the previously saved billing address, to differentiate it in the
   * form, the names are appended with `bill_`. The keys need to be renamed so that they
   * can be set as defaultValues if they exist
   */
  const defaultBillAddressValues = changeSavedBillAddressKeys(savedBillAddress)

  const formMethods = useForm({
    defaultValues: {
      email: savedEmail,
      ...removeNullValue(savedShipAddress),
      ...defaultBillAddressValues,
    },
  })

  /*
   * This form values determine wether to show or hide billing address form or the tax
   * reciept form
   */
  const isBillingAddressSame = formMethods.watch('isBillingAddressSame')
  const isTaxRecieptRequired = formMethods.watch('isTaxRecieptRequired')

  /*
   * watch form values for stateList and districtList as the list rendered on screen
   * for districtList and subDistrictList depends on what was selected before.
   *
   * ex: render subDistricts based on the district selected
   */
  const currentStateId =
    formMethods.watch('state_id') ?? districtList[0].state_id
  const currentDistrictId =
    formMethods.watch('district_id') ?? subDistrictList[0].district_id

  useEffect(() => {
    formMethods.setValue('isBillingAddressSame', true)
    formMethods.setValue('isTaxRecieptRequired', false)
  }, [])

  useEffect(() => {
    // update district and subDistrict list to show only those values within the selected
    // state and district respectively
    setCurrentDistrictList(
      districtList.filter((district) => district.state_id === currentStateId)
    )

    setCurrentSubDistrictList(
      subDistrictList.filter(
        (subDistrict) => subDistrict.district_id === currentDistrictId
      )
    )
  }, [currentStateId, currentDistrictId])

  const submitWithToken = (values: any) => submitForm(values, formAuthToken)

  return (
    <Container>
      <StyledForm onSubmit={formMethods.handleSubmit(submitWithToken)}>
        <FormContainer>
          <ContactInfoContainer>
            <Input
              isRequired
              type="text"
              {...formMethods.register('email', {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              errorText={formMethods.formState.errors.email?.message}
              hasError={formMethods.formState.errors.email}
              labelText={EMAIL_TEXT}
              flexCol
            />
            <Input
              labelText={MOBILE_TEXT}
              isRequired
              type="number"
              {...formMethods.register('phone', {
                required: true,
                minLength: 9,
                maxLength: 12,
              })}
              errorText={formMethods.formState.errors.phone?.message}
              hasError={formMethods.formState.errors.phone}
              flexCol
            />
          </ContactInfoContainer>

          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {DELIVERY_FORM_HEADER}
            </DeliveryHeaderContainer>

            <FormikContainer>
              {CHECKOUT_ADDRESS_INPUTS.map(
                ({ id, label, name, type, isRequired }) => (
                  <Input
                    key={id}
                    type={type}
                    {...formMethods.register(name, { required: isRequired })}
                    hasError={formMethods.formState.errors[name]}
                    errorText={formMethods.formState.errors[name]?.message}
                    flexCol
                    labelText={label}
                    isRequired={isRequired}
                  />
                )
              )}

              <FormProvider {...formMethods}>
                <Select
                  selectName="country_id"
                  labelText={COUNTRY_HEADING}
                  isRequired
                  list={countryList}
                />
                <Select
                  selectName="state_id"
                  labelText={STATE_HEADING}
                  isRequired
                  list={stateList}
                />
                <Select
                  selectName="district_id"
                  labelText={DISTRICT_HEADING}
                  isRequired
                  list={currentDistrictList}
                />
                <Select
                  selectName="subdistrict_id"
                  labelText={SUB_DISTRICT_HEADING}
                  isRequired
                  list={currentSubDistrictList}
                />
                <Input
                  flexCol
                  isRequired
                  labelText={ZIPCODE_HEADING}
                  type="number"
                  {...formMethods.register('zipcode', { required: true })}
                  errorText={formMethods.formState.errors.zipcode?.message}
                  hasError={formMethods.formState.errors.zipcode}
                />
              </FormProvider>

              <Input
                flexCol
                labelText={ALTERNATIVE_CONTACT_HEADING}
                type="number"
                {...formMethods.register('alternative_phone')}
                errorText={
                  formMethods.formState.errors.alternative_phone?.message
                }
                hasError={formMethods.formState.errors.alternative_phone}
              />
            </FormikContainer>
          </DeliveryFormContainer>

          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {TAX_INVOICE_HEADER}
            </DeliveryHeaderContainer>
            <FormikContainer>
              <CheckboxRegular
                {...formMethods.register('isTaxRecieptRequired')}
                text={TAX_INVOICE_TEXT}
              />
            </FormikContainer>
            <Transition
              show={isTaxRecieptRequired ?? false}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <FormikContainer>
                <Input
                  labelText={COMPANY_NAME_TEXT}
                  flexCol
                  isRequired
                  type="text"
                  {...formMethods.register('taxpayer', {
                    required: isTaxRecieptRequired,
                  })}
                  errorText={formMethods.formState.errors.taxpayer?.message}
                  hasError={formMethods.formState.errors.taxpayer}
                />
                <Input
                  flexCol
                  isRequired
                  type="number"
                  labelText={TAX_ID_TEXT}
                  {...formMethods.register('taxpayer_id', {
                    required: isTaxRecieptRequired,
                  })}
                  errorText={formMethods.formState.errors.taxpayer_id?.message}
                  hasError={formMethods.formState.errors.taxpayer_id}
                />
              </FormikContainer>
            </Transition>
          </DeliveryFormContainer>

          <DeliveryFormContainer>
            <DeliveryHeaderContainer>
              {BILLING_ADDRESS_HEADER}
            </DeliveryHeaderContainer>
            <FormikContainer>
              <CheckboxRegular
                {...formMethods.register('isBillingAddressSame')}
                text={BILLING_ADDRESS_TEXT}
              />
            </FormikContainer>
            <Transition
              show={!isBillingAddressSame ?? false}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <FormikContainer>
                {CHECKOUT_ADDRESS_INPUTS.map(
                  ({ id, label, name, type, isRequired }) => (
                    <Input
                      key={id}
                      type={type}
                      {...formMethods.register(`b_${name}`, {
                        required: !isBillingAddressSame && isRequired,
                      })}
                      hasError={formMethods.formState.errors[`b_${name}`]}
                      errorText={
                        formMethods.formState.errors[`b_${name}`]?.message
                      }
                      flexCol
                      labelText={label}
                      isRequired={isRequired}
                    />
                  )
                )}

                <FormProvider {...formMethods}>
                  <Select
                    selectName="b_country_id"
                    labelText={COUNTRY_HEADING}
                    isRequired={isBillingAddressSame}
                    list={countryList}
                  />
                  <Select
                    selectName="b_state_id"
                    labelText={STATE_HEADING}
                    isRequired={isBillingAddressSame}
                    list={stateList}
                  />
                  <Select
                    selectName="b_district_id"
                    labelText={DISTRICT_HEADING}
                    isRequired={isBillingAddressSame}
                    list={currentDistrictList}
                  />
                  <Select
                    selectName="b_subdistrict_id"
                    labelText={SUB_DISTRICT_HEADING}
                    isRequired={isBillingAddressSame}
                    list={currentSubDistrictList}
                  />
                </FormProvider>

                <Input
                  flexCol
                  isRequired
                  labelText={ZIPCODE_HEADING}
                  type="number"
                  {...formMethods.register('b_zipcode', {
                    required: !isBillingAddressSame,
                  })}
                  errorText={formMethods.formState.errors.b_zipcode?.message}
                  hasError={formMethods.formState.errors.b_zipcode}
                />
                <Input
                  flexCol
                  labelText={ALTERNATIVE_CONTACT_HEADING}
                  type="number"
                  {...formMethods.register('b_alternative_phone')}
                  errorText={
                    formMethods.formState.errors.b_alternative_phone
                      ?.b_alternative_phone
                  }
                  hasError={formMethods.formState.errors.b_alternative_phone}
                />
              </FormikContainer>
            </Transition>
          </DeliveryFormContainer>
        </FormContainer>

        <CheckoutPriceSection
          handleSubmit={() => {}}
          itemTotalPrice={itemTotalPrice}
          totalPrice={totalPrice}
          orderNumber={orderNumber}
          valuePromo={valuePromo}
          promocode={promocode}
        />
      </StyledForm>
    </Container>
  )
}

export default CheckoutAddress
