import tw from 'tailwind-styled-components'

import { DefaultContainer } from '../../GlobalStyles'

export const Container = tw(DefaultContainer)`
  mt-12
  bg-white
  px-0
  pb-10
  rounded-t-md
`

export const TabsContainer = tw.div`
  w-full
  flex
  rounded-md
  shadow-md
`

export const TabBorder = tw.div`
  w-1/3
  border-r
  border-gray-200
`

export const Tab = tw.button<{ $isActive: boolean }>`
  w-full
  h-full
  py-6
  border-b-4
  focus:outline-none
  ${(props) => (props.$isActive ? 'border-primaryDark' : 'border-transparent')}
  ${(props) => (props.$isActive ? 'font-bold' : '')}
  ${(props) => (props.$isActive ? 'text-primaryDark' : 'text-secondaryDark')}
  hover:bg-gray-50
`

export const TabPanelContainer = tw.div<{ $isVisible: boolean }>`
  py-6
  px-12
  ${(props) => (props.$isVisible ? 'block' : 'hidden')}
`

export const TabPanel = tw.div`
  space-y-5
`

export const Image = tw.img`
  max-h-200
  lg:max-h-500
  w-full
  rounded-lg
  object-contain
`

export const DropdownContainer = tw.div`
  py-2
  mb-4
  border-gray-300
`

export const Ol = tw.ol`
  text-secondaryDark
  list-inside
`
