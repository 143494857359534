import tw from 'tailwind-styled-components'

import { Container as DefaultContainer } from '../BlogSection/BlogSection.styles'

export const Container = tw(DefaultContainer)`
  order-first
  md:order-last
  mb-4
  md:mb-0
`

export const CardsGrid = tw.div`
  grid
  grid-cols-3
  grid-rows-auto
  gap-4
`
