import tw from 'tailwind-styled-components'

const H1Primary = tw.h1`
  text-primaryDark
  text-2xl
  lg:text-4xl
  font-bold
`

export default H1Primary
