import * as React from 'react'
import { IGridMenu, NavLanguage } from '../../@types/nav'
import { menus } from '../../constants/menus'
import { MegaMenu } from './MegaMenu'
import { MenubarItem } from './MenubarItem'

interface MenubarProps {
  gridLinks: IGridMenu[]
  language: NavLanguage
}

export const Menubar = ({ gridLinks, language }: MenubarProps) => {
  return (
    <div className="hidden w-full px-4 py-3 text-white bg-gradientDark lg:block">
      <div className="container flex items-center max-w-6xl mx-auto justify-evenly">
        {gridLinks.map((menu) => (
          <div className="flex items-center space-x-2" key={menu.cate_name_en}>
            <MegaMenu menu={menu} language={language} />
          </div>
        ))}

        {menus.map((menu) => (
          <a href={menu.url} key={menu[language]}>
            <MenubarItem menu={menu} language={language} />
          </a>
        ))}
      </div>
    </div>
  )
}
