import { Popover, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import * as React from 'react'
import { IGridMenu, NavLanguage } from '../../@types/nav'

import { GridMenuItem } from './GridMenuItem'

interface SubDrawerMenuProps {
  menu: IGridMenu
  language: NavLanguage
}

export const SubDrawerMenu = ({ menu, language }: SubDrawerMenuProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex justify-between w-full px-4 py-3 text-xs text-primaryDark">
            <div className="flex space-x-2">
              <img
                src={menu.icon_url}
                className="w-4 h-4 brightness-50"
                alt={menu.cate_name_en}
              />
              <div>
                {
                  menu?.[
                    language === 'name_th' ? 'cate_name_th' : 'cate_name_en'
                  ]
                }
              </div>
            </div>
            <ChevronRightIcon className="w-5 h-5" />
          </Popover.Button>

          <Popover.Overlay
            className={`${
              open
                ? 'fixed top-top-nav z-40 h-screen w-screen opacity-50'
                : 'opacity-0'
            } bg-black`}
          />

          <Transition
            as={React.Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Popover.Panel className="fixed z-50 h-screen px-2 overflow-scroll bg-white shadow top-top-nav w-60">
              <div className="py-2 space-y-2">
                {menu.sub_cate_lv1.map((cat) => (
                  <GridMenuItem
                    menu={cat}
                    key={cat.name_en}
                    language={language}
                  />
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
