import { ShoppingCartIcon } from '@heroicons/react/outline'
import * as React from 'react'

interface CartButtonProps {
  itemsInCart: number
}

export const CartButton = ({ itemsInCart = 0 }: CartButtonProps) => {
  return (
    <a href="/cart" className="relative">
      <div className="absolute flex items-center justify-center w-5 h-5 text-xs bg-red-500 rounded-full -inset-2">
        {itemsInCart}
      </div>
      <ShoppingCartIcon className="w-6 h-6 lg:h-8 lg:w-8" />
    </a>
  )
}
