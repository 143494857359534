import React, { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  Inner,
  RoundedBadgeContainer,
  Icon,
  BadgesContainer,
  ZigZagContainer,
  ImageContainer,
  Image,
  OutlineButtonsContainer,
  OutlineButton,
  LineClamp,
  FlexBetween,
  Flex,
  CardButtonsContainer,
  CardButtonOutline,
  CardButtonFill,
  VolmuePricingText,
} from './FlashCard.styles'
import {
  H3PrimaryDarkRegular,
  H3Danger,
  H3Black,
  PrimaryTextRegular,
  PrimaryTextThin,
  SecondaryTextStrikeThrough,
} from '../../GlobalStyles'

// import types
import { ICardItem } from '../../@types/cards'
import { FREE_SHIPPING, INSTALLMENT } from '../../constants/pageTexts'

// helpers to calculateSavings
import {
  calculateSavings,
  calculateSavingsPercentage,
} from '../../helpers/calculateSavings'

// hook to handle product count toggle
import { useProductCountToggler } from '../../hooks'

// removes spin style from input
import './FlashCard.css'

import { addToCart } from '../../helpers/addProduct'

/* -------------------------------------------------------------------------------------
 * Helper function
 * -------------------------------------------------------------------------------------
 *
 * @param price to be formatted
 * @return string which is price formatted
 *
 * takes a number like 1230.123 and returns 1,230
 */
export function formatPrice(price: number): string {
  // seperate thousands by a ','
  // added * 1 to make sure it gets formatted correctly
  const priceLocaleString = (price * 1).toLocaleString('en')
  return priceLocaleString.split('.')[0]
}

const BUY_NOW_TEXT = 'ซื้อเลย'
const ADD_TO_CART_TEXT = 'ใส่รถเข็น'
const MIN_CART_ITEM = 1
const UNIT_TEXT = 'ชิ้น'
const SAVE_TEXT = 'ประหยัด'
const TIMEOUT_DELAY = 2000

const FlashCard = ({
  variant_id,
  name,
  slug,
  discount_price,
  master_price,
  volume_price_text,
  share_image,
  lcs_choice,
  free_shipping,
  installment,
}: ICardItem) => {
  /*
   * show loader, to let user know that the the process is being handled
   */
  const [isAddingToCart, setIsAddingToCart] = useState(false)

  const { itemCount, ProductCountToggler } = useProductCountToggler({})

  /*
   * additional values to be inferred from server values
   */
  const savings = calculateSavings(Number(master_price), Number(discount_price))
  const savingsPercentage = calculateSavingsPercentage(
    Number(master_price),
    Number(discount_price)
  )

  const handleActionButtonClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    action: 'addToCart' | 'checkout'
  ) => {
    // as the card is clicable
    event.preventDefault()
    /*
     * disable the add to cart button when, a request is being processed
     */
    if (isAddingToCart) {
      return
    }

    if (action === 'addToCart') {
      setIsAddingToCart(true)
    }

    const isAddedToCart = await addToCart(variant_id, itemCount).then(
      (response) => {
        /*
         * to avoid abruptly stoping the spinner
         */
        setTimeout(() => setIsAddingToCart(false), TIMEOUT_DELAY)
        return response
      }
    )

    if (isAddedToCart) {
      /*
       * based on the action either show a toast or route to the cart page
       */
      if (action === 'addToCart') {
        toast.success('Item has been successfully added to cart')

        // reload page to update cart count
        window.location.reload()
        return
      }

      window.location.href = '/cart'
      return
    }

    toast.error('There was an error adding item to cart')
  }

  return (
    <a href={`/products/${slug}`} className="no-underline">
      <Container className="h-full border border-gray-50">
        <BadgesContainer>
          {lcs_choice && (
            <RoundedBadgeContainer>
              <Icon src="/images/lcsChoice.png" />
            </RoundedBadgeContainer>
          )}

          {discount_price && savingsPercentage > 0 && (
            <ZigZagContainer>-{savingsPercentage}%</ZigZagContainer>
          )}
        </BadgesContainer>

        <ImageContainer className="rounded-t-md">
          <Image loading="lazy" src={share_image} className="rounded-t-md" />
        </ImageContainer>

        <Inner>
          <OutlineButtonsContainer>
            {installment && (
              <OutlineButton $isVisible={installment}>
                {INSTALLMENT}
              </OutlineButton>
            )}
            <OutlineButton $isVisible={free_shipping}>
              {FREE_SHIPPING}
            </OutlineButton>
          </OutlineButtonsContainer>

          <LineClamp>
            <H3PrimaryDarkRegular>{name}</H3PrimaryDarkRegular>
          </LineClamp>

          <FlexBetween className="h-7">
            <Flex className="flex-none">
              {discount_price ? (
                <H3Danger>฿ {formatPrice(Number(discount_price))}</H3Danger>
              ) : (
                <H3Black>฿ {formatPrice(Number(master_price))}</H3Black>
              )}
              <PrimaryTextRegular> /{UNIT_TEXT}</PrimaryTextRegular>
            </Flex>

            {discount_price && (
              <Flex className="flex-shrink">
                <SecondaryTextStrikeThrough>
                  ฿{formatPrice(Number(master_price))}
                </SecondaryTextStrikeThrough>
                <PrimaryTextThin>
                  {SAVE_TEXT} ฿{formatPrice(savings)}
                </PrimaryTextThin>
              </Flex>
            )}
          </FlexBetween>

          <FlexBetween className="pb-2">
            <VolmuePricingText>{volume_price_text}</VolmuePricingText>

            <ProductCountToggler />
          </FlexBetween>

          <CardButtonsContainer>
            <CardButtonOutline
              onClick={(event) => handleActionButtonClick(event, 'addToCart')}
            >
              {ADD_TO_CART_TEXT}
              {isAddingToCart && (
                <svg
                  className="w-5 h-5 ml-3 -mr-1 animate-spin text-primaryDark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox=" 0 0 24 24"
                >
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                </svg>
              )}
            </CardButtonOutline>
            <CardButtonFill
              onClick={(event) => handleActionButtonClick(event, 'checkout')}
            >
              {BUY_NOW_TEXT}
            </CardButtonFill>
          </CardButtonsContainer>
        </Inner>
      </Container>
    </a>
  )
}

export default FlashCard
